
import React from "react";
import 'font-awesome/css/font-awesome.min.css';
import Sidebar from "../../app/components/sidebar";
import Header from "../../app/components/header";
import styles from "./admin.module.scss";



const Admin = () => { 
  
  // const tableWrapper = document.querySelector(".table-wrapper");
  // const switchInputs = document.querySelectorAll(".switch-wrapper input");
  // const prices = tableWrapper.querySelectorAll(".price");
  // const toggleClass = "hide";
  
  // const $button  = document.querySelector('#sidebar-toggle');
	// const $wrapper = document.querySelector('#wrapper');
	
	// $button.addEventListener('click', (e) => {
	//   e.preventDefault();
	//   $wrapper.classList.toggle('toggled');
	// });
  

  return (    

  <div id="wrapper" className="">    
    <Sidebar/>
    <Header />
    <section id="content-wrapper">
            <div className="row">
              <div className="col-lg-12">
                <h2 className={styles.contentTitle}>Admin</h2>
                <div className={styles.volunteerPageTitle}>                  
                  <div className={styles.titleSearchPrt}>
                    <input type="search" name="" placeholder="Search"/>
                  </div>
                </div>

                <div className={`${styles.volunteerDataTable} ${styles.lastclofull}`}>
                  <div className={styles.tableBody}>
                    <div className={styles.theader}>
                      <div className={styles.tableHeader}>Admin Name <a className="sortDownArrow" href="#"><img src= {"../images/arrow-down.png"} alt="Down Arrow"/></a></div>
                      <div className={styles.tableHeader}>Admin Email <a className="sortDownArrow" href="#"><img src= {"../images/arrow-down.png"} alt="Down Arrow"/></a></div>
                      <div className={styles.tableHeader}></div>                                            
                    </div>
                  </div>

                  <div className={styles.tableBody}>                    
                      <div className={styles.tableRow}>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Admin Name:</div>
                        <div className={styles.tableCell}>John Christensen</div>
                      </div>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Admin Email:</div>
                        <div className={styles.tableCell}>$1,000</div>
                      </div>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Action:</div>
                        <div className={styles.tableCell}><a className={styles.subBtn3} href="#">Remove Admin Access</a></div>
                      </div>                                          
                    </div>
                  </div>

                  <div className={styles.tableBody}>                    
                      <div className={styles.tableRow}>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Admin Name:</div>
                        <div className={styles.tableCell}>John Christensen</div>
                      </div>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Admin Email:</div>
                        <div className={styles.tableCell}>$1,000</div>
                      </div>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Action:</div>
                        <div className={styles.tableCell}><a className={styles.subBtn3} href="#">Remove Admin Access</a></div>
                      </div>                                          
                    </div>
                  </div>

                  <div className={styles.tableBody}>                    
                      <div className={styles.tableRow}>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Admin Name:</div>
                        <div className={styles.tableCell}>John Christensen</div>
                      </div>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Admin Email:</div>
                        <div className={styles.tableCell}>$1,000</div>
                      </div>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Action:</div>
                        <div className={styles.tableCell}><a className={styles.subBtn3} href="#">Remove Admin Access</a></div>
                      </div>                                          
                    </div>
                  </div>


                  <div className={styles.tableBody}>                    
                      <div className={styles.tableRow}>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Admin Name:</div>
                        <div className={styles.tableCell}>John Christensen</div>
                      </div>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Admin Email:</div>
                        <div className={styles.tableCell}>$1,000</div>
                      </div>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Action:</div>
                        <div className={styles.tableCell}><a className={styles.subBtn3} href="#">Remove Admin Access</a></div>
                      </div>                                          
                    </div>
                  </div>


                  <div className={styles.tableBody}>                    
                      <div className={styles.tableRow}>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Admin Name:</div>
                        <div className={styles.tableCell}>John Christensen</div>
                      </div>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Admin Email:</div>
                        <div className={styles.tableCell}>$1,000</div>
                      </div>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Action:</div>
                        <div className={styles.tableCell}><a className={styles.subBtn3} href="#">Remove Admin Access</a></div>
                      </div>                                          
                    </div>
                  </div>


                  <div className={styles.tableBody}>                    
                      <div className={styles.tableRow}>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Admin Name:</div>
                        <div className={styles.tableCell}>John Christensen</div>
                      </div>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Admin Email:</div>
                        <div className={styles.tableCell}>$1,000</div>
                      </div>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Action:</div>
                        <div className={styles.tableCell}><a className={styles.subBtn3} href="#">Remove Admin Access</a></div>
                      </div>                                          
                    </div>
                  </div>
                 
                                    
                  
                </div>
                
              </div>
            </div>
        </section>
  </div>
);}
export default Admin;



