const data = {
    // "Educator-Registration-Form": [
    //     {
    //         fieldName: "firstName",
    //         placeholder: ""
    //     },
    //     {
    //         fieldName: "lastName",
    //         placeholder: ""
    //     },
    //     {
    //         fieldName: "emailAddress",
    //         placeholder: ""
    //     },
    //     {
    //         fieldName: "password",
    //         placeholder: ""
    //     }
    // ],
    // "Volunteer-Registration-Form": [
    //     {
    //         fieldName: "firstName",
    //         placeholder: ""
    //     },
    //     {
    //         fieldName: "lastName",
    //         placeholder: ""
    //     },
    //     {
    //         fieldName: "emailAddress",
    //         placeholder: ""
    //     },
    //     {
    //         fieldName: "password",
    //         placeholder: ""
    //     }
    // ],
    "Educator-Create-Form": [
        {
            label: "First Name",
            fieldName: "firstName",
            placeholder: ""
        },
        {
            label: "Last Name",
            fieldName: "lastName",
            placeholder: ""
        },
        {
            label: "Email Address",
            fieldName: "emailAddress",
            placeholder: ""
        },
        {
            label: "Phone Number",
            fieldName: "phoneNumber",
            placeholder: ""
        },
        {
            label: "Educator Position",
            fieldName: "educatorPosition",
            placeholder: ""
        },
        {
            label: "School District",
            fieldName: "schoolDistrictId",
            placeholder: ""
        },
        {
            label: "School Name",
            fieldName: "schoolId",
            placeholder: ""
        }
    ],
    "Volunteer-Create-Form": [
        {
            label: "First Name",
            fieldName: "firstName",
            placeholder: ""
        },
        {
            label: "Last Name",
            fieldName: "lastName",
            placeholder: ""
        },
        {
            label: "Email Address",
            fieldName: "emailAddress",
            placeholder: ""
        },
        {
            label: "Phone Number",
            fieldName: "phoneNumber",
            placeholder: ""
        },
        {
            label: "Apartment Name",
            fieldName: "apartmentName",
            placeholder: ""
        },
        {
            label: "Street Address",
            fieldName: "streetAddress",
            placeholder: ""
        },
        {
            label: "City",
            fieldName: "city",
            placeholder: ""
        },
        {
            label: "State",
            fieldName: "state",
            placeholder: ""
        },
        {
            label: "Zip Code",
            fieldName: "zipCode",
            placeholder: ""
        },
        {
            label: "Volunteer Agreement Signature",
            fieldName: "agreementSignatureString",
            placeholder: ""
        },
        {
            label: "I am a (select one)",
            fieldName: "iAmA",
            placeholder: ""
        },
        {
            label: "How did you hear about KIND Fund?",
            fieldName: "referralString",
            placeholder: ""
        },
        {
            label: "How many kids are you interested in shopping for? *",
            fieldName: "packetPerRequest",
            placeholder: ""
        },
        {
            label: "Which county would you prefer to shop in?*",
            fieldName: "countyId",
            placeholder: ""
        },
        {
            fieldName: "schoolDistrictId",
            placeholder: ""
        },
        {
            fieldName: "partnerId",
            placeholder: ""
        },
        {
            label: "How often would you like to volunteer? *",
            fieldName: "howOftenLikeToVolunteer",
            placeholder: ""
        },
        {
            label: "Have you volunteered with Young Caring for Our Young before? *",
            fieldName: "haveVolunteerBefore",
            placeholder: ""
        },
        {
            label: "What other types of volunteer opportunities with our group are you interested in? ",
            fieldName: "opportunityInterestedIn",
            placeholder: ""
        }
    ],
    "Educator-Edit-Profile-Form": [
        {
            label: "First Name",
            fieldName: "firstName",
            placeholder: ""
        },
        {
            label: "Last Name",
            fieldName: "lastName",
            placeholder: ""
        },
        {
            label: "Email Address",
            fieldName: "emailAddress",
            placeholder: ""
        },
        {
            label: "Phone Number",
            fieldName: "phoneNumber",
            placeholder: ""
        },
        {
            label: "Educator Position",
            fieldName: "educatorPosition",
            placeholder: ""
        },
        {
            label: "School District",
            fieldName: "schoolDistrictId",
            placeholder: ""
        },
        {
            label: "School Name",
            fieldName: "schoolId",
            placeholder: ""
        }
    ],
    "Volunteer-Edit-Profile-Form": [
        {
            label: "First Name",
            fieldName: "firstName",
            placeholder: ""
        },
        {
            label: "Last Name",
            fieldName: "lastName",
            placeholder: ""
        },
        {
            label: "Email Address",
            fieldName: "emailAddress",
            placeholder: ""
        },
        {
            label: "Phone Number",
            fieldName: "phoneNumber",
            placeholder: ""
        },
        {
            label: "Apartment Name",
            fieldName: "apartmentName",
            placeholder: ""
        },
        {
            label: "Street Address",
            fieldName: "streetAddress",
            placeholder: ""
        },
        {
            label: "City",
            fieldName: "city",
            placeholder: ""
        },
        {
            label: "State",
            fieldName: "state",
            placeholder: ""
        },
        {
            label: "Zip Code",
            fieldName: "zipCode",
            placeholder: ""
        },
        {
            label: "Volunteer Agreement Signature",
            fieldName: "agreementSignatureString",
            placeholder: ""
        },
        {
            label: "I am a (select one)",
            fieldName: "iAmA",
            placeholder: ""
        },
        {
            label: "How did you hear about KIND Fund?",
            fieldName: "referralString",
            placeholder: ""
        },
        {
            label: "How many kids are you interested in shopping for? *",
            fieldName: "packetPerRequest",
            placeholder: ""
        },
        {
            label: "Which county would you prefer to shop in?*",
            fieldName: "countyId",
            placeholder: ""
        },
        {
            fieldName: "schoolDistrictId",
            placeholder: ""
        },
        {
            fieldName: "partnerId",
            placeholder: ""
        },
        {
            label: "How often would you like to volunteer? *",
            fieldName: "howOftenLikeToVolunteer",
            placeholder: ""
        },
        {
            label: "Have you volunteered with Young Caring for Our Young before? *",
            fieldName: "haveVolunteerBefore",
            placeholder: ""
        },
        {
            label: "What other types of volunteer opportunities with our group are you interested in? ",
            fieldName: "opportunityInterestedIn",
            placeholder: ""
        }
    ],
    "Packet-Request-Form": [
        {
            label: "Tracking Number",
            fieldName: "trackingNumber",
            placeholder: ""
        },
        {
            label: "Location Hours",
            fieldName: "locationHours",
            placeholder: ""
        },
        {
            label: "Student's Age",
            fieldName: "studentAge",
            placeholder: ""
        },
        {
            label: "Student's Gender",
            fieldName: "studentGender",
            placeholder: ""
        },
        {
            label: "Special Clothing Requirements",
            fieldName: "specialClothingRequirements",
            placeholder: ""
        },
        {
            label: "Requested Items - Select all that apply:",
            fieldName: "itemTypeIds",
            placeholder: ""
        },
        {
            label: "Student’s Shirt Size",
            fieldName: "shirtSizeId",
            placeholder: ""
        },
        {
            label: "Student’s Pant Size",
            fieldName: "pantSizeId",
            placeholder: ""
        },
        {
            label: "Student’s Shoe Size",
            fieldName: "shoeSizeId",
            placeholder: ""
        },
        {
            label: "",
            fieldName: "shoeType",
            placeholder: ""
        },
        {
            label: "Additional Needs/Priority Items",
            fieldName: "additionalItems",
            placeholder: ""
        },
        {
            label: "Student’s Preferences (colors, styles, etc.)",
            fieldName: "studentPreferences",
            placeholder: ""
        },
        {
            label: "Other Comments or Additional Info",
            fieldName: "remarks",
            placeholder: ""
        }
    ]
}

export default data;
