import styles from "./assignPartner.module.scss";
import closeIcon_img from "../../../assets/images/close-icon.png";
import React, {useEffect, useRef, useState} from "react";
import {fetchGetRequest, fetchPatchRequest} from "../../../utils/network";
import endpoints from "../../../utils/endpoints";
import AssignPartnerWithAnother from "./assignWithAnother";
import Spinner from "react-bootstrap/Spinner";
import toastMessage from "../../../utils/toastMessage";
import constants from "../../../utils/constants";

const PartnerRow = ({packetDetails, user, setSelectedPartner}) => {
    const [assigningPacket, setAssigningPacket] = useState(false);
    const [showActions, setShowActions] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setShowActions(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    // const activeCount = user?.packetLeftToAssign;
    // const capacity = user?.packetPerRequest;
    // const remainingCount = activeCount;
    const capacity = Infinity;
    const remainingCount = Infinity; // user.volunteerCount - user.packetCount;

    const assignPacketToPartner = async (packetId, partnerId) => {
        setAssigningPacket(true);
        try {
            const response = await fetchPatchRequest(
                endpoints.partner.assignPacket(packetId),
                {partnerId}
            );
            if(response.status < 400) {
                // onClosePopup?.();
                setSelectedPartner(partnerId);
                toastMessage(constants.TOAST_TYPE.SUCCESS, response.data.message);
            } else {
                // onClosePopup?.();
                toastMessage(constants.TOAST_TYPE.ERROR, response.data.message);
                throw "Error";
            }
        } catch(err) {
            // onClosePopup?.();
            console.error(err);
            toastMessage(constants.TOAST_TYPE.ERROR, err.data.message);
            throw "Error";
        } finally {
            setAssigningPacket(false);
        }
    }

    return (
        <tr ref={ref} className={styles.tableBody} key={user?.userId}
            style={{
                backgroundColor: packetDetails?.partner?.partnerId === user?.partnerId
                    ? 'lightgrey' : 'transparent'
            }}>
            <td style={{paddingLeft: '10px'}}>
                <div className={styles.tableCell}>{user?.title}</div>
            </td>
            <td>
                <div className={styles.tableCell} style={{paddingLeft: '0.75rem'}}>
                    {user.packetCount} of {(capacity === Infinity) ? `∞`: capacity} packet{capacity>1?"s":""}
                </div>
            </td>
            {/*<td>*/}
            {/*    <div className={styles.tableCell} style={{paddingLeft: '0.75rem'}}>*/}
            {/*        {user?.howOftenLikeToVolunteer}*/}
            {/*    </div>*/}
            {/*</td>*/}
            {/*<td>*/}
            {/*    <div className={styles.tableCell} style={{paddingLeft: '0.75rem'}}>*/}
            {/*        {user?.partner.title}*/}
            {/*    </div>*/}
            {/*</td>*/}
            <td>
                <div className={styles.tableCell} style={{display: 'flex', justifyContent: 'center'}}>
                    {remainingCount > 1 &&
                        <button disabled={packetDetails?.partner?.partnerId === user.partnerId || assigningPacket}
                                onClick={() => {
                                    assignPacketToPartner(packetDetails.packetId, user.partnerId)
                                        .then(() => {
                                            setSelectedPartner(user);
                                        }).catch(err => null)
                                }}
                                className={`${styles.viewAllBtn} ${
                                    packetDetails?.partner?.partnerId === user.partnerId
                                        ? styles.disabledBtn : ""
                                }`}>{
                            assigningPacket
                                ? <Spinner animation="border" />
                                : <>Assign Packet w/ Another</>
                        }</button>}
                    {remainingCount === 1 &&
                        <button disabled={packetDetails?.partner?.partnerId === user.partnerId || assigningPacket}
                            onClick={() => assignPacketToPartner(packetDetails.packetId, user.partnerId)}
                            className={`${styles.viewAllBtn} ${
                                packetDetails?.partner?.partnerId === user.partnerId
                                    ? styles.disabledBtn : ""
                            }`}
                        >{assigningPacket
                            ? <Spinner animation="border" />
                            : <>Assign Packet</>
                        }</button>}
                </div>
            </td>
        </tr>
    )
}

const RenderHeaderRow = ({ label, value, ordering, updateOrdering }) => {
    return (
        <th className={`${styles.tableHeader} ${value ? styles.hoverableHeader : ""}`}
            onClick={() => {
                if(value) updateOrdering(value);
            }}
            style={{backgroundColor: ordering.orderBy === value ? '#fff' : 'transparent'}}>
            {label}
            <span className="sortDownArrow">
          {ordering.orderBy === value
              ? (ordering.orderType == 'ASC'
                  ? <img src={"/images/arrow-up.png"} alt="Down Arrow"/>
                  : <img src={"/images/arrow-down.png"} alt="Down Arrow"/>)
              : null}
        </span>
        </th>
    )
}

function customSort(data, orderBy, orderType) {
    if(orderBy === "fullName") {
        const asc = (x, y) => x.user.fullName < y.user.fullName ? -1
            : (x.user.fullName > y.user.fullName ? 1 : 0);
        const desc = (x, y) => x.user.fullName > y.user.fullName ? -1
            : (x.user.fullName < y.user.fullName ? 1 : 0);

        return data.sort(orderType === "ASC" ? asc : desc);
    }

    return data;
}

const AssignPartner = ({ packetDetails, open, onClose, }) => {
    const [selectedPartner, setSelectedPartner] = useState(null);
    const [partnerData, setPartnerData] = useState(null);
    const [loadingData, setLoadingData] = useState(false);
    const [searchInputValue, setSearchInputValue] = useState("");
    const [pagination, setPagination] = useState({page:1, size:999})
    const [ordering, setOrdering] = useState({orderBy: 'title', orderType: 'ASC'})
    const [pageCountOptions, setPageCountOptions] = useState([pagination.size, 20, 50])
    const [lastPage, setLastPage] = useState(1);

    const getPartnerData = async () => {
        setPartnerData(null);
        setLoadingData(true);
        try {
            const params = {
                // ...pagination, ...ordering,
                // packetId: packetDetails?.packetId,
                // keywords: searchInputValue,
            };

            const response = await fetchGetRequest(
                endpoints.partner.getAvailablePartners(packetDetails.packetId),
                {params}
            );
            if (response.data.partners.length > 0) {
                setPartnerData(() => {
                    const newData = response.data.partners.map((user) => {
                        return { ...user, isAccordianOpen: false };
                    })
                    return customSort(newData, params.orderBy, params.orderType);
                });
                setLastPage(1);
            } else {
                setPartnerData([]);
                setLastPage(1);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoadingData(false);
        }
    };

    useEffect(() => {
        if(!packetDetails) return;
        getPartnerData()
    }, [packetDetails, open, selectedPartner, ordering])

    const updateOrdering = (orderBy) => {
        setOrdering(x => {
            if(x.orderBy === orderBy) {
                const orderType = x.orderType === 'ASC' ? 'DESC' : 'ASC';
                return {...x, orderType}
            }
            return {orderBy, orderType: 'ASC'}
        })
    }

    useEffect(() => {
        document.body.style.overflow = open ? 'hidden' : 'auto';

        return () => {
            document.body.style.overflow = 'auto';
        }
    }, [open]);

    return (
        <>
            {selectedPartner &&
                <AssignPartnerWithAnother
                    open={!!selectedPartner}
                    onClose={() => onClose()}
                    partner={selectedPartner}
                    packetDetails={packetDetails}
                />}
            {!selectedPartner &&
                <div className={`${styles.modal} ${styles.fade} ${open ? styles.show : ''}`}
                     tabIndex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
                    <div className={styles['modal-dialog']} style={{ maxWidth: '65%' }}>
                        <div className={styles['modal-content']} style={{padding: '15px'}}>
                            <div className={styles['modal-header']}>
                                <div>
                                    <h5 style={{ marginBottom: '8px', fontSize: '18px', fontWeight: 'normal' }}>
                                        Assign Partner For
                                    </h5>
                                    <h4 style={{ marginBottom: 0, fontSize: '28px', fontWeight: 'normal' }}><strong>Packet -</strong> {packetDetails?.requestNumber}</h4>
                                </div>
                                <button type="button" onClick={onClose} className={styles['close']} data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true"><img src={closeIcon_img} alt="close-icon" /></span>
                                </button>
                            </div>
                            <div className={styles['modal-body']}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div style={{marginTop: '10px', marginBottom: '15px'}}>Available Partners:</div>
                                </div>

                                <div style={{ maxHeight: '70vh', overflowY: 'scroll' }}>
                                    {partnerData?.length > 0 &&
                                        <table className={styles.DataTable} style={{position: 'sticky'}}>
                                            <thead>
                                            <tr>
                                                <RenderHeaderRow label="Partner Name" value="title"
                                                     ordering={ordering} updateOrdering={updateOrdering}
                                                />
                                                {/*<RenderHeaderRow label="Packet Availability" value=""*/}
                                                {/*     ordering={ordering} updateOrdering={updateOrdering}*/}
                                                {/*/>*/}
                                                <RenderHeaderRow label="Partner Availability" value=""
                                                     ordering={ordering} updateOrdering={updateOrdering}
                                                />
                                                {/*<RenderHeaderRow label="Partner Name" value=""*/}
                                                {/*     ordering={ordering} updateOrdering={updateOrdering}*/}
                                                {/*/>*/}
                                                <th style={{width: '40%', textAlign: 'center'}}
                                                    className={`${styles.tableHeader} ${styles.lastCol}`}
                                                >Action</th>
                                            </tr>
                                            </thead>

                                            <tbody>
                                            {partnerData?.map((user) => {
                                                return (
                                                    <PartnerRow user={user} packetDetails={packetDetails}
                                                          setSelectedPartner={setSelectedPartner}
                                                          key={user.partnerId}
                                                    />
                                                );
                                            })}
                                            </tbody>
                                        </table>}
                                </div>

                                {partnerData === null &&
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Spinner animation="border" />
                                    </div>}

                                {partnerData?.length === 0 &&
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        No Records Found!
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>}
        </>
    )
}

export default AssignPartner;
