import { Routes, Route } from "react-router-dom";
import { PrivateRoute, PublicRoute } from "./app/guards";

import Login from "./pages/login";

import Dashboard from "./pages/dashboard";
import Volunteers from "./pages/volunteers";
import Donors from "./pages/donors";
import Educators from "./pages/educators";
import Viewpackets from "./pages/packets/view";
import Packets from "./pages/packets";
import Notifications from "./pages/notifications";
import Admin from "./pages/admin";
import Admins from "./pages/admins";
import Editforms from "./pages/editforms";
import Editformsview from "./pages/editformsview";

import RequiredItemList from "./pages/requiredItemManagement/rquiredItemList";
import AddRequiredItem from "./pages/requiredItemManagement/addRequiredItem";
import EditRequiredItem from "./pages/requiredItemManagement/editRequiredItem";
import CountyList from "./pages/countyManagement/countyList";
import AddCounty from "./pages/countyManagement/addCounty";
import EditCounty from "./pages/countyManagement/editCounty";
import AddSchoolDistrict from "./pages/schoolDistrictManagement/addSchoolDistrict";
import SchoolDistrictList from "./pages/schoolDistrictManagement/schoolDistrictList";
import EditSchoolDistrict from "./pages/schoolDistrictManagement/editSchoolDistrict";
import AddItemSize from "./pages/itemSizeManagement/addItemSize";
import EditItemSize from "./pages/itemSizeManagement/editItemSize";
import ItemSizeList from "./pages/itemSizeManagement/itemSizeList";
import AddSchool from "./pages/schoolManagement/addSchool";
import EditSchool from "./pages/schoolManagement/editSchool";
import SchoolList from "./pages/schoolManagement/schoolList";
import PartnerList from "./pages/partnerManagement/partnerList";
import AddPartner from "./pages/partnerManagement/addPartner";
import EditPartner from "./pages/partnerManagement/editPartner";
import ViewPartnerVolunteers from "./pages/partnerManagement/viewPartnerVolunteers";
import FormPlaceholderManagement from "./pages/formPlaceholderManagement";
import EditFormPlaceholders from "./pages/formPlaceholderManagement/edit/EditFormPlaceholders";
import UnassignedPacketsListing from "./pages/packets/unassigned";
import ActivePacketsListing from "./pages/packets/active";
import ClosedPacketsListing from "./pages/packets/closed";
import ChangePassword from "./pages/change-password";
import ForgottenPassword from "./pages/forgotten-password";
import EditPacket from "./pages/packets/edit";

const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<PublicRoute />}>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgottenPassword />} />
        <Route path="/change-password" element={<ChangePassword />} />
      </Route>
      <Route element={<PrivateRoute />}>
        <Route path="/" element={<Dashboard />} />
        <Route path="/required-item-management" element={<RequiredItemList />} />
        <Route path="/required-item-management/add" element={<AddRequiredItem />} />
        <Route
          path="/required-item-management/edit/:id"
          element={<EditRequiredItem />}
        />
        <Route path="/county-management" element={<CountyList />} />
        <Route path="/county-management/add" element={<AddCounty />} />
        <Route path="/county-management/edit/:id" element={<EditCounty />} />
        <Route
          path="/school-district-management"
          element={<SchoolDistrictList />}
        />
        <Route
          path="/school-district-management/add"
          element={<AddSchoolDistrict />}
        />
        <Route
          path="/school-district-management/edit/:id"
          element={<EditSchoolDistrict />}
        />
        {/*<Route path="/item-size-management" element={<ItemSizeList />} />*/}
        {/*<Route path="/item-size-management/add" element={<AddItemSize />} />*/}
        {/*<Route*/}
        {/*  path="/item-size-management/edit/:id"*/}
        {/*  element={<EditItemSize />}*/}
        {/*/>*/}
        <Route path="/school-management" element={<SchoolList />} />
        <Route path="/school-management/add" element={<AddSchool />} />
        <Route path="/school-management/edit/:id" element={<EditSchool />} />
        <Route path="/partner-management" element={<PartnerList />} />
        <Route path="/partner-management/add" element={<AddPartner />} />
        <Route path="/partner-management/edit/:id" element={<EditPartner />} />
        <Route path="/partner-management/view-volunteers/:id" element={<ViewPartnerVolunteers />} />
        <Route path="/volunteers" element={<Volunteers />} />
        <Route path="/educators" element={<Educators />} />

        <Route path="/form-placeholder-management" element={<FormPlaceholderManagement />} />
        <Route path="/form-placeholder-management/edit/:type" element={<EditFormPlaceholders />} />

        <Route path="/packets" element={<Packets />} />
        <Route path="/packets/unassigned" element={<UnassignedPacketsListing />} />
        <Route path="/packets/active" element={<ActivePacketsListing />} />
        <Route path="/packets/closed" element={<ClosedPacketsListing />} />
        <Route path="/packets/details/:packetId" element={<Viewpackets />} />
        <Route path="/packets/edit/:packetId" element={<EditPacket />} />
      </Route>
      <Route path="/donors" element={<Donors />} />
      <Route path="/viewpackets" element={<Viewpackets />} />
      <Route path="/packets" element={<Packets />} />
      <Route path="/notifications" element={<Notifications />} />
      <Route path="/admin" element={<Admin />} />
      <Route path="/admins" element={<Admins />} />
      <Route path="/editforms" element={<Editforms />} />
      <Route path="/editformsview" element={<Editformsview />} />
    </Routes>
  );
};

export default AppRoutes;
