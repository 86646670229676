import React, {useRef, useState} from "react";
import "font-awesome/css/font-awesome.min.css";
import Sidebar from "../../../app/components/sidebar";
import Header from "../../../app/components/header";
import styles from "./addRequiredItem.module.scss";
import { Formik } from "formik";
import * as yup from "yup";
import { fetchPostRequest } from "../../../utils/network";
import endpoints from "../../../utils/endpoints";
import toastMessage from "../../../utils/toastMessage";
import constants from "../../../utils/constants";
import { useNavigate } from "react-router-dom";
import InputErrorMessage from "../../../app/components/inputErrorMessage";
import Spinner from "react-bootstrap/Spinner";

const AddRequiredItem = () => {
  const navigate = useNavigate();
  const formikRef = useRef();
  const [addingItem, setAddingItem] = useState(false);

  const addItemType = async (values) => {
    setAddingItem(true);
    try {
      const payload = {
        title: values.title,
      };
      const response = await fetchPostRequest(
        endpoints.itemTypeManagement.add,
        payload
      );
      toastMessage(constants.TOAST_TYPE.SUCCESS, response?.data?.message);
      navigate("/required-item-management");
    } catch (err) {
      console.log(err);
      if (err?.data?.errors?.length > 0) {
        return err?.data?.errors?.forEach((error) => {
          formikRef?.current?.setFieldError(
            error?.fieldName,
            error?.fieldError?.[0]
          );
        });
      }
      toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
    } finally {
      setAddingItem(false);
    }
  };

  return (
    <div id="wrapper" className="">
      <Sidebar />
      <Header />
      <section id="content-wrapper">
        <div className="row">
          <div className="col-lg-12">
            <h2 className={styles.contentTitle}>Add Item Type</h2>
            <Formik
              initialValues={{ title: "" }}
              validationSchema={yup.object().shape({
                title: yup.string().required("Please enter item type").trim(),
              })}
              innerRef={formikRef}
              onSubmit={(values) => {
                addItemType(values);
              }}
            >
              {({ values, handleBlur, handleChange, handleSubmit }) => {
                return (
                  <form className={styles.mt50} onSubmit={handleSubmit}>
                    <div className={`${styles.dFlex} ${styles.viewForm}`}>
                      <div className={`${styles.leftCol} ${styles.fullWidth}`}>
                        <label>Item Type</label>
                        <input
                          className={styles.formControl}
                          placeholder="Item Type"
                          type="text"
                          name="title"
                          value={values.title}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <InputErrorMessage name="title" />
                      </div>
                    </div>

                    <div className={`${styles.dFlex} ${styles.viewForm}`}>
                      <button className={styles.subBtn2} type="submit"
                          disabled={addingItem}
                          style={{
                            display: 'flex', alignItems: 'center'
                          }}>
                        {addingItem &&
                          <Spinner animation="border" size="sm" style={{ marginRight: '10px' }} />}
                        Add
                      </button>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </section>
    </div>
  );
};
export default AddRequiredItem;
