import styles from "./filterByModal.module.scss";
import closeIcon_img from "../../assets/images/close-icon.png";
import React, {useEffect, useState} from "react";
import Form from 'react-bootstrap/Form';


const FilterByModal = ({ open, onClose, activeFilters, setActiveFilters }) => {
    const [filters, setFilters] = useState({});

    const loadFilters = () => {
        const newFiltersState = {};
        if(activeFilters.volunteerAvailability) {
            newFiltersState.volunteerAvailability = activeFilters.volunteerAvailability;
        }
        if('volunteerStatus' in activeFilters) {
            newFiltersState.volunteerStatus = activeFilters.volunteerStatus ? "Available" : "Not Available";
        }
        setFilters(newFiltersState)
    }

    const saveFilters = () => {
        const newFiltersState = {};
        if(filters.volunteerAvailability) {
            newFiltersState.volunteerAvailability = filters.volunteerAvailability;
        }
        if(filters.volunteerStatus) {
            newFiltersState.volunteerStatus = filters.volunteerStatus === "Available";
        }
        setActiveFilters(newFiltersState);
    }

    useEffect(() => {
        if(open) {
            document.getElementsByTagName('body')?.[0].classList.add(styles['modal-open']);

            loadFilters();
        } else {
            document.getElementsByTagName('body')?.[0].classList.remove(styles['modal-open']);
        }
    }, [open]);

    const removeFilter = (name) => {
        return () => {
            console.log("removeFilter!!!");
            if(filters.hasOwnProperty(name)) {
                setFilters((x) => {
                    const y = {...x};
                    delete y[name];
                    return y;
                })
            }
        }
    }

    const updateFilter = (name, value) => {
        setFilters(x => {
            const y = {...x, [name]: value}
            if(!value) {
                delete y[name];
            }
            return y;
        })
    }

    return (
        <div className={`${styles.modal} ${styles.fade} ${open ? styles.show : ''}`}
             tabIndex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
            <div className={styles['modal-dialog']}>
                <div className={styles['modal-content']}>
                    <div className={styles['modal-header']}>
                        <h4 className={styles['modal-title']}>Filter by</h4>
                        <button type="button" onClick={onClose} className={styles['close']} data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true"><img src={closeIcon_img} alt="close-icon" /></span>
                        </button>
                    </div>

                    <form className={styles['modal-body']}
                        style={{
                            padding: '30px',
                            display: 'flex', flexDirection: 'column', gap: '20px'
                        }}>
                        <div style={{
                            display: 'flex'
                        }}>
                            <input type="radio" style={{ height: '20px', width: '20px', marginRight: '10px' }}
                                   checked={filters.hasOwnProperty("volunteerAvailability")}
                                   onClick={removeFilter("volunteerAvailability")} />
                            <span style={{flex: 1}}>Volunteer Availability</span>
                            <Form.Select style={{flex: 1}} value={filters.volunteerAvailability || ''}
                                         onChange={e => updateFilter("volunteerAvailability", e.target.value)}>
                                <option disabled={!filters.hasOwnProperty("volunteerAvailability")} value="">Select Volunteer Availability</option>
                                <option>Once</option>
                                <option>Weekly</option>
                                <option>Bi-Weekly</option>
                                <option>Monthly</option>
                                <option>Quarterly</option>
                                <option>Yearly</option>
                            </Form.Select>
                        </div>
                        <div style={{
                            display: 'flex'
                        }}>
                            <input type="radio" style={{ height: '20px', width: '20px', marginRight: '10px' }}
                                   checked={filters.hasOwnProperty("volunteerStatus")}
                                   onClick={removeFilter("volunteerStatus")} />
                            <span style={{flex: 1}}>Volunteer Status</span>
                            <Form.Select style={{flex: 1}} value={filters.volunteerStatus || ''}
                                         onChange={e => updateFilter("volunteerStatus", e.target.value)}>
                                <option disabled={!filters.hasOwnProperty("volunteerStatus")} value="">Select Volunteer Status</option>
                                <option>Available</option>
                                <option>Not Available</option>
                            </Form.Select>
                        </div>
                    </form>
                    <div className={styles['modal-footer']}>
                        <button type="button"
                            className={`${styles['confirmBtn']}`}
                            onClick={() => {
                                saveFilters(); onClose();
                            }}>Filter</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FilterByModal;
