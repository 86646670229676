import React from "react";
import { NavLink } from "react-router-dom";
import logo_img from "../../../assets/images/small-logo.png"

const Header = () => {
  const toggle = () => {
    const wrapper = document.getElementById('wrapper')
    if(!wrapper) return;
    if(wrapper.classList.contains('toggled')) {
      wrapper.classList.remove('toggled')
    } else {
      wrapper.classList.add('toggled')
    }
  }

  return (
    <div id="navbar-wrapper">
      <nav className="navbar navbar-inverse">
        <div className="container-fluid">
          <div className="navbar-header">
            <div className="d-flex2">
              <img src={logo_img} alt="Young Automotive Group" style={{width: '50px', height: '50px'}} />
              <NavLink onClick={toggle} className="navbar-brand" id="sidebar-toggle">
                <i className="fa fa-bars"></i>
              </NavLink>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};
export default Header;
