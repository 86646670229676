import React, {useEffect, useState} from "react";
import { useDispatch } from "react-redux";
import {NavLink, useNavigate} from "react-router-dom";
import { signout } from "../../../store/reducers/loginData";
import {fetchGetRequest} from "../../../utils/network";
import Spinner from "react-bootstrap/Spinner";
import endpoints from "../../../utils/endpoints";
import {Badge} from "react-bootstrap";

function NavCollapsible(image, label, children) {
  return {image, label, children}
}

function NavItem(image, label, link) {
  if(typeof link === "string") {
    return {image, label, link}
  }
  const onClick = link;
  return {image, label, onClick}
}


const SidebarItem = ({image, label, link, onClick, children}) => {
  const pathname = window.location.pathname;

  const isSelected = pathname?.trim() === link?.trim();
  const isChildSelected = children?.some(x => pathname?.trim() === x.link?.trim()) || false;
  const collapsibleClasses = Array.isArray(children) ? 'submenu__item _has-child' : '';
  const isActive = isSelected

  const [expanded, setExpanded] = useState(isChildSelected);

  return (
      <li onClick={() => {
        if(children) setExpanded(x => !x);
        onClick?.();
      }} className={`${collapsibleClasses} ${isActive ? 'active' : ''}`}>
        {(!Array.isArray(children) && link) ?
            <NavLink to={link}>
              <i>
                <img src={image} />
              </i>{" "}
              <font>{label}</font>
            </NavLink>
            : <span>
              <i>
                <img src={image} />
              </i>{" "}
              <font>{label}</font>
            </span>}
        {children &&
            <ul className={`submenu__child ${expanded ? "active" : ""}`}>
              {children.map((item, i) =>
                  <SidebarItem {...item} key={i} />)}
            </ul>
        }
      </li>
  )
}

const NotificationsCount = () => {
  // const [time, setTime] = useState(new Date());
  const [count, setCount] = useState("");

  const fetchCount = async () => {
    try {
      const response = await fetchGetRequest(
          endpoints.auth.meData
      )
      if(Number.isInteger(response.data.user.unreadNotificationsCount)) {
        setCount(response.data.user.unreadNotificationsCount);
      }
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    // const interval = setInterval(() => {
    //   setTime(new Date());
    //   setCount(localStorage.getItem("unread"))
    // }, 1000);
    //
    // return clearInterval(interval);
    fetchCount();

  }, [])

  return (
      <div style={{
        display: 'flex', justifyContent: 'center', alignItems: 'center'
      }}>
        <span>Notifications </span>
        {count !== 0 &&
          <>
            {count === ""
              ? <div>
                  <Spinner animation="border" size="sm" />
                </div>
              : <div>
                <Badge bg="danger" pill style={{ display: 'inline' }}>
                  {count} <span className="visually-hidden">unread notifications</span>
                </Badge>
                </div>}
            </>}
      </div>
  )
}


const Sidebar = () => {
  const dispatch = useDispatch();
  const [ expanded, setExpanded ] = useState(localStorage.getItem("dynamic-management-expanded") || false);
  const navigate = useNavigate();

  const logout = async () => {
    await dispatch(signout());
    navigate("/login");
  };

  const toggleDynamicManagement = () => {
    setExpanded(x => !x)
    localStorage.setItem("dynamic-management-expanded", !expanded)
  }

  const sidebar_config = [
    NavItem("/images/dashboard.svg", "Dashboard", "/"),
    NavItem("/images/user-icon.svg", "Packet Management", "/packets"),
    NavItem("/images/user-icon.svg", "Admins", "/admins"),
    NavItem("/images/user-icon.svg", "Educators", "/educators"),
    NavItem("/images/tag-icon.svg", "Volunteers", "/volunteers"),
    NavCollapsible("/images/format_list_bulleted.svg", "Dynamic Management", [
      NavItem("/images/ballot.svg", "Required Item Management", "/required-item-management"),
      // NavItem("/images/aspect_ratio.svg", "Item Size Management", "/item-size-management"),
      NavItem("/images/flag_circle.svg", "County Management", "/county-management"),
      NavItem("/images/history_edu.svg", "School District Management", "/school-district-management"),
      NavItem("/images/school.svg", "School Management", "/school-management"),
      NavItem("/images/handshake.svg", "Partner Management", "/partner-management"),
      NavItem("/images/user-icon.svg", "Form Placeholder Management", "/form-placeholder-management"),
    ]),
    NavItem("/images/notification-bell.svg", <NotificationsCount />, "/notifications"),
    NavItem("/images/log-icon.svg", "Logout", logout)
  ];
  

  return (
    <aside id="sidebar-wrapper">
      <div className="sidebar-brand">
        <div className="d-flex">
          <img alt="Young Automotive Group" src={"/images/small-logo.png"} style={{width: '50px', height: '50px'}} />
          <h2>The KIND Fund</h2>
        </div>
      </div>
      <div className="scrollbar" id="style-1">
        <div className="force-overflow submenu">
        <ul className="sidebar-nav submenu__main">
          {sidebar_config.map((sidebar_item, i) =>
            <SidebarItem {...sidebar_item} key={i} />)}

        {/*<li>*/}
        {/*  <NavLink to="/">*/}
        {/*    <i>*/}
        {/*      <img src={"/images/dashboard.svg"} alt="dashboard-icon" />*/}
        {/*    </i>{" "}*/}
        {/*    <font>Dashboard</font>*/}
        {/*  </NavLink>*/}
        {/*</li>*/}
        {/*<li className="submenu__item _has-child" onClick={toggleDynamicManagement}>*/}
        {/*  <span>*/}
        {/*    <i>*/}
        {/*      <img src={"/images/format_list_bulleted.svg"} alt="list_bulleted-icon" />*/}
        {/*    </i>{" "}*/}
        {/*    <font>Dynamic Management</font>*/}
        {/*  </span>*/}
        {/*  <ul className={`submenu__child ${expanded ? "active" : ""}`}>*/}
        {/*    <li>*/}
        {/*      <NavLink to="/required-item-management">*/}
        {/*        <i>*/}
        {/*          <img src={"/images/ballot.svg"} alt="ballot-icon" />*/}
        {/*        </i>{" "}*/}
        {/*        <font>Required Item Management</font>*/}
        {/*      </NavLink>*/}
        {/*    </li>*/}
        {/*    <li>*/}
        {/*      <NavLink to="/item-size-management">*/}
        {/*        <i>*/}
        {/*          <img src={"/images/aspect_ratio.svg"} alt="aspect_ratio-icon" />*/}
        {/*        </i>{" "}*/}
        {/*        <font>Item Size Management</font>*/}
        {/*      </NavLink>*/}
        {/*    </li>*/}
        {/*    <li>*/}
        {/*      <NavLink to="/county-management">*/}
        {/*        <i>*/}
        {/*          <img src={"/images/flag_circle.svg"} alt="flag_circle-icon" />*/}
        {/*        </i>{" "}*/}
        {/*        <font>County Management</font>*/}
        {/*      </NavLink>*/}
        {/*    </li>*/}
        {/*    <li>*/}
        {/*      <NavLink to="/school-district-management">*/}
        {/*        <i>*/}
        {/*          <img src={"/images/history_edu.svg"} alt="history_edu-icon" />*/}
        {/*        </i>{" "}*/}
        {/*        <font>School District Management</font>*/}
        {/*      </NavLink>*/}
        {/*    </li>*/}
        {/*    <li>*/}
        {/*      <NavLink to="/school-management">*/}
        {/*        <i>*/}
        {/*          <img src={"/images/school.svg"} alt="school-icon" />*/}
        {/*        </i>{" "}*/}
        {/*        <font>School Management</font>*/}
        {/*      </NavLink>*/}
        {/*    </li>*/}
        {/*    <li>*/}
        {/*      <NavLink to="/partner-management">*/}
        {/*        <i>*/}
        {/*          <img src={"/images/handshake.svg"} alt="handshake-icon" />*/}
        {/*        </i>{" "}*/}
        {/*        <font>Partner Management</font>*/}
        {/*      </NavLink>*/}
        {/*    </li>*/}
        {/*  </ul>*/}
        {/*</li>*/}

        {/*<li>*/}
        {/*  <NavLink to="/form-placeholder-management">*/}
        {/*    <i>*/}
        {/*      <img src={"/images/user-icon.svg"} alt="user-icon" />*/}
        {/*    </i>{" "}*/}
        {/*    <font>Form Placeholder Management</font>*/}
        {/*  </NavLink>*/}
        {/*</li>*/}
        {/*<li>*/}
        {/*  <NavLink to="/packets">*/}
        {/*    <i>*/}
        {/*      <img src={"/images/user-icon.svg"} alt="user-icon" />*/}
        {/*    </i>{" "}*/}
        {/*    <font>Packet Management</font>*/}
        {/*  </NavLink>*/}
        {/*</li>*/}
        {/*<li>*/}
        {/*  <NavLink to="/educators">*/}
        {/*    <i>*/}
        {/*      <img src={"/images/user-icon.svg"} alt="user-icon" />*/}
        {/*    </i>{" "}*/}
        {/*    <font>Educators</font>*/}
        {/*  </NavLink>*/}
        {/*</li>*/}
        {/*<li>*/}
        {/*  <NavLink to="/volunteers">*/}
        {/*    <i>*/}
        {/*      <img src={"/images/tag-icon.svg"} alt="tag-icon" />*/}
        {/*    </i>{" "}*/}
        {/*    <font>Volunteers</font>*/}
        {/*  </NavLink>*/}
        {/*</li>*/}

        {/*       */}
        {/*<li*/}
        {/*  onClick={() => {*/}
        {/*    logout();*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <span>*/}
        {/*    <i>*/}
        {/*      <img src={"/images/log-icon.svg"} alt="Logout-icon" />*/}
        {/*    </i>{" "}*/}
        {/*    <font>Logout</font>*/}
        {/*  </span>*/}
        {/*</li>*/}
      </ul>
        </div>
      </div>
      
    </aside>
  );
};
export default Sidebar;
