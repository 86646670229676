import styles from './createSubAdmin.module.scss';
import React, {useEffect, useState} from "react";
import closeIcon_img from "../../../assets/images/close-icon.png";
import InputErrorMessage, {CustomInputErrorMessage} from "../../../app/components/inputErrorMessage";
import {fetchPostRequest} from "../../../utils/network";
import endpoints from "../../../utils/endpoints";
import toastMessage from "../../../utils/toastMessage";
import constants from "../../../utils/constants";
import Spinner from "react-bootstrap/Spinner";

const CreateSubAdmin = ({ open, onClose }) => {
    const [creatingAdmin, setCreatingAdmin] = useState(false);
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [emailAddress, setEmailAddress] = useState("")
    const [password, setPassword] = useState("")
    const [fieldErrors, setFieldErrors] = useState({})

    const onSubmit = e => {
        e.preventDefault();
        setCreatingAdmin(true)

        fetchPostRequest(
            endpoints.subadmin.create, {
                firstName, lastName, emailAddress, password
            }
        ).then(response => {
            if(response.status < 400) {
                toastMessage(constants.TOAST_TYPE.SUCCESS, response?.data?.message);
                onClose();
            } else {
                toastMessage(constants.TOAST_TYPE.ERROR, response?.data?.message);
            }
        }).catch(err => {
            // toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
            if(Array.isArray(err.data.errors)) {
                const obj = {}
                err.data.errors.forEach(x => {
                    obj[x.fieldName] = x.fieldError.join(",")
                })
                setFieldErrors(obj);
            }
        }).finally(() => {
            setCreatingAdmin(false)
        })
    }

    useEffect(() => {
        if(open) {
            document.getElementsByTagName('body')?.[0].classList.add(styles['modal-open']);
            setFirstName("");
            setLastName("");
            setEmailAddress("");
            setPassword("");
            setFieldErrors({});
        } else {
            document.getElementsByTagName('body')?.[0].classList.remove(styles['modal-open']);
        }
    }, [open]);

    return (
        <div className={`${styles.modal} ${styles.fade} ${open ? styles.show : ''}`}
             tabIndex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
            <div className={styles['modal-dialog']}>
                <div className={styles['modal-content']} style={{padding: '20px'}}>
                    <div className={styles['modal-header']}>
                        <h4 className={styles['modal-title']}>Create New Subadmin</h4>
                        <button type="button" onClick={onClose} className={styles['close']} data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true"><img src={closeIcon_img} alt="close-icon" /></span>
                        </button>
                    </div>
                    <form className={styles['modal-body']} id="subadmin-creation-form"
                        onSubmit={onSubmit}>
                        <div style={{
                            display: 'flex', gap: '30px', marginTop: '20px'
                        }}>
                            <div style={{flex: 1}}>
                                <div className={`${styles.dFlex} ${styles.viewForm}`}>
                                    <div className={`${styles.leftCol} ${styles.fullWidth}`}>
                                        <label>First Name</label>
                                        <input
                                            className={styles.formControl}
                                            placeholder="First Name"
                                            type="text" required={true}
                                            name="firstName" value={firstName}
                                            onChange={e => setFirstName(e.target.value)}
                                        />
                                        <CustomInputErrorMessage msg={fieldErrors.firstName} />
                                    </div>
                                </div>
                            </div>

                            <div style={{flex: 1}}>
                                <div className={`${styles.dFlex} ${styles.viewForm}`}>
                                    <div className={`${styles.leftCol} ${styles.fullWidth}`}>
                                        <label>Last Name</label>
                                        <input
                                            className={styles.formControl}
                                            placeholder="Last Name"
                                            type="text" required={true}
                                            name="lastName" value={lastName}
                                            onChange={e => setLastName(e.target.value)}
                                        />
                                        <CustomInputErrorMessage msg={fieldErrors.lastName} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{
                            display: 'flex', gap: '30px', marginTop: '20px'
                        }}>
                            <div style={{flex: 1}}>
                                <div className={`${styles.dFlex} ${styles.viewForm}`}>
                                    <div className={`${styles.leftCol} ${styles.fullWidth}`}>
                                        <label>Email</label>
                                        <input
                                            className={styles.formControl}
                                            placeholder="example@gmail.com"
                                            type="email" required={true}
                                            name="email" value={emailAddress}
                                            onChange={e => setEmailAddress(e.target.value)}
                                        />
                                        <CustomInputErrorMessage msg={fieldErrors.emailAddress} />
                                    </div>
                                </div>
                            </div>

                            <div style={{flex: 1}}>
                                <div className={`${styles.dFlex} ${styles.viewForm}`}>
                                    <div className={`${styles.leftCol} ${styles.fullWidth}`}>
                                        <label>Create Password</label>
                                        <input
                                            className={styles.formControl}
                                            placeholder="Password"
                                            type="password" required={true}
                                            name="password" value={password}
                                            pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W_]).{8,}$"
                                            title="Password shoud consist of atleast upper & lower case characters, a number and a symbol"
                                            onChange={e => setPassword(e.target.value)}
                                        />
                                        <CustomInputErrorMessage msg={fieldErrors.password} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className={styles['modal-footer']}>
                        <button type="submit" form="subadmin-creation-form"
                            className={`${styles['confirmBtn']}`}>
                            {creatingAdmin &&
                                <Spinner animation="border" size="sm" style={{ marginRight: '10px' }} />}
                            Create</button>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default CreateSubAdmin;
