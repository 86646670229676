import Sidebar from "../../../app/components/sidebar";
import Header from "../../../app/components/header";
import React, {useEffect, useRef, useState} from "react";
import styles from "../packets.module.scss";
import {fetchGetRequest} from "../../../utils/network";
import endpoints from "../../../utils/endpoints";
import ReactPaginate from "react-paginate";
import {Link} from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import FilterByVolunteerAndEducatorModal from "../FilterByVolunteerAndEducatorModal";

const PacketRow = ({item}) => {
    const [showActions, setShowActions] = useState(false);
    const ref = useRef(null);

    const educator = item.educator?.user;

    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setShowActions(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    return (
        <tr ref={ref} key={item.packetId}>
            <td className={styles.tableSmall}>
                <div className={styles.tableCell}>Packet Number:</div>
                <div className={styles.tableCell}>{item?.requestNumber} {item?.partner && "(Group)"}</div>
            </td>
            <td className={styles.tableSmall}>
                <div className={styles.tableCell}>Educator Name:</div>
                <div className={styles.tableCell}>{educator?.firstName} {educator?.lastName}</div>
            </td>
            <td className={styles.tableSmall}>
                <div className={styles.tableCell}>School District:</div>
                <div className={styles.tableCell}>{item?.schoolDistrict?.districtName}</div>
            </td>
            <td className={styles.tableSmall}>
                <div className={styles.tableCell}>School Name:</div>
                <div className={styles.tableCell}>{item?.school?.schoolName}</div>
            </td>
            <td className={styles.tableSmall}>
                <div className={styles.tableCell}>Student Age:</div>
                <div className={styles.tableCell}>{item?.studentAge}</div>
            </td>
            <td className={styles.tableSmall}>
                <div className={styles.tableCell}>Student Gender:</div>
                <div className={styles.tableCell}>{item?.studentGender}</div>
            </td>
            <td className={`${styles.tableSmall} ${styles.lastCol}`}>
                <div className={styles.tableCell}>Action:</div>
                <div className={styles.tableCell}>
                    <div className={styles.dFlex5}>
                        <Link className={styles.dropdownSec} onClick={() => setShowActions(true)}
                            style={{
                                marginLeft: '20px', marginRight: '20px',
                                color: 'black'
                            }}
                            to={`/packets/details/${item.packetId}`}
                        >
                            <i className="fa fa-eye"></i>
                        </Link>
                    </div>
                </div>
            </td>
        </tr>
    )
}

const RenderHeaderRow = ({ label, value, ordering, updateOrdering }) => {
    const [hovering, setHovering] = useState(false);

    return (
        <div className={`${styles.tableHeader} ${value ? styles.hoverableHeader : ""}`}
             onMouseOver={() => setHovering(true)} onMouseOut={() => setHovering(false)}
             onClick={() => {
                 if(value) updateOrdering(value);
             }}
             style={{backgroundColor: ordering.orderBy === value ? '#fff' : 'transparent'}}>
            {label}
            <span className="sortDownArrow">
              {(ordering.orderBy === value && !hovering)
                  ? (ordering.orderType == 'ASC'
                      ? <img src={"../images/arrow-up.png"} alt="Down Arrow"/>
                      : <img src={"../images/arrow-down.png"} alt="Down Arrow"/>)
                  : null}

                {(ordering.orderBy === value && hovering)
                    ? (ordering.orderType == 'ASC'
                        ? <img src={"../images/arrow-down.png"} alt="Down Arrow" style={{ opacity: 0.3 }} />
                        : <img src={"../images/arrow-up.png"} alt="Down Arrow" style={{ opacity: 0.3 }} />)
                    : null}

                {(hovering && ordering.orderBy !== value) &&
                    <img src={"../images/arrow-up.png"} alt="Down Arrow"
                         style={{ opacity: 0.3 }} />}

            </span>
        </div>
    )
}

const UnassignedPacketsListing = () => {
    const [packets, setPackets] = useState(null);
    const [loadingData, setLoadingData] = useState(false);
    const [searchInputValue, setSearchInputValue] = useState("");
    const [pagination, setPagination] = useState({page:1,size:10});
    const [ordering, setOrdering] = useState({orderBy: 'requestNumber', orderType: 'ASC'})
    const [pageCountOptions, setPageCountOptions] = useState([pagination.size, 20, 50])
    const [lastPage, setLastPage] = useState(1);

    const [filters, setFilters] = useState({});
    const [filterByActive, setFilterByActive] = useState(false);

    const fetchAllPackets = async (keywords="") => {
        setLoadingData(true);
        try {
            const response = await fetchGetRequest(
                endpoints.packetManagement.getAll,
                {params: {...pagination, ...ordering, keywords, status: 'Pending', ...filters}}
            )
            if (response.status < 400 && Array.isArray(response.data.packets)) {
                setPackets(x => [...response.data.packets])
                setLastPage(response.data?.pagination?.lastPage || 1)
            } else {
                setPackets([]);
                setLastPage(1);
            }
        } catch(err) {
            console.error(err);
        } finally {
            setLoadingData(false);
        }
    }

    useEffect(() => {
        if(searchInputValue.length !== 0) return;
        fetchAllPackets()
    }, [searchInputValue, ordering, pagination, filters])

    const handlePageClick = (event) => {
        setPagination(x => ({...x, page: event.selected+1}))
    }

    const updateOrdering = (orderBy) => {
        setOrdering(x => {
            if(x.orderBy === orderBy) {
                const orderType = x.orderType === 'ASC' ? 'DESC' : 'ASC';
                return {...x, orderType}
            }
            return {orderBy, orderType: 'ASC'}
        })
    }

    return (
        <div id="wrapper" className="">
            <Sidebar/>
            <Header />
            <section id="content-wrapper">
                <FilterByVolunteerAndEducatorModal open={filterByActive} activeFilters={filters}
                   setActiveFilters={setFilters} onClose={() => setFilterByActive(false)} />

                <div className="row">
                    <div className="col-lg-12">
                        <h2 className={styles.contentTitle}>Unassigned Packets</h2>
                        <div className={styles.PageTitle}>
                            <div className={styles.titleSearchPrt} style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                                <div className={`${styles.filterIcon} ${styles.hoverable}`}
                                     style={{ padding: '10px', borderRadius: '100px', width: '45px', height: '45px',
                                         display: 'flex', justifyContent: 'center', marginRight: '10px' }}
                                     onClick={() => setFilterByActive(true)}>
                                    <a data-toggle="modal" data-target="#basicModal2" href="#">
                                        {Object.keys(filters).length === 0
                                            ? <img src= {"../images/filter.svg"} alt="filter"/>
                                            : <img src= {"../images/edit-filter.svg"} alt="filter"/>}
                                    </a>
                                </div>
                                <input
                                    type="search"
                                    placeholder="Search"
                                    value={searchInputValue}
                                    onChange={(e) => {
                                        setSearchInputValue(e.target.value);
                                    }}
                                />
                                <button style={{
                                    background: 'black', padding: '10px 10px'
                                }} onClick={() => fetchAllPackets(searchInputValue)}>
                                    <i className="fa fa-search" style={{ color: 'white' }}></i>
                                </button>
                            </div>
                        </div>

                        {(!loadingData && packets?.length > 0) &&
                            <table className={styles.DataTable}>
                                <thead>
                                    <tr>
                                        <RenderHeaderRow label="Packet Number" value="requestNumber"
                                             ordering={ordering} updateOrdering={updateOrdering}
                                        />
                                        <RenderHeaderRow label="Educator name" value=""
                                             ordering={ordering} updateOrdering={updateOrdering}
                                        />
                                        <RenderHeaderRow label="School District" value=""
                                             ordering={ordering} updateOrdering={updateOrdering}
                                        />
                                        <RenderHeaderRow label="School Name" value=""
                                             ordering={ordering} updateOrdering={updateOrdering}
                                        />
                                        <RenderHeaderRow label="Student Age" value=""
                                             ordering={ordering} updateOrdering={updateOrdering}
                                        />
                                        <RenderHeaderRow label="Student Gender" value=""
                                             ordering={ordering} updateOrdering={updateOrdering}
                                        />
                                        <div className={styles.tableHeader}>Action</div>
                                    </tr>
                                </thead>
                                <tbody>
                                    {packets?.map((item) => {
                                        return (
                                            <PacketRow item={item} />
                                        );
                                    })}
                                </tbody>
                            </table>}

                        {(loadingData || packets === null) &&
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Spinner animation="border" />
                            </div>}

                        {(!loadingData && packets?.length === 0) &&
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                No Records Found!
                            </div>}

                        {packets?.length > 0 &&
                            <div style={{
                                display: 'flex', justifyContent: 'flex-end', marginTop: '50px'
                            }}>
                                <ReactPaginate
                                    className="Pagination"
                                    breakLabel="..."
                                    nextLabel="Next"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={5}
                                    pageCount={lastPage}
                                    previousLabel="Previous"
                                    renderOnZeroPageCount={null}
                                />
                                <div style={{
                                    display: 'flex', alignItems: 'center', marginLeft: '15px'
                                }}>
                                    <select value={pagination.size}
                                            className={styles.selectPrt}
                                            onChange={e =>
                                                setPagination(x => ({page:1, size: e.target.value}))}>
                                        {pageCountOptions.map((x, i) =>
                                            <option key={i} value={x}>{x}</option>)}
                                    </select>
                                </div>
                            </div>}
                    </div>
                </div>
            </section>
        </div>
    )
}

export default UnassignedPacketsListing;
