import React, {useEffect, useRef, useState} from "react";
import "font-awesome/css/font-awesome.min.css";
import Sidebar from "../../../app/components/sidebar";
import Header from "../../../app/components/header";
import styles from "./schoolList.module.scss";
import { NavLink, useNavigate } from "react-router-dom";
import { fetchDeleteRequest, fetchGetRequest } from "../../../utils/network";
import endpoints from "../../../utils/endpoints";
import toastMessage from "../../../utils/toastMessage";
import constants from "../../../utils/constants";
import ReactPaginate from "react-paginate";
import DeleteConfirmationModal from "../../../components/common/DeleteConfirmationModal";
import Spinner from "react-bootstrap/Spinner";

const SchoolRow = ({item, onEdit, onDelete}) => {
  const [showActions, setShowActions] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowActions(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
      <div ref={ref} className={styles.tableBody} key={item?.schoolId}>
        <div className={styles.tableRow}>
          <div className={styles.tableSmall}>
            <div className={styles.tableCell}>School Name:</div>
            <div className={styles.tableCell}>
              {item?.schoolName}
            </div>
          </div>
          <div className={styles.tableSmall}>
            <div className={styles.tableCell}>District:</div>
            <div className={styles.tableCell}>
              {item?.schoolDistrict?.districtName || "n/a"}
            </div>
          </div>
          <div className={styles.tableSmall}>
            <div className={styles.tableCell}>Address:</div>
            <div className={styles.tableCell}>
              {item?.address}, {item?.city}, {item?.zipCode}
            </div>
          </div>
          <div className={styles.tableSmall}>
            <div className={styles.tableCell}>Phone Number:</div>
            <div className={styles.tableCell}>
              {item?.phoneNumber}
            </div>
          </div>
          <div className={`${styles.tableSmall} ${styles.lastCol}`}>
            <div className={styles.tableCell}>Action:</div>
            <div className={styles.tableCell}>
              <div className={styles.dFlex5}>
                <div className={styles.dropdownSec} onClick={() => setShowActions(true)}>
                  <img
                      className={styles.mrGap}
                      src={"../images/doted.png"}
                      alt="dot"
                  />
                  {showActions && <ul>
                    <li
                        onClick={() => onEdit(item?.schoolId)}
                    >
                      <i className="fa fa-edit"></i> Edit School
                    </li>
                    <li
                        onClick={() => onDelete(item?.schoolId)}
                    >
                      <i className="fa fa-trash-o"></i> Delete
                      School
                    </li>
                  </ul>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

const RenderHeaderRow = ({ label, value, ordering, updateOrdering }) => {
  const [hovering, setHovering] = useState(false);

  return (
      <div className={`${styles.tableHeader} ${value ? styles.hoverableHeader : ""}`}
           onMouseOver={() => setHovering(true)} onMouseOut={() => setHovering(false)}
           onClick={() => {
             if(value) updateOrdering(value);
           }}
           style={{backgroundColor: ordering.orderBy === value ? '#fff' : 'transparent'}}>
        {label}
        <span className="sortDownArrow">
          {(value && ordering.orderBy === value && !hovering)
              ? (ordering.orderType == 'ASC'
                  ? <img src={"../images/arrow-up.png"} alt="Down Arrow"/>
                  : <img src={"../images/arrow-down.png"} alt="Down Arrow"/>)
              : null}

          {(value && ordering.orderBy === value && hovering)
              ? (ordering.orderType == 'ASC'
                  ? <img src={"../images/arrow-down.png"} alt="Down Arrow" style={{ opacity: 0.3 }} />
                  : <img src={"../images/arrow-up.png"} alt="Down Arrow" style={{ opacity: 0.3 }} />)
              : null}

          {(value && hovering && ordering.orderBy !== value) &&
              <img src={"../images/arrow-up.png"} alt="Down Arrow"
                   style={{ opacity: 0.3 }} />}

            </span>
      </div>
  )
}


const SchoolList = () => {
  const [schoolData, setSchoolData] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [pagination, setPagination] = useState({page:1, size: 10})
  const [ordering, setOrdering] = useState({orderBy: 'schoolName', orderType: 'ASC'})
  const [pageCountOptions, setPageCountOptions] = useState([pagination.size, 20, 50])
  const [lastPage, setLastPage] = useState(1);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState('');

  const navigate = useNavigate();

  const getSchoolData = async () => {
    setLoadingData(true);
    try {
      const params = {
        ...pagination, ...ordering,
        keywords: searchInputValue,
      };
      const response = await fetchGetRequest(
        endpoints.schoolManagement.getAll,
        { params }
      );
      if(response?.data?.schools) {
        setSchoolData(response?.data?.schools);
        setLastPage(response.data.pagination.lastPage);
      } else {
        setSchoolData([]);
        setLastPage(1);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    getSchoolData();
  }, [searchInputValue, pagination, ordering]);

  const deleteSchool = async (id) => {
    try {
      const response = await fetchDeleteRequest(
        endpoints.schoolManagement.delete(id)
      );
      toastMessage(constants.TOAST_TYPE.SUCCESS, response?.data?.message);
      getSchoolData();
    } catch (err) {
      console.log(err);
      toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
    }
  };

  const handlePageClick = (event) => {
    setPagination(x => ({...x, page: event.selected+1}))
  }

  const onEdit = (schoolId) => {
    navigate(
        `/school-management/edit/${schoolId}`
    );
  }

  const updateOrdering = (orderBy) => {
    setOrdering(x => {
      if(x.orderBy === orderBy) {
        const orderType = x.orderType === 'ASC' ? 'DESC' : 'ASC';
        return {...x, orderType}
      }
      return {orderBy, orderType: 'ASC'}
    })
  }

  return (
    <div id="wrapper" className="">
      <DeleteConfirmationModal open={!!showDeleteConfirmation}
         onClose={() => setShowDeleteConfirmation('')}
         title="Delete School?"
         body="Are you sure you want to delete the school?"
         onConfirm={() => {
           deleteSchool(showDeleteConfirmation); setShowDeleteConfirmation('');
         }}
      />
      <Sidebar />
      <Header />
      <section id="content-wrapper">
        <div className="row">
          <div className="col-lg-12">
            <h2 className={styles.contentTitle}>School Management</h2>
            <div className={styles.dFlex}>
              <div className={styles.volunteerPageTitle}>
                <div className={styles.titleSearchPrt} style={{display: 'flex', alignItems: 'center'}}>
                  <input
                    type="search"
                    placeholder="Search"
                    value={searchInputValue}
                    onChange={(e) => {
                      if(!searchInputValue && e.target.value) {
                        setPagination(x => ({
                          ...x, page: 1
                        }))
                      }
                      setSearchInputValue(e.target.value);
                    }}
                  />
                </div>
              </div>
              <NavLink className={styles.addBtn} to="/school-management/add">
                <i className="fa fa-plus"></i> Add School
              </NavLink>
            </div>

            {(!loadingData && schoolData?.length > 0) &&
              <div className={styles.volunteerDataTable}>
                <div className={styles.tableBody}>
                  <div className={styles.theader}>
                    <RenderHeaderRow label="School Name" value="schoolName"
                         ordering={ordering} updateOrdering={updateOrdering}
                      />
                    <RenderHeaderRow label="District" value="schoolDistrict.districtName"
                         ordering={ordering} updateOrdering={updateOrdering}
                    />
                    <RenderHeaderRow label="Address" value="address"
                         ordering={ordering} updateOrdering={updateOrdering}
                    />
                    <RenderHeaderRow label="Phone Number" value=""
                         ordering={ordering} updateOrdering={updateOrdering}
                    />
                    <div className={styles.tableHeader}>Action</div>
                  </div>
                </div>
                {schoolData.map((item) => {
                  return (
                    <SchoolRow item={item} onEdit={onEdit}
                         onDelete={setShowDeleteConfirmation}
                      />
                  );
                })}
              </div>}

            {(loadingData || schoolData === null) &&
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Spinner animation="border" />
                </div>}

            {(!loadingData && schoolData?.length === 0) &&
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  No Records Found!
                </div>}

            {schoolData?.length > 0 &&
              <div style={{
                display: 'flex', justifyContent: 'flex-end', marginTop: '50px'
              }}>
                <ReactPaginate
                    className="Pagination"
                    breakLabel="..."
                    nextLabel="Next"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={lastPage}
                    previousLabel="Previous"
                    renderOnZeroPageCount={null}
                />
                <div style={{
                  display: 'flex', alignItems: 'center', marginLeft: '15px'
                }}>
                  <select value={pagination.size}
                          style={{ paddingTop: '10px', paddingBottom: '10px', borderRadius: '6px' }}
                          className={styles.selectPrt}
                          onChange={e =>
                              setPagination(x => ({page:1, size: e.target.value}))}>
                    {pageCountOptions.map((x, i) =>
                        <option key={i} value={x}>{x}</option>)}
                  </select>
                </div>
              </div>}
          </div>
        </div>
      </section>
    </div>
  );
};
export default SchoolList;
