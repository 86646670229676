import React, {useState,useEffect} from "react";
import 'font-awesome/css/font-awesome.min.css';
import Sidebar from "../../app/components/sidebar";
import Header from "../../app/components/header";
import styles from "./packets.module.scss";
import {fetchGetRequest} from "../../utils/network";
import endpoints from "../../utils/endpoints";
import {Link} from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import toastMessage from "../../utils/toastMessage";
import {getStatusLabel} from "./view";
import FilterByVolunteerAndEducatorModal from "./FilterByVolunteerAndEducatorModal";

export async function fetchPackets(packetType, keywords, extraParams) {
    const params = {page: 1, size: 4, keywords, status: packetType, ...extraParams}
    if(!params.keywords) {
        delete params.keywords;
    }
    try {
        const response = await fetchGetRequest(
            endpoints.packetManagement.getAll,
            {params}
        )
        if (response.status < 400 && Array.isArray(response.data.packets)) {
            return {
                data: response.data.packets,
                lastPage: response.data?.pagination?.lastPage || 1,
                totalItems: response.data?.pagination?.totalItems
            }
        } else {
            return {
                data: [], lastPage: 1
            }
        }
    } catch (err) {
        console.error(err);
    }
}

const Packets = () => {
    const [keywords, setKeywords] = useState("");
    const [categorizedPackets, setCategorizedPackets] = useState({});
    const [pagination, setPagination] = useState({page:1,size:5});
    const [pageCountOptions, setPageCountOptions] = useState([pagination.size, 20, 50])
    const [ordering, setOrdering] = useState({orderBy: 'requestNumber', orderType: 'ASC'})
    const [lastPage, setLastPage] = useState(1);

    const [filters, setFilters] = useState({});
    const [filterByActive, setFilterByActive] = useState(false);

    const fetchAllPackets = async (keywords="") => {
        const data = await Promise.all([
            fetchPackets("Pending", keywords, filters),
            fetchPackets("Active", keywords, filters),
            fetchPackets("Completed", keywords, filters)
        ]);
        setCategorizedPackets({
            pending: data[0].data,
            active: data[1].data,
            closed: data[2].data,

            pendingTotalCount: data[0].totalItems,
            activeTotalCount: data[1].totalItems,
            closedTotalCount: data[2].totalItems,
        })
    }

    useEffect(() => {
        if(keywords.length !== 0) return;
        fetchAllPackets()
    }, [pagination, keywords, filters])

  return (
      <div id="wrapper" className="">
        <Sidebar/>
        <Header />
        <section id="content-wrapper">

            <FilterByVolunteerAndEducatorModal open={filterByActive} activeFilters={filters}
               setActiveFilters={setFilters} onClose={() => setFilterByActive(false)} />

            <div className="row">
              <div className="col-lg-12">
                  <h2 className={styles.contentTitle}>Packets</h2>
                  <div className={styles.volunteerPageTitle}>
                    <div className={styles.titleSearchPrt} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <div className={`${styles.filterIcon} ${styles.hoverable}`}
                             style={{ padding: '10px', borderRadius: '100px', width: '45px', height: '45px',
                                 display: 'flex', justifyContent: 'center', marginRight: '10px' }}
                             onClick={() => setFilterByActive(true)}>
                            <a data-toggle="modal" data-target="#basicModal2" href="#">
                                {Object.keys(filters).length === 0
                                    ? <img src= {"../images/filter.svg"} alt="filter"/>
                                    : <img src= {"../images/edit-filter.svg"} alt="filter"/>}
                            </a>
                        </div>
                        <input type="search" name="" placeholder="Search" value={keywords}
                             style={{ borderColor: 'grey', padding: '10px 8px' }}
                             onChange={e => {
                                 setKeywords(e.target.value)}
                             } />
                        <button style={{
                              background: 'black', padding: '10px 10px'
                          }} onClick={() => fetchAllPackets(keywords)}>
                            <i className="fa fa-search" style={{ color: 'white' }}></i>
                        </button>
                    </div>
                </div>

                  {Object.keys(categorizedPackets).length === 0 &&
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <Spinner animation="border" />
                      </div>}

                <form style={{ marginTop: '30px' }}>
                    {Array.isArray(categorizedPackets.pending) &&
                    <>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <h3 className={styles.subTitle} style={{marginBottom: '20px'}}>Unassigned Requests</h3>
                            <div>
                                {categorizedPackets.pendingTotalCount > 4 &&
                                    <a href="/packets/unassigned" className={styles.viewAllBtn}>View All</a>}
                            </div>
                        </div>
                        {categorizedPackets.pending?.length > 0 ?
                            <div style={{display: 'grid', gridTemplateColumns: 'auto auto', gap: '25px'}}>
                                {categorizedPackets.pending.map(packet =>
                                    <Link to={`/packets/details/${packet.packetId}`} style={{textDecoration: 'none'}}>
                                        <div className={`${styles.packForm}`}>
                                            <div className={styles.leftCol}>
                                                <div className={styles.formControl}>{packet.requestNumber} <b>{packet.partner && "(Group)"}</b></div>
                                                <span>{getStatusLabel(packet.packetStatus).toUpperCase()}</span>
                                            </div>
                                        </div>
                                    </Link>)}
                            </div>
                        : <div style={{
                            width: '100%', display: 'flex', justifyContent: 'center',
                            color: 'grey', marginBottom: '80px'
                            }}>No Records Found</div>}
                    </>}

                    {Array.isArray(categorizedPackets.active) &&
                    <>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '100px' }}>
                            <h3 className={styles.subTitle} style={{marginBottom: '20px'}}>Active Packets</h3>
                            <div>
                                {categorizedPackets.activeTotalCount > 4 &&
                                    <a href="/packets/active" className={styles.viewAllBtn}>View All</a>}
                                {/*{categorizedPackets.active?.length > 0 &&*/}
                                {/*    <a className={`${styles.viewAllBtn}`} href="#">Export All Receipts</a>}*/}
                            </div>
                        </div>
                        {categorizedPackets.active?.length > 0 ?
                            <div style={{display: 'grid', gridTemplateColumns: 'auto auto', gap: '25px'}}>
                                {categorizedPackets.active.map(packet =>
                                    <Link to={`/packets/details/${packet.packetId}`} style={{textDecoration: 'none'}}>
                                        <div className={`${styles.packForm}`}>
                                            <div className={styles.leftCol}>
                                                <div className={styles.formControl}>{packet.requestNumber} <b>{packet.partner && "(Group)"}</b></div>
                                                <span>{getStatusLabel(packet.packetStatus).toUpperCase()}</span>
                                            </div>
                                        </div>
                                    </Link>)}
                            </div>
                        : <div style={{
                                width: '100%', display: 'flex', justifyContent: 'center',
                                color: 'grey', marginBottom: '80px'
                            }}>No Records Found</div>}
                    </>}

                    {Array.isArray(categorizedPackets.closed) &&
                    <>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '100px' }}>
                            <h3 className={styles.subTitle} style={{marginBottom: '20px'}}>Completed Packets</h3>
                            <div>
                                {categorizedPackets.closedTotalCount > 4 &&
                                    <a href="/packets/closed" className={styles.viewAllBtn}>View All</a>}
                            </div>
                        </div>
                        {categorizedPackets.closed?.length > 0 ?
                            <div style={{display: 'grid', gridTemplateColumns: 'auto auto', gap: '25px'}}>
                                {categorizedPackets.closed.map(packet =>
                                    <Link to={`/packets/details/${packet.packetId}`} style={{textDecoration: 'none'}}>
                                        <div className={`${styles.packForm}`}>
                                            <div className={styles.leftCol}>
                                                <div className={styles.formControl}>{packet.requestNumber} <b>{packet.partner && "(Group)"}</b></div>
                                                <span>{getStatusLabel(packet.packetStatus).toUpperCase()}</span>
                                            </div>
                                        </div>
                                    </Link>)}
                            </div>
                        : <div style={{
                                width: '100%', display: 'flex', justifyContent: 'center',
                                color: 'grey', marginBottom: '80px'
                            }}>No Records Found</div>}
                    </>}

                </form>

              </div>
            </div>
        </section>
      </div>
    );
}



export default Packets;

