import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchDeleteRequest, fetchPostRequest } from "../../utils/network";
import endpoints from "../../utils/endpoints";
import toastMessage from "../../utils/toastMessage";
import constants from "../../utils/constants";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  data: null,
};

export const getLoginData = createAsyncThunk(
  "loginData/getLoginData",
  async (payload, thunkAPI) => {
    try {
      const response = await fetchPostRequest(endpoints.auth.login, payload);
      return thunkAPI.fulfillWithValue(response.data);
    } catch (err) {
      console.log(err);
      toastMessage(constants.TOAST_TYPE.ERROR, "Invalid credentials");
      return thunkAPI.rejectWithValue(err.data);
    }
  }
);

export const signout = createAsyncThunk(
  "loginData/signout",
  async (payload, thunkAPI) => {
      thunkAPI.dispatch(resetLoginData());
      localStorage.clear();
  }
);

const { reducer, actions } = createSlice({
  name: "loginData",
  initialState,
  reducers: {
    storeAccessTokenFromRefreshToken: (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
      state.data.tokens.accessToken = action.payload.tokens.accessToken;
      localStorage.setItem(
        "storeAccessTokenFromRefreshToken",
        action.payload.tokens.accessToken
      );
    },
    passwordUpdated: (state) => {
      state.data.user.userSettings.needToChangePassword = false
    },
    resetLoginData: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLoginData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getLoginData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
      state.data = action.payload;
    });
    builder.addCase(getLoginData.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.data = action.payload;
    });
  },
});

export const { storeAccessTokenFromRefreshToken, resetLoginData, passwordUpdated } = actions;
export default reducer;
