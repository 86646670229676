import React, {useEffect, useState} from "react";
import styles from "./forgottenPassword.module.scss";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getLoginData } from "../../store/reducers/loginData";
import constants from "../../utils/constants";
import InputErrorMessage, {CustomInputErrorMessage} from "../../app/components/inputErrorMessage";
import { useNavigate } from "react-router-dom";
import {fetchPatchRequest, fetchPostRequest} from "../../utils/network";
import endpoints from "../../utils/endpoints";
import Spinner from "react-bootstrap/Spinner";
import toastMessage from "../../utils/toastMessage";

function isString(myVar) {
    return typeof myVar === 'string' || myVar instanceof String
}

function extractNumbers(s) {
    const numbers = Object.keys(Array(10).fill(0));
    return s.split("").filter(x => numbers.includes(x)).join("")
}

const ForgottenPassword = () => {
    const loginData = useSelector((state) => state.loginData);
    const [stepData, setStepData] = useState(0);
    const [emailAddress, setEmailAddress] = useState("");
    const [verificationCode, setVerificationCode] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [sendingCode, setSendingCode] = useState(false);
    const [fieldErrors, setFieldErrors] = useState({})
    const [verifyingCode, setVerifyingCode] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const forgotPassword = async () => {
        setSendingCode(true);
        try {
            const response = await fetchPostRequest(
                endpoints.auth.forgotPassword, {
                    userType: "Administrator", emailAddress
                }
            )
            if(response.status < 400) {
                toastMessage(constants.TOAST_TYPE.SUCCESS, response.data.message);
                setStepData(1);
            } else {
                toastMessage(constants.TOAST_TYPE.ERROR, response?.data?.message);
            }
        } catch (err) {
            console.error(err);
            if(Array.isArray(err.data.errors)) {
                const obj = {}
                err.data.errors.forEach(x => {
                    obj[x.fieldName] = x.fieldError.join(",")
                })
                setFieldErrors(obj);
            } else if(isString(err.data.errors)) {
                toastMessage(constants.TOAST_TYPE.ERROR, err.data.errors);
            }
        } finally {
            setSendingCode(false);
        }
    }

    const resetPassword = async () => {
        setVerifyingCode(true);
        if(password !== confirmPassword) {
            toastMessage(constants.TOAST_TYPE.ERROR, "New Password & Confirm Password must be same!")
            return;
        }
        try {
            const response = await fetchPatchRequest(
                endpoints.auth.resetPassword, {
                    userType: "Administrator", emailAddress,
                    resetCode: verificationCode, newPassword: password, confirmPassword
                }
            )
            if(response.status < 400) {
                toastMessage(constants.TOAST_TYPE.SUCCESS, response?.data?.message);
                navigate("/login")
            } else {
                toastMessage(constants.TOAST_TYPE.ERROR, response?.data?.message);
            }
        } catch (err) {
            console.error(err)
            if(Array.isArray(err.data.errors)) {
                const obj = {}
                err.data.errors.forEach(x => {
                    obj[x.fieldName] = x.fieldError.join(",")
                })
                setFieldErrors(obj);
            } else if(isString(err.data.errors)) {
                toastMessage(constants.TOAST_TYPE.ERROR, err.data.errors);
            }
        } finally {
            setVerifyingCode(false);
        }
    }

    return (
        <div className={styles.loginBody}>
            <div className={styles.container}>
                <div className={styles.loginContent}>
                    <div className={styles.loginContentLeft}>
                        <img src={"../../images/logo.png"} alt="Young Automotive Group" style={{ height: '200px', width: 'auto' }} />
                    </div>
                    <div className={styles.loginContentRight}>
                        <div className={styles.loginForm}>

                            {stepData === 0 &&
                                <>
                                    <h2>Forgot Password?</h2>
                                    <p>Please enter your email address to receive a verification code.</p>
                                    <form onSubmit={e => {
                                        e.preventDefault();
                                        forgotPassword();
                                    }}>
                                        <div className={styles.loginFormGroup}
                                            style={{
                                                marginTop: '40px', marginBottom: '40px',
                                                marginLeft: '15px', marginRight: '15px'
                                            }}>
                                            <label>Email Address</label>
                                            <input
                                                type="text"
                                                placeholder="email@example.com"
                                                name="emailAddress" required={true}
                                                onChange={e =>
                                                    setEmailAddress(e.target.value)}
                                                value={emailAddress}
                                            />
                                        </div>
                                        <div style={{
                                            display: 'flex', gap: '20px',
                                            padding: '15px'
                                        }}>
                                            <button style={{flex:1}} className={styles.backBtn} onClick={() => navigate("/login")}>
                                                Back
                                            </button>
                                            <button style={{flex:1, display: 'flex', justifyContent: 'center', alignItems: 'center'}} className={styles.signBtn} type="submit"
                                                disabled={sendingCode}>
                                                {sendingCode &&
                                                    <Spinner animation="border" style={{ marginRight: '10px' }} />}
                                                Get Code
                                            </button>
                                        </div>
                                    </form>
                                </>}

                            {stepData === 1 &&
                                <>
                                    <h2>Create a New Password</h2>
                                    <br /><br />
                                    <div style={{ paddingLeft: '15px', paddingRight: '15px' }}>
                                        <h5 style={{textAlign: 'left'}}>Verify Email</h5>
                                        <p style={{textAlign: 'left', marginTop: '5px'}}>Enter the 6 digit code that was sent to {emailAddress}</p>
                                    </div>
                                    <form onSubmit={e => {
                                        e.preventDefault();
                                        resetPassword();
                                    }}>
                                        <div className={styles.loginFormGroup}
                                             style={{
                                                 marginTop: '30px', marginBottom: '20px',
                                                 marginLeft: '15px', marginRight: '15px'
                                             }}>
                                            <label>Verification Code</label>
                                            <input
                                                type="text" pattern="[0-9]{6}"
                                                title="OTP should consist of digits only"
                                                name="verificationCode" required={true}
                                                onChange={e =>
                                                    setVerificationCode(extractNumbers(e.target.value).slice(0, 6))
                                                }
                                                value={verificationCode}
                                            />
                                        </div>
                                        <div style={{
                                            display: 'flex', justifyContent: 'center'
                                        }}>
                                            <button style={{
                                                    flex:1, display: 'flex', justifyContent: 'center', alignItems: 'center',
                                                    border: 'none'
                                                }}
                                                className={styles.backBtn}
                                                onClick={() => {
                                                    forgotPassword()
                                                }}
                                                disabled={sendingCode}>
                                                {sendingCode &&
                                                    <Spinner animation="border" style={{ marginRight: '10px' }} />}
                                                Resend Code
                                            </button>
                                        </div>
                                        <br />
                                        <div style={{ paddingLeft: '15px', paddingRight: '15px' }}>
                                            <h5 style={{textAlign: 'left'}}>Set New Password</h5>
                                            <p style={{textAlign: 'left'}}>Your new password must be different from your previous password.</p>
                                        </div>
                                        <div className={styles.loginFormGroup}
                                             style={{
                                                 marginTop: '30px', marginBottom: '30px',
                                                 marginLeft: '15px', marginRight: '15px'
                                             }}>
                                            <label>New Password</label>
                                            <input
                                                type="password" placeholder="New Password"
                                                name="password" required={true}
                                                onChange={e =>
                                                    setPassword(e.target.value)}
                                                value={password}
                                                pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W_]).{8,}$"
                                                title="Password shoud consist of atleast upper & lower case characters, a number and a symbol"
                                            />
                                            <CustomInputErrorMessage msg={fieldErrors.newPassword} />
                                        </div>
                                        <div className={styles.loginFormGroup}
                                             style={{
                                                 marginTop: '30px', marginBottom: '30px',
                                                 marginLeft: '15px', marginRight: '15px'
                                             }}>
                                            <label>Confirm New Password</label>
                                            <input
                                                type="password" placeholder="Confirm New Password"
                                                name="confirmPassword" required={true}
                                                onChange={e =>
                                                    setConfirmPassword(e.target.value)}
                                                value={confirmPassword}
                                                pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W_]).{8,}$"
                                                title="Password shoud consist of atleast upper & lower case characters, a number and a symbol"
                                            />
                                            <CustomInputErrorMessage msg={fieldErrors.confirmPassword} />
                                        </div>
                                        <div style={{
                                            display: 'flex', gap: '20px',
                                            padding: '15px'
                                        }}>
                                            <div style={{flex:1}}></div>
                                            <button style={{flex:1, display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                                                    className={styles.signBtn} type="submit">
                                                {verifyingCode &&
                                                    <Spinner animation="border" style={{ marginRight: '10px' }} />}
                                                Save
                                            </button>
                                        </div>
                                    </form>
                                </>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgottenPassword;
