import React, {useEffect, useState} from "react";
import {fetchDeleteRequest, fetchGetRequest, fetchPatchRequest, fetchPostRequest} from "../../../utils/network";
import endpoints from "../../../utils/endpoints";
import Sidebar from "../../../app/components/sidebar";
import Header from "../../../app/components/header";
import styles from "./edit-form-placeholders.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import placeholders_data from "../data";
import Spinner from "react-bootstrap/Spinner";


function camelCaseToTitleText(text) {
    const result = text.replace(/([A-Z])/g, " $1");
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
}


var debounce = function(fn, t) {
    let timeout;
    function debounced(...args) {
        const later = () => {
            fn.apply(this, args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, t);
    }
    debounced.clear = () => {
        clearTimeout(timeout);
    };
    return debounced;
};

function FormPlaceholder({type, label, fieldName, placeholder, formPlaceholderId, onUpdateFormPlaceholderId}) {
    const [saving, setSaving] = useState(false);
    const [savingLabel, setSavingLabel] = useState("        ");
    let [value, setValue] = useState(placeholder);

    const updateSavingState = (newState) => {
        setSaving(oldState => {
            if(oldState === newState) return newState;
            if(!oldState && newState) {
                setSavingLabel("( Saving... )")
            } else if(oldState && !newState) {
                setSavingLabel("( Saved. )")
                setTimeout(() => {
                    setSavingLabel(z => {
                        if(z === "( Saved. )") return "        ";
                        return z;
                    })
                }, 500);
            }
            return newState;
        })
    }

    const onBlur = async () => {
        console.log("onBlur", value, formPlaceholderId);
        if(placeholder === value) return;
        if(!value) {
            if(!formPlaceholderId) return;
            updateSavingState(true);
            let response;
            try {
                response = await fetchDeleteRequest(
                    endpoints.formPlaceholderManagement.delete(formPlaceholderId)
                )
            } catch(err) {console.error(err)}
            finally {
                await onUpdateFormPlaceholderId();
                updateSavingState(false);
            }
            return;
        }
        if(formPlaceholderId) {
            updateSavingState(true);
            let response;
            try {
                response = await fetchPatchRequest(
                    endpoints.formPlaceholderManagement.updateParticularFormPlaceholder(formPlaceholderId),
                    {fieldName, placeholder: value, type}
                )
            } catch(err) {console.error(err)}
            finally {
                await onUpdateFormPlaceholderId();
                updateSavingState(false)
            }
        } else {
            updateSavingState(true);
            let response;
            try {
                response = await fetchPostRequest(
                    endpoints.formPlaceholderManagement.createFormPlaceholder,
                    {fieldName, placeholder: value, type}
                )
            } catch (err) {console.error(err)}
            finally {
                await onUpdateFormPlaceholderId();
                updateSavingState(false)
            }
        }
    }

    return (
        <div className={`${styles.viewForm}`}>
            <div className={styles.leftCol}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <label style={{marginBottom: '10px'}}>
                        {label || camelCaseToTitleText(fieldName)}
                    </label>
                    <span style={{color: 'grey'}}>{savingLabel}</span>
                </div>
                <input className={styles.formControl} value={value} type="text" onBlur={() => onBlur()}
                       onChange={e => setValue(e.target.value)}
                       onKeyDown={e => {
                           if(e.key === 'Enter') onBlur();
                       }}
                />
            </div>
        </div>
    )
}

function EditFormPlaceholders() {
    const [formPlaceholders, setFormPlaceholders] = useState(null);
    const [pagination, setPagination] = useState({page: 1, size: 100})
    const [lastPage, setLastPage] = useState(1);

    const params = useParams();
    const {type} = params;

    const fetchAllFormPlaceholders = async () => {
        const response = await fetchGetRequest(
            endpoints.formPlaceholderManagement.getAll,
            {params: {...pagination, type, keywords:""}}
        )
        if(response.status < 400 && Array.isArray(response.data.formPlaceholders)) {
            const newFormPlaceholders = merge(placeholders_data[type] || [], response.data.formPlaceholders);
            setFormPlaceholders([...newFormPlaceholders])
            setLastPage(response.data?.pagination?.lastPage || 1)
        } else {
            setFormPlaceholders([]);
            setLastPage(1);
        }
    }

    const merge = (a, b) => {
        return b.reduce((acc, cur, i) => {
            const xi = acc.findIndex(z => z.fieldName === cur.fieldName)
            if(xi === -1) {
                acc.push(cur)
            } else {
                acc[xi] = { ...acc[xi] , ...cur };
            }
            return acc;
        }, [...a])
    }

    useEffect(() => {
        fetchAllFormPlaceholders();
    }, [])

    return (
        <div id="wrapper" className="">
            <Sidebar/>
            <Header />
            <section id="content-wrapper">
                <div className="row">
                    <div className="col-lg-12">
                        <h2 className={styles.contentTitle}>{type.replaceAll("-", " ")}</h2>

                        {formPlaceholders?.length > 1 &&
                            <form style={{
                                marginTop: '35px',
                                display: 'grid', gap: '25px',
                                gridTemplateColumns: 'auto auto'
                            }}>
                                {formPlaceholders.map((data, i) =>
                                    <FormPlaceholder type={type} {...data} key={i}
                                        onUpdateFormPlaceholderId={fetchAllFormPlaceholders}
                                    />)}
                            </form>}

                        {formPlaceholders === null &&
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Spinner animation="border" />
                            </div>}

                        {formPlaceholders?.length === 0 &&
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                No Records Found!
                            </div>}
                    </div>
                </div>
            </section>
        </div>
    )
}

export default EditFormPlaceholders;
