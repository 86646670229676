import React, {useEffect, useState} from "react";
import 'font-awesome/css/font-awesome.min.css';
import Sidebar from "../../../app/components/sidebar";
import Header from "../../../app/components/header";
import styles from "./viewpackets.module.scss";
import {useNavigate, useParams} from "react-router-dom";
import {fetchDeleteRequest, fetchGetRequest, fetchPatchRequest} from "../../../utils/network";
import endpoints from "../../../utils/endpoints";
import AssignVolunteer from "../assignVolunteer";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import AssignPartner from "../assignPartner";
import DeleteConfirmationModal from "../../../components/common/DeleteConfirmationModal";
import toastMessage from "../../../utils/toastMessage";
import constants from "../../../utils/constants";


export function getStatusLabel(status) {
    switch (status) {
        case "Pending":
            return "Pending";
        case "Volunteer Assigned":
            return "Volunteer Assigned";
        case "Volunteer Accepted":
            return "Volunteer Accepted";
        case "Shop Completed":
            return "Shop Completed";
        case "Delivered To Office":
            return "Delivered to School Office";
        case "Delivered To Student":
            return "Delivered To Student";
    }
    return status;
}

export function getDateStringUSFormat(s) {
    return moment(s).format('MM/DD/YYYY')
}

const Viewpackets = () => {
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState('');
    const [packetDetails, setPacketDetails] = useState(null);
    const [showAssignVolunteer, setShowAssignVolunteer] = useState(null);
    const [showAssignPartner, setShowAssignPartner] = useState(null);

    const [editGiftCard, setEditGiftCard] = useState(null);
    const [savingGiftCard, setSavingGiftCard] = useState(false);

    const [editTotalAmountSpent, setEditTotalAmountSpent] = useState(null);
    const [savingTotalAmountSpent, setSavingTotalAmountSpent] = useState(false);

    const {packetId} = useParams();

    const fetchPacketDetails = async() => {
        let response;
        response = await fetchGetRequest(endpoints.packetManagement.getParticularPacket(packetId))
        if(response.status < 400 && response.data.packet) {
            setPacketDetails(response.data.packet);
        }
    }


    const navigate = useNavigate();

    const deletePacket = async (packetId) => {
        try {
            const resp = await fetchDeleteRequest(endpoints.packetManagement.deletePacket(packetId))
            toastMessage(constants.TOAST_TYPE.SUCCESS, resp?.data?.message);
            navigate('/packets');
        } catch(err) {
            console.error(err);
            toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
        }
    }

    const updatePacketGiftCard = async () => {
        setSavingGiftCard(true);
        try {
            const body = {
                giftCardNumber: editGiftCard
            }
            const resp = await fetchPatchRequest(endpoints.packetManagement.updateGiftCard(packetId), body);
            if(resp.status === 200) {
                setPacketDetails(x => ({ ...x, giftCardNumber: body.giftCardNumber }));
            }
        } catch (err) {
            console.error(err);
            toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
        } finally {
            setSavingGiftCard(false);
            setEditGiftCard(null);
        }
    }

    const updateTotalAmountSpent = async () => {
        setSavingTotalAmountSpent(true);
        try {
            const body = {
                totalSpendAmount: parseFloat(editTotalAmountSpent)
            }
            const resp = await fetchPatchRequest(endpoints.packetManagement.updateTotalSpend(packetId), body);
            if(resp.status === 200) {
                setPacketDetails(x => ({ ...x, totalSpendAmount: body.totalSpendAmount }));
            }
        } catch (err) {
            console.error(err);
            toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
        } finally {
            setSavingTotalAmountSpent(false);
            setEditTotalAmountSpent(null);
        }
    }

    useEffect(() => {
        fetchPacketDetails();
    }, [showAssignVolunteer, showAssignPartner])

  return (

  <div id="wrapper" className="">    
    <Sidebar/>
    <Header />
      {!!showAssignVolunteer &&
          <AssignVolunteer
              open={!!showAssignVolunteer}
              onClose={() => setShowAssignVolunteer(null)}
              packetDetails={showAssignVolunteer}
          />}
      {!!showAssignPartner &&
          <AssignPartner
              open={!!showAssignPartner}
              onClose={() => setShowAssignPartner(null)}
              packetDetails={showAssignPartner}
          />}
    <section id="content-wrapper">

        <DeleteConfirmationModal open={!!showDeleteConfirmation}
             onClose={() => setShowDeleteConfirmation('')}
             title="Delete Packet Information?"
             body="Are you sure you want to delete the Packet Information?"
             onConfirm={() => {
                 deletePacket(showDeleteConfirmation);
                 setShowDeleteConfirmation('');
             }}
        />

        <div className="row">
            {packetDetails &&
                <div className="col-lg-12">
                <div className={styles.dFlex}>
                  <h2 className={styles.contentTitle}>Packet - {packetDetails.requestNumber}</h2>
                  <a href="#">{getStatusLabel(packetDetails.packetStatus).toUpperCase()}</a>
                  {["Request Submitted", "Volunteer Assigned", "Volunteer Accepted"].includes(packetDetails.packetStatus) ?
                    <button className={`${styles.sendBtn} ${styles.avBtn}`} onClick={() => setShowAssignVolunteer(packetDetails)}>
                        {packetDetails.volunteer ? "Re-Assign Volunteer" : "Assign Volunteer"}
                    </button>
                      : <div></div>}
                    {["Request Submitted", "Volunteer Assigned", "Volunteer Accepted"].includes(packetDetails.packetStatus) ?
                        <button onClick={() => setShowAssignPartner(packetDetails)} className={`${styles.sendBtn} ${styles.avBtn}`}>
                            {packetDetails.partner ? "Re-Assign Partner" : "Assign Partner"}</button>
                        : <div></div>}
                    {["Request Submitted", "Volunteer Assigned"].includes(packetDetails.packetStatus) &&
                        <div style={{display:"flex", justifyContent:"center", gap:"10px"}}>
                            <button style={{borderRadius: '100px', width: '45px', height: '45px'}} onClick={() => navigate(`/packets/edit/${packetDetails.packetId}`)}>
                                <i className="fa fa-edit" style={{fontSize: '32px'}}></i>
                            </button>
                            <button style={{borderRadius: '100px', width: '45px', height: '45px'}} onClick={() => setShowDeleteConfirmation(packetDetails.packetId)}>
                                <i className="fa fa-trash-o" style={{fontSize: '32px'}}></i>
                            </button>
                        </div>}
                </div>

                <div className={styles.eduInfo}>
                    <h3 className={styles.subTitle}>Total Money Spent</h3>
                    <div className={styles.dFlex}>
                        {savingTotalAmountSpent &&
                            <p><span>...</span></p>}
                        {!savingTotalAmountSpent && (editTotalAmountSpent == null) &&
                            <p style={{flex: 1}}><span>Amount:</span> {
                                packetDetails.totalSpendAmount >= 0 ? <>{packetDetails.totalSpendAmount}</> : <b>n/a</b>
                            }
                                {["Shop Completed", "Delivered To Office", "Delivered To Student"].includes(packetDetails.packetStatus) &&
                                    <i className={`fa fa-pencil ${styles.hoverable}`} style={{fontSize: '20px', marginLeft: '8px'}}
                                        onClick={() => setEditTotalAmountSpent(packetDetails.totalSpendAmount || "")}></i>}
                            </p>}
                        {(!savingTotalAmountSpent && editTotalAmountSpent !== null) &&
                            <p style={{flex: 1}}><span>Amount:</span>
                                <form style={{display: 'inline-flex', alignItems: 'center', background: 'transparent', border: 'none'}}
                                    onSubmit={() => updateTotalAmountSpent()}>
                                    <input required type="number" step="any" value={editTotalAmountSpent} style={{ marginLeft: '10px' }} maxLength={15} placeholder="150"
                                           onChange={e => setEditTotalAmountSpent(e.target.value)} min="0.01" />
                                    <button type="submit" className={`fa fa-check-circle ${styles.hoverable}`}
                                            style={{marginLeft: '8px', fontSize: '25px'}}></button>
                                </form>
                            </p>}
                    </div>
                </div>

                <div className={styles.eduInfo}>
                    <h3 className={styles.subTitle}>Gift Card Information</h3>
                    <div className={styles.dFlex}>
                        {savingGiftCard &&
                            <p><span>...</span></p>}
                        {(!savingGiftCard && editGiftCard === null) &&
                            <p style={{flex: 1}}><span>Gift Card Number:</span> {
                                packetDetails.giftCardNumber ? <>{packetDetails.giftCardNumber}</> : <b>n/a</b>
                            }
                                <i className={`fa fa-pencil ${styles.hoverable}`} style={{fontSize: '20px', marginLeft: '8px'}}
                                   onClick={() => setEditGiftCard(packetDetails.giftCardNumber || "")}></i>
                            </p>}
                        {(!savingGiftCard && editGiftCard !== null) &&
                            <p style={{flex: 1}}><span>Gift Card Number:</span>
                                <span style={{display: 'inline-flex', alignItems: 'center'}}>
                                    <input type="text" value={editGiftCard} style={{ marginLeft: '10px' }} maxLength={15} placeholder="Wal: 0000-0000"
                                           onChange={e => setEditGiftCard(e.target.value)} />
                                    <i className={`fa fa-check-circle ${styles.hoverable}`} style={{marginLeft: '8px', fontSize: '25px'}}
                                        onClick={() => {
                                            updatePacketGiftCard();
                                        }}></i>
                                </span>
                            </p>}
                    </div>
                </div>

                {(packetDetails.trackingNumber || packetDetails.additionalDates.packetDueDate) &&
                    <div className={styles.eduInfo}>
                        <h3 className={styles.subTitle}>Request Information</h3>
                        <div className={styles.dFlex}>
                            {packetDetails.trackingNumber &&
                                <p style={{flex: 1}}><span>Tracking Number:</span> #{packetDetails.trackingNumber}</p>}
                            {packetDetails.createdAt &&
                                <p style={{flex: 1}}><span>Request Date:</span> {getDateStringUSFormat(packetDetails.createdAt)}</p>}
                            {packetDetails.additionalDates.packetDueDate &&
                                <p style={{flex: 1}}><span>Due Date:</span> {getDateStringUSFormat(packetDetails.additionalDates.packetDueDate)}</p>}
                            {packetDetails.additionalDates.volunteerDeliveredToOfficeDate &&
                                <p style={{flex: 1}}><span>Delivery Date:</span> {getDateStringUSFormat(packetDetails.additionalDates.volunteerDeliveredToOfficeDate)}</p>}
                        </div>
                    </div>}

                {packetDetails.educator &&
                    <div className={styles.eduInfo}>
                        <h3 className={styles.subTitle}>Educator Information</h3>
                        <div className={styles.dFlex}>
                             <p><span>Full Name:</span> {packetDetails.educator.user.firstName} {packetDetails.educator.user.lastName}</p>
                             <p><span>Position:</span> {packetDetails.educator.educatorPosition}</p>
                             <p><span>Email:</span> {packetDetails.educator.user.emailAddress}</p>
                             <p><span>Phone Number:</span> {packetDetails.educator.user.phoneCountryCode} {packetDetails.educator.user.phoneNumber}</p>
                        </div>
                    </div>}

                {packetDetails?.schoolDistrict &&
                    <div className={styles.eduInfo}>
                        <h3 className={styles.subTitle}>School District Information</h3>
                        <div className={styles.dFlex}>
                            <p><span>District Name: </span> {packetDetails.schoolDistrict?.districtName}</p>
                            <p><span>Phone no: </span> {packetDetails.schoolDistrict?.phoneNumber}</p>
                            <p><span>Address: </span> {packetDetails.schoolDistrict?.address}, {packetDetails.schoolDistrict?.city}, {packetDetails.schoolDistrict?.zipCode}</p>
                        </div>
                    </div>}

                {packetDetails?.school &&
                        <div className={styles.eduInfo}>
                        <h3 className={styles.subTitle}>School Information</h3>
                        <div className={styles.dFlex}>
                            {packetDetails?.schoolDistrict &&
                                <p><span>District: </span> {packetDetails?.schoolDistrict?.districtName}</p>}
                             <p><span>Name: </span> {packetDetails?.school?.schoolName}</p>
                             <p><span>Address: </span> {packetDetails?.school?.address}, {packetDetails.school.city}, {packetDetails.school.zipCode}</p>
                             <p><span>Phone Number:</span> {packetDetails?.school?.phoneNumber}</p>
                        </div>
                    </div>}

                <div className={styles.eduInfo}>
                    <h3 className={styles.subTitle}>Student Information</h3>
                    <div className={styles.dFlex}>
                         <p><span>Age: </span> {packetDetails.studentAge} </p>
                         <p><span>Gender: </span> {packetDetails.studentGender} </p>
                    </div>
                </div>

                {packetDetails.requestedItems?.length &&
                    <div className={styles.itemsSec}>
                        <h4>Requested Items:</h4>
                        <ul>
                            {packetDetails.requestedItems.map(reqItem =>
                                <li key={reqItem.itemTypeId}>{reqItem.title}</li>)}
                        </ul>
                    </div>}


                {packetDetails.specialClothingRequirements &&
                    <div className={styles.itemsSec}>
                        <h4>Special Clothing Requirements: </h4>
                        <p>{packetDetails.specialClothingRequirements}</p>
                    </div>}

                <div className={styles.eduInfo}>
                    <div className={styles.dFlex}>
                        {packetDetails.shirtSize &&
                            <p><span>Student’s Shirt Size: </span> {packetDetails?.studentType} {packetDetails.shirtSize}</p>}
                        {packetDetails.pantSize &&
                            <p><span>Student’s Pant Size:</span> {packetDetails?.studentType} {packetDetails.pantSize}</p>}
                        {packetDetails.shoeSize &&
                            <p><span>Student’s Shoe Size: </span> {packetDetails?.studentType} {packetDetails.shoeSize}</p>}
                    </div>
                </div>

                {packetDetails.remarks &&
                    <div className={styles.itemsSec}>
                        <h4>Additional Needs/Priority Items: </h4>
                        <p>{packetDetails.additionalItems}</p>
                    </div>}

                {packetDetails.studentPreferences &&
                    <div className={styles.itemsSec}>
                        <h4>Student's Preferences: </h4>
                        <p>{packetDetails.studentPreferences}</p>
                    </div>}


                {packetDetails.remarks &&
                    <div className={styles.itemsSec}>
                        <h4>Other notes:</h4>
                        <p>{packetDetails.remarks}</p>
                    </div>}

                {packetDetails.partner
                    ?
                        <>
                            <br /><br />
                            <h3>Group Volunteer Details</h3>
                            {packetDetails.partner &&
                                <div>
                                    <br /><br />
                                    <p><span style={{fontWeight: 'bold', display: 'inline-block', marginRight: '10px'}}>Partner: </span> {packetDetails.partner.title}</p>
                                </div>}
                            {packetDetails.packetAcceptedBy &&
                                <div className={styles.eduInfo}>
                                    <h3 className={styles.subTitle}>Packet Accepted By</h3>
                                    <div className={styles.dFlex}>
                                        <p><span>Full Name:</span> {packetDetails.packetAcceptedBy?.user?.firstName} {packetDetails.packetAcceptedBy?.user?.lastName}</p>
                                        <p><span>Amount of Active Packets:</span> {packetDetails.packetAcceptedBy?.packetLeftToAssign}</p>
                                        <p><span>Email:</span> {packetDetails.packetAcceptedBy?.user?.emailAddress}</p>
                                        <p><span>Phone Number:</span> {packetDetails.packetAcceptedBy?.user?.phoneCountryCode} {packetDetails.packetAcceptedBy?.user?.phoneNumber}</p>
                                    </div>
                                </div>}
                            {packetDetails.shopCompletedBy &&
                                <div className={styles.eduInfo}>
                                    <h3 className={styles.subTitle}>Shop Completed By</h3>
                                    <div className={styles.dFlex}>
                                        <p><span>Full Name:</span> {packetDetails.shopCompletedBy.user.firstName} {packetDetails.shopCompletedBy.user.lastName}</p>
                                        <p><span>Amount of Active Packets:</span> {packetDetails.shopCompletedBy?.packetLeftToAssign}</p>
                                        <p><span>Email:</span> {packetDetails.shopCompletedBy.user.emailAddress}</p>
                                        <p><span>Phone Number:</span> {packetDetails.shopCompletedBy.user.phoneCountryCode} {packetDetails.shopCompletedBy.user.phoneNumber}</p>
                                    </div>
                                </div>}
                            {packetDetails.deliveredToOfficeBy &&
                                <div className={styles.eduInfo}>
                                    <h3 className={styles.subTitle}>Delivered To Office By</h3>
                                    <div className={styles.dFlex}>
                                        <p><span>Full Name:</span> {packetDetails.deliveredToOfficeBy.user.firstName} {packetDetails.deliveredToOfficeBy.user.lastName}</p>
                                        <p><span>Amount of Active Packets:</span> {packetDetails.deliveredToOfficeBy?.packetLeftToAssign}</p>
                                        <p><span>Email:</span> {packetDetails.deliveredToOfficeBy.user.emailAddress}</p>
                                        <p><span>Phone Number:</span> {packetDetails.deliveredToOfficeBy.user.phoneCountryCode} {packetDetails.deliveredToOfficeBy.user.phoneNumber}</p>
                                    </div>
                                </div>}
                        </>
                    :
                        <>
                            {packetDetails.volunteer &&
                                <div className={styles.eduInfo}>
                                    <h3 className={styles.subTitle}>Volunteer Information</h3>
                                    <div className={styles.dFlex}>
                                        <p><span>Full Name:</span> {packetDetails.volunteer.user.firstName} {packetDetails.volunteer.user.lastName}</p>
                                        <p><span>Amount of Active Packets:</span> {packetDetails.volunteer?.packetLeftToAssign}</p>
                                        <p><span>Email:</span> {packetDetails.volunteer.user.emailAddress}</p>
                                        <p><span>Phone Number:</span> {packetDetails.volunteer.user.phoneCountryCode} {packetDetails.volunteer.user.phoneNumber}</p>
                                    </div>
                                </div>}
                        </>}

                {(packetDetails.invoices?.length > 0 || packetDetails.volunteerDeliveryImage || packetDetails.educatorDeliveryImage) &&
                    <div className={styles.eduInfo}>
                        {packetDetails.invoices?.length > 0 &&
                            <>
                                <h3 className={styles.subTitle}>Shop Completed Images</h3>
                                <div style={{
                                    display: 'grid', gap: '8px',
                                    gridTemplateColumns: 'auto auto auto',
                                    marginLeft: '20px', marginRight: '20px'
                                }}>
                                    {packetDetails.invoices.map((invoice, i) =>
                                        <a key={i} onClick={() => window.open(invoice.invoiceFileUrl, "_blank")}>
                                            <img style={{ width: '100%', maxWidth: '100px', height: 'auto' }}
                                                src={invoice.invoiceFileUrl} />
                                        </a>)}
                                </div>
                                <br />
                            </>}
                        {packetDetails.volunteerDeliveryImage &&
                            <>
                                <h3 className={styles.subTitle}>Delivered to Office Images</h3>
                                <div style={{
                                    display: 'grid', gap: '8px',
                                    gridTemplateColumns: 'auto auto auto',
                                    marginLeft: '20px', marginRight: '20px'
                                }}>
                                    <a onClick={() => window.open(packetDetails.volunteerDeliveryImage.proofOfDeliveryFileUrl, "_blank")}>
                                        <img style={{ width: '100%', maxWidth: '100px', height: 'auto' }}
                                             src={packetDetails.volunteerDeliveryImage.proofOfDeliveryFileUrl} />
                                    </a>
                                </div>
                                <br />
                            </>}

                        {packetDetails.educatorDeliveryImage &&
                            <>
                                <h3 className={styles.subTitle}>Delivered to Student Images</h3>
                                <div style={{
                                    display: 'grid', gap: '8px',
                                    gridTemplateColumns: 'auto auto auto',
                                    marginLeft: '20px', marginRight: '20px'
                                }}>
                                    <a onClick={() => window.open(packetDetails.educatorDeliveryImage.proofOfDeliveryFileUrl, "_blank")}>
                                        <img style={{ width: '100%', maxWidth: '100px', height: 'auto' }}
                                             src={packetDetails.educatorDeliveryImage.proofOfDeliveryFileUrl} />
                                    </a>
                                </div>
                                <br />
                            </>}

                    </div>}

              </div>}

            {!packetDetails &&
                <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '50px' }}>
                    <Spinner />
                </div>}
        </div>
    </section>
  </div>
    );
}

export default Viewpackets;



