
import React, {useEffect, useState} from "react";
import 'font-awesome/css/font-awesome.min.css';
import Sidebar from "../../app/components/sidebar";
import Header from "../../app/components/header";
import styles from "./notifications.module.scss";
import {fetchGetRequest} from "../../utils/network";
import endpoints from "../../utils/endpoints";
import Spinner from "react-bootstrap/Spinner";
import ReactPaginate from "react-paginate";
import SendNotificationModal from "../../components/common/SendNotificationModal";
import {useNavigate} from "react-router-dom";




const Notifications = () => {
    const [ownNotifications, setOwnNotifications] = useState(null);
    const [ownPagination, setOwnPagination] = useState({page:1, size: 10});
    const [ownPageCountOptions, setOwnPageCountOptions] = useState([ownPagination.size, 20, 50])
    const [ownLastPage, setOwnLastPage] = useState(1);

    const [sentNotifications, setSentNotifications] = useState(null);
    const [sentPagination, setSentPagination] = useState({page:1, size:10});
    const [sentPageCountOptions, setSentPageCountOptions] = useState([ownPagination.size, 20, 50])
    const [sentLastPage, setSentLastPage] = useState(1);

    const [showSendNotification, setShowSendNotification] = useState(false);

  // const tableWrapper = document.querySelector(".table-wrapper");
  // const switchInputs = document.querySelectorAll(".switch-wrapper input");
  // const prices = tableWrapper.querySelectorAll(".price");
  // const toggleClass = "hide";
  
  // const $button  = document.querySelector('#sidebar-toggle');
	// const $wrapper = document.querySelector('#wrapper');
	
	// $button.addEventListener('click', (e) => {
	//   e.preventDefault();
	//   $wrapper.classList.toggle('toggled');
	// });

    const navigate = useNavigate();

    const fetchOwnNotifications = async () => {
        setOwnNotifications(null);
        try {
            const response = await fetchGetRequest(
                endpoints.notifications.getAll, {
                    params: {...ownPagination}
                }
            )
            if(Array.isArray(response.data?.notifications)) {
                setOwnNotifications(response.data.notifications);
                setOwnLastPage(response.data.pagination.lastPage);
            }
            
        } catch (err) {
            console.error(err);
        }
    }

    const fetchSentNotifications = async () => {
        setSentNotifications(null);
        try {
            const response = await fetchGetRequest(
                endpoints.notifications.getSentNotifications, {
                    params: { ...sentPagination }
                }
            )
            if(Array.isArray(response.data?.notifications)) {
                setSentNotifications(response.data.notifications)
                setSentLastPage(response.data.pagination.lastPage)
            }

        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        fetchOwnNotifications();
    }, [ownPagination])

    useEffect(() => {
        fetchSentNotifications();
    }, [sentPagination]);

  return (    

  <div id="wrapper" className="">    
    <Sidebar/>
    <Header />

      <SendNotificationModal
          open={showSendNotification}
          onClose={() => {
              setShowSendNotification(false);
              fetchSentNotifications();
          }}
      />

    <section id="content-wrapper">
            <div className="row">
                <h2 className={styles.contentTitle}>Notifications</h2>
                <div className="col-lg-12">
                  <div style={{
                      display: 'flex', alignItems: 'center'
                  }}>
                      <div>
                        <h3 className={styles.subTitle2}>Your Notifications</h3>
                      </div>
                  </div>

                  {ownNotifications &&
                      <>
                      {ownNotifications?.map(notification => {
                          const packetId = notification?.notificationMetaData?.packetId;
                            return (
                                <div className={`${styles.requestSec} ${packetId ? styles.hoverable : ""}`}
                                    onClick={() => navigate(`/packets/details/${packetId}`)}>
                                    <h3><span style={{fontWeight: 'bolder'}}>{notification?.notificationMetaData?.isPartner === "Yes" ? "(Group)" : ""}</span> {notification.notificationSubject}</h3>
                                    <p>{notification.notificationBody}</p>
                                </div>
                            )
                          })}
                      </>}

                  {ownNotifications?.length === 0 &&
                     <div>
                         <p style={{
                              textAlign: 'center', color: 'grey', paddingTop: '15px'
                            }}>No Notifications!</p>
                     </div>}

                  {ownNotifications === null &&
                    <div style={{
                        display: 'flex', justifyContent: 'center', width: '100%'
                    }}>
                        <Spinner animation="border" />
                    </div>}
    
                    <br />
                    <div style={{
                        display: 'flex', justifyContent: 'flex-end', marginLeft: '50px',
                        visibility: ownNotifications?.length > 0 ? 'visible' : 'hidden'
                    }}>
                        <ReactPaginate
                            className="Pagination"
                            breakLabel="..."
                            nextLabel=">"
                            onPageChange={(event) =>
                                setOwnPagination(x => ({...x, page: event.selected+1}))}
                            pageRangeDisplayed={5}
                            pageCount={ownLastPage}
                            previousLabel="<"
                            renderOnZeroPageCount={null}
                        />
                        <div style={{
                            display: 'flex', alignItems: 'center', marginLeft: '15px'
                        }}>
                            <select value={ownPagination.size}
                                    style={{ paddingTop: '10px', paddingBottom: '10px', borderRadius: '6px' }}
                                    className={styles.selectPrt}
                                    onChange={e =>
                                        setOwnPagination(x => ({page:1, size: e.target.value}))}>
                                {ownPageCountOptions.map((x, i) =>
                                    <option key={i} value={x}>{x}</option>)}
                            </select>
                        </div>
                    </div>
                    
                    <br />

                <div className={`${styles.dFlex} ${styles.mt30}`}>
                    <div style={{
                        display: 'flex'
                    }}>
                        <h3 className={styles.subTitle2}>Notifications You’ve Sent</h3>
                    </div>
                    <div><button className={styles.sendBtn} data-toggle="modal" data-target="#basicModal"
                        onClick={() => {
                            setShowSendNotification(true);
                        }}>Send Notification</button></div>
                </div>

                {sentNotifications?.length > 0 &&
                    <>
                        {sentNotifications?.map(notification => {
                            return (
                                <div className={styles.requestSec}>
                                    <h3><span style={{fontWeight: 'bolder'}}>{notification?.notificationMetaData?.isPartner === "Yes" ? "(Group)" : ""}</span> {notification.notificationSubject}</h3>
                                    <p>{notification.notificationBody}</p>
                                </div>
                            )
                        })}
                    </>}

                {sentNotifications?.length === 0 &&
                    <div>
                        <p style={{
                            textAlign: 'center', color: 'grey', paddingTop: '15px'
                        }}>No Notifications have been sent!</p>
                    </div>}

                {sentNotifications === null &&
                    <div style={{
                        display: 'flex', justifyContent: 'center', width: '100%'
                    }}>
                        <Spinner animation="border" />
                    </div>}

                    <br />

                    <div style={{
                        display: 'flex', justifyContent: 'flex-end', marginLeft: '50px',
                        visibility: sentNotifications?.length > 0 ? 'visible' : 'hidden'
                    }}>
                        <ReactPaginate
                            className="Pagination"
                            breakLabel="..."
                            nextLabel=">"
                            onPageChange={(event) =>
                                setSentPagination(x => ({...x, page: event.selected+1}))}
                            pageRangeDisplayed={5}
                            pageCount={sentLastPage}
                            previousLabel="<"
                            renderOnZeroPageCount={null}
                        />
                        <div style={{
                            display: 'flex', alignItems: 'center', marginLeft: '15px'
                        }}>
                            <select value={sentPagination.size}
                                    style={{ paddingTop: '10px', paddingBottom: '10px', borderRadius: '6px' }}
                                    className={styles.selectPrt}
                                    onChange={e =>
                                        setSentPagination(x => ({page:1, size: e.target.value}))}>
                                {sentPageCountOptions.map((x, i) =>
                                    <option key={i} value={x}>{x}</option>)}
                            </select>
                        </div>
                    </div>
                    <br />

              </div>
            </div>
        </section>
  </div>
);}
export default Notifications;



