import styles from "./filterByModal.module.scss";
import closeIcon_img from "../../assets/images/close-icon.png";
import React, {useEffect, useState} from "react";
import Form from 'react-bootstrap/Form';
import {fetchGetRequest} from "../../utils/network";
import endpoints from "../../utils/endpoints";
import Spinner from "react-bootstrap/Spinner";




const FilterByModal = ({ open, onClose, activeFilters, setActiveFilters }) => {
    const [filters, setFilters] = useState({});
    const [selectableEducators, setSelectableEducators] = useState(null);
    const [selectableVolunteers, setSelectableVolunteers] = useState(null);
    const [selectableSchools, setSelectableSchools] = useState(null);
    const [selectableSchoolDistricts, setSelectableSchoolDistricts] = useState(null);
    const pagination = {page: 1, size: 999}

    const fetchEducators = async () => {
        try {
            const response = await fetchGetRequest(endpoints.educator.getAll, {params: pagination})
            if(response.status < 400 && Array.isArray(response.data.users)) {
                setSelectableEducators(response.data.users)
            }
        } catch(err) {

        }
    }

    const fetchVolunteers = async () => {
        try {
            const response = await fetchGetRequest(endpoints.volunteer.getAll, {params: pagination})
            if(response.status < 400 && Array.isArray(response.data.users)) {
                setSelectableVolunteers(response.data.users)
            }
        } catch (err) {

        }
    }

    const fetchSchoolDistricts = async () => {
        try {
            const response = await fetchGetRequest(endpoints.schoolDistrictManagement.getAll, {params: pagination})
            if(response.status < 400 && Array.isArray(response.data.schoolDistricts)) {
                setSelectableSchoolDistricts(response.data.schoolDistricts)
            }
        } catch (err) {

        }
    }

    const fetchSchools = async () => {
        try {
            const response = await fetchGetRequest(endpoints.schoolManagement.getAll, {params: pagination})
            if(response.status < 400 && Array.isArray(response.data.schools)) {
                setSelectableSchools(response.data.schools)
            }
        } catch (err) {

        }
    }

    const loadFilters = () => {
        const newFiltersState = {};
        if(activeFilters.educatorId) {
            newFiltersState.educator = activeFilters.educatorId;
        }
        if(activeFilters.volunteerId) {
            newFiltersState.volunteer = activeFilters.volunteerId;
        }
        if(activeFilters.schoolId) {
            newFiltersState.school = activeFilters.schoolId;
        }
        if(activeFilters.studentAge) {
            newFiltersState.studentAge = activeFilters.studentAge;
        }
        if(activeFilters.studentGender) {
            newFiltersState.studentGender = activeFilters.studentGender;
        }
        if(activeFilters.packetStatus) {
            newFiltersState.packetStatus = activeFilters.packetStatus;
        }
        if(activeFilters.schoolDistrictId) {
            newFiltersState.schoolDistrict = activeFilters.schoolDistrictId;
        }
        setFilters(newFiltersState)
    }

    const saveFilters = () => {
        const newFiltersState = {};
        if(filters.educator) {
            newFiltersState.educatorId = filters.educator;
        }
        if(filters.volunteer) {
            newFiltersState.volunteerId = filters.volunteer;
        }
        if(filters.school) {
            newFiltersState.schoolId = filters.school;
        }
        if(filters.studentAge) {
            newFiltersState.studentAge = filters.studentAge;
        }
        if(filters.studentGender) {
            newFiltersState.studentGender = filters.studentGender;
        }
        if(filters.packetStatus) {
            newFiltersState.packetStatus = filters.packetStatus;
        }
        if(filters.schoolDistrict) {
            newFiltersState.schoolDistrictId = filters.schoolDistrict;
        }
        setActiveFilters(newFiltersState);
    }

    useEffect(() => {
        if(open) {
            document.getElementsByTagName('body')?.[0].classList.add(styles['modal-open']);
            fetchVolunteers()
            fetchEducators()
            fetchSchools();
            fetchSchoolDistricts();

            loadFilters();
        } else {
            document.getElementsByTagName('body')?.[0].classList.remove(styles['modal-open']);
        }
    }, [open]);

    const removeFilter = (name) => {
        return () => {
            console.log("removeFilter!!!");
            if(filters.hasOwnProperty(name)) {
                setFilters((x) => {
                    const y = {...x};
                    delete y[name];
                    return y;
                })
            }
        }
    }

    const updateFilter = (name, value) => {
        setFilters(x => {
            const y = {...x, [name]: value}
            if(!value) {
                delete y[name];
            }
            return y;
        })
    }


    const isDataLoaded = () =>
        selectableEducators?.length >= 0 && selectableVolunteers?.length >= 0 && selectableSchools?.length >= 0
    const isDataLoading = () =>
        selectableEducators === null || selectableVolunteers === null || selectableSchools === null

    return (
        <div className={`${styles.modal} ${styles.fade} ${open ? styles.show : ''}`}
             tabIndex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
            <div className={styles['modal-dialog']}>
                <div className={styles['modal-content']}>
                    <div className={styles['modal-header']}>
                        <h4 className={styles['modal-title']}>Filter by</h4>
                        <button type="button" onClick={onClose} className={styles['close']} data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true"><img src={closeIcon_img} alt="close-icon" /></span>
                        </button>
                    </div>
                    {isDataLoading() &&
                        <div style={{
                            display: 'flex', justifyContent: 'center'
                        }}>
                            <Spinner animation="border" />
                        </div>}
                    {isDataLoaded() &&
                        <form className={styles['modal-body']}
                            style={{
                                padding: '30px',
                                display: 'flex', flexDirection: 'column', gap: '20px'
                            }}>
                            <div style={{
                                display: 'flex'
                            }}>
                                <input type="radio" style={{ height: '20px', width: '20px', marginRight: '10px' }}
                                   checked={filters.hasOwnProperty("educator")}
                                   onClick={removeFilter("educator")} />
                                <span style={{flex: 1}}>Educator</span>
                                <Form.Select name="educator" style={{flex: 1}} value={filters.educator || ''}
                                    onChange={e => updateFilter("educator", e.target.value)}>
                                    <option disabled={!filters.hasOwnProperty("educator")}
                                            value="">Select Educator</option>
                                    {selectableEducators?.map(educator =>
                                        <option value={educator.educator.educatorId}>{educator.fullName}</option>)}
                                </Form.Select>
                            </div>
                            <div style={{
                                display: 'flex'
                            }}>
                                <input type="radio" style={{ height: '20px', width: '20px', marginRight: '10px' }}
                                   checked={filters.hasOwnProperty("volunteer")}
                                   onClick={removeFilter("volunteer")} />
                                <span style={{flex: 1}}>Volunteer</span>
                                <Form.Select style={{flex: 1}} value={filters.volunteer || ''}
                                     onChange={e => updateFilter("volunteer", e.target.value)}>
                                    <option disabled={!filters.hasOwnProperty("volunteer")} value="">Select Volunteer</option>
                                    {selectableVolunteers?.map(volunteer =>
                                        <option value={volunteer.volunteer.volunteerId}>{volunteer.fullName}</option>)}
                                </Form.Select>
                            </div>
                            <div style={{
                                display: 'flex'
                            }}>
                                <input type="radio" style={{ height: '20px', width: '20px', marginRight: '10px' }}
                                    checked={filters.hasOwnProperty("school")}
                                    onClick={removeFilter("school")} />
                                <span style={{flex: 1}}>School</span>
                                <Form.Select style={{flex: 1}} value={filters.school || ''}
                                     onChange={e => updateFilter("school", e.target.value)}>
                                    <option disabled={!filters.hasOwnProperty("school")} value="">Select School</option>
                                    {selectableSchools?.map(school =>
                                        <option value={school.schoolId}>{school.schoolName}</option>)}
                                </Form.Select>
                            </div>
                            <div style={{
                                display: 'flex'
                            }}>
                                <input type="radio" style={{ height: '20px', width: '20px', marginRight: '10px' }}
                                    checked={filters.hasOwnProperty("studentAge")}
                                    onClick={removeFilter("studentAge")} />
                                <span style={{flex: 1}}>Students Age</span>
                                <Form.Select style={{flex: 1}} value={filters.studentAge || ''}
                                     onChange={e => updateFilter("studentAge", e.target.value)}>
                                    <option disabled={!filters.hasOwnProperty("studentAge")} value="">Select Age</option>
                                    {Object.keys(Array(60).fill(0)).map(age => {
                                        if(age > 0)
                                            return <option value={age}>{age}</option>;
                                        return null;
                                    })}
                                </Form.Select>
                            </div>
                            <div style={{
                                display: 'flex'
                            }}>
                                <input type="radio" style={{ height: '20px', width: '20px', marginRight: '10px' }}
                                    checked={filters.hasOwnProperty("studentGender")}
                                    onClick={removeFilter("studentGender")} />
                                <span style={{flex: 1}}>Students Gender</span>
                                <Form.Select style={{flex: 1}} value={filters.studentGender || ''}
                                     onChange={e => updateFilter("studentGender", e.target.value)}>
                                    <option disabled={!filters.hasOwnProperty("studentGender")} value="">Select Gender</option>
                                    <option>Male</option>
                                    <option>Female</option>
                                </Form.Select>
                            </div>
                            <div style={{
                                display: 'flex'
                            }}>
                                <input type="radio" style={{ height: '20px', width: '20px', marginRight: '10px' }}
                                       checked={filters.hasOwnProperty("packetStatus")}
                                       onClick={removeFilter("packetStatus")} />
                                <span style={{flex: 1}}>Request Status</span>
                                <Form.Select style={{flex: 1}} value={filters.packetStatus || ''}
                                             onChange={e => updateFilter("packetStatus", e.target.value)}>
                                    <option disabled={!filters.hasOwnProperty("packetStatus")} value="">Select Request Status</option>
                                    <option>Request Submitted</option>
                                    <option>Volunteer Assigned</option>
                                    <option>Volunteer Accepted</option>
                                    <option>Shop Completed</option>
                                    <option>Delivered To Office</option>
                                    <option>Delivered To Student</option>
                                </Form.Select>
                            </div>
                            <div style={{
                                display: 'flex'
                            }}>
                                <input type="radio" style={{ height: '20px', width: '20px', marginRight: '10px' }}
                                       checked={filters.hasOwnProperty("schoolDistrict")}
                                       onClick={removeFilter("schoolDistrict")} />
                                <span style={{flex: 1}}>School District</span>
                                <Form.Select style={{flex: 1}} value={filters.schoolDistrict || ''}
                                             onChange={e => updateFilter("schoolDistrict", e.target.value)}>
                                    <option disabled={!filters.hasOwnProperty("schoolDistrict")} value="">Select School District</option>
                                    {selectableSchoolDistricts?.map(schoolDistrict =>
                                        <option value={schoolDistrict.schoolDistrictId}>{schoolDistrict.districtName}</option>)}
                                </Form.Select>
                            </div>
                        </form>}
                    <div className={styles['modal-footer']}>
                        {isDataLoaded() &&
                            <button type="button"
                                className={`${styles['confirmBtn']}`}
                                onClick={() => {
                                    saveFilters(); onClose();
                                }}>Filter</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FilterByModal;
