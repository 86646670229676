import styles from "./filterByVolunteerAndEducatorModal.module.scss";
import 'react-select-search/style.css'
import closeIcon_img from "../../assets/images/close-icon.png";
import React, {useEffect, useState} from "react";
import Form from 'react-bootstrap/Form';
import {fetchGetRequest} from "../../utils/network";
import endpoints from "../../utils/endpoints";
import Spinner from "react-bootstrap/Spinner";
import SelectSearch from "react-select-search";


const inputStyles = {
    display: 'block',
    width: '100%',
    padding: '0.375rem 2.25rem 0.375rem 0.75rem',
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: 1.5,
    border: '1px solid #dee2e6',
    borderRadius: '0.375rem',
}

const inputFilledStyles = {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRight: 'none'
}

const inputClearBtnStyles = {
    border: '1px solid #dee2e6',
    borderRadius: '0.375rem',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    background: 'transparent',
    borderLeft: 0
}

const FilterByVolunteerAndEducatorModal = ({ open, onClose, activeFilters, setActiveFilters }) => {
    const [filters, setFilters] = useState({});
    const [selectableEducators, setSelectableEducators] = useState(null);
    const [selectableVolunteers, setSelectableVolunteers] = useState(null);
    const pagination = {page: 1, size: 999}

    const fetchEducators = async () => {
        try {
            const response = await fetchGetRequest(endpoints.educator.getAll, {params: pagination})
            if(response.status < 400 && Array.isArray(response.data.users)) {
                setSelectableEducators(response.data.users)
            }
        } catch(err) {

        }
    }

    const fetchVolunteers = async () => {
        try {
            const response = await fetchGetRequest(endpoints.volunteer.getAll, {params: pagination})
            if(response.status < 400 && Array.isArray(response.data.users)) {
                setSelectableVolunteers(response.data.users)
            }
        } catch (err) {

        }
    }

    const loadFilters = () => {
        const newFiltersState = {};
        if(activeFilters.educatorId) {
            newFiltersState.educator = activeFilters.educatorId;
        }
        if(activeFilters.volunteerId) {
            newFiltersState.volunteer = activeFilters.volunteerId;
        }
        setFilters(newFiltersState)
    }

    const saveFilters = () => {
        const newFiltersState = {};
        if(filters.educator) {
            newFiltersState.educatorId = filters.educator;
        }
        if(filters.volunteer) {
            newFiltersState.volunteerId = filters.volunteer;
        }
        setActiveFilters(newFiltersState);
    }

    useEffect(() => {
        if(open) {
            document.getElementsByTagName('body')?.[0].classList.add(styles['modal-open']);
            fetchVolunteers()
            fetchEducators()

            loadFilters();
        } else {
            document.getElementsByTagName('body')?.[0].classList.remove(styles['modal-open']);
        }
    }, [open]);

    const removeFilter = (name) => {
        return () => {
            console.log("removeFilter!!!");
            if(filters.hasOwnProperty(name)) {
                setFilters((x) => {
                    const y = {...x};
                    delete y[name];
                    return y;
                })
            }
        }
    }

    const updateFilter = (name, value) => {
        setFilters(x => {
            const y = {...x, [name]: value}
            if(!value) {
                delete y[name];
            }
            return y;
        })
    }


    const isDataLoaded = () =>
        selectableEducators?.length >= 0 && selectableVolunteers?.length >= 0
    const isDataLoading = () =>
        selectableEducators === null || selectableVolunteers === null

    return (
        <div className={`${styles.modal} ${styles.fade} ${open ? styles.show : ''}`}
             tabIndex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
            <div className={styles['modal-dialog']}>
                <div className={styles['modal-content']}>
                    <div className={styles['modal-header']}>
                        <h4 className={styles['modal-title']}>Filter by</h4>
                        <button type="button" onClick={onClose} className={styles['close']} data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true"><img src={closeIcon_img} alt="close-icon" /></span>
                        </button>
                    </div>
                    {isDataLoading() &&
                        <div style={{
                            display: 'flex', justifyContent: 'center'
                        }}>
                            <Spinner animation="border" />
                        </div>}
                    {isDataLoaded() &&
                        <form className={styles['modal-body']}
                              style={{
                                  padding: '30px',
                                  display: 'flex', flexDirection: 'column', gap: '20px'
                              }}>
                            <div style={{display: 'flex'}}>
                                <input type="radio" style={{ height: '20px', width: '20px', marginRight: '10px' }}
                                       checked={filters.hasOwnProperty("educator")}
                                       onClick={removeFilter("educator")} />
                                <span className={styles.hoverable} style={{flex: 1}} onClick={removeFilter('educator')}>Educator</span>
                                <div style={{display: 'flex', width: '400px', maxWidth: '52%'}}>
                                    <SelectSearch search autoComplete="on" key={filters.educator} options={
                                            selectableEducators.map(educator => ({
                                                name: educator.fullName,
                                                value: educator.educator.educatorId
                                            }))
                                        } value={filters.educator} name="Educator" placeholder="Select Educator"
                                        onChange={newValue => {
                                            console.log("newValue: ", newValue);
                                            updateFilter('educator', newValue)
                                        }}
                                        renderValue={(valueProps) =>
                                            <input {...valueProps}
                                               style={{...inputStyles, ...(
                                                   filters.educator ? inputFilledStyles : {}
                                               )}}
                                            />
                                        }
                                    />
                                    {filters.educator &&
                                        <button style={inputClearBtnStyles} onClick={removeFilter('educator')}>
                                            <i className="fa fa-close" />
                                        </button>}
                                </div>
                            </div>
                            <div style={{display: 'flex'}}>
                                <input type="radio" style={{ height: '20px', width: '20px', marginRight: '10px' }}
                                       checked={filters.hasOwnProperty("volunteer")}
                                       onClick={removeFilter("volunteer")} />
                                <span className={styles.hoverable} style={{flex: 1}} onClick={removeFilter("volunteer")}>Volunteer</span>
                                <div style={{display: 'flex', width: '400px', maxWidth: '52%'}}>
                                    <SelectSearch search autoComplete="on" key={filters.volunteer} options={
                                        selectableVolunteers.map(volunteer => ({
                                            name: volunteer.fullName,
                                            value: volunteer.volunteer.volunteerId
                                        }))
                                    } value={filters.volunteer} name="Volunteer" placeholder="Select Volunteer"
                                          onChange={newValue => {
                                              updateFilter('volunteer', newValue)
                                          }}
                                          renderValue={(valueProps) =>
                                              <input {...valueProps}
                                                     style={{...inputStyles, ...(
                                                             filters.volunteer ? inputFilledStyles : {}
                                                         )}}
                                              />
                                          }
                                    />
                                    {filters.volunteer &&
                                        <button style={inputClearBtnStyles} onClick={removeFilter('volunteer')}>
                                            <i className="fa fa-close" />
                                        </button>}
                                </div>
                            </div>
                        </form>}
                    <div className={styles['modal-footer']}>
                        {isDataLoaded() &&
                            <button type="button"
                                    className={`${styles['confirmBtn']}`}
                                    onClick={() => {
                                        saveFilters(); onClose();
                                    }}>Filter</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FilterByVolunteerAndEducatorModal;
