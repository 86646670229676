import React, {useEffect, useRef, useState} from "react";
import "font-awesome/css/font-awesome.min.css";
import Sidebar from "../../app/components/sidebar";
import Header from "../../app/components/header";
import styles from "./admins.module.scss";
import { fetchDeleteRequest, fetchGetRequest } from "../../utils/network";
import endpoints from "../../utils/endpoints";
import toastMessage from "../../utils/toastMessage";
import constants from "../../utils/constants";
import ReactPaginate from "react-paginate";
import DeleteConfirmationModal from "../../components/common/DeleteConfirmationModal";
import Spinner from "react-bootstrap/Spinner";
import CreateSubAdmin from "./CreateSubAdmin";


const AdminRow = ({user, onEdit, onDelete, onToggleAccordion}) => {
    const [showActions, setShowActions] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setShowActions(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    return (
        <div ref={ref} className={styles.tableBody} key={user?.userId}>
            <div className={styles.tableRow}>
                <div className={styles.tableSmall}>
                    <div className={styles.tableCell}>Educator Name:</div>
                    <div className={styles.tableCell}>{user?.fullName}</div>
                </div>
                <div className={styles.tableSmall}>
                    <div className={styles.tableCell}>Requests:</div>
                    <div className={styles.tableCell}>{user?.emailAddress}</div>
                </div>
                <div className={`${styles.tableSmall} ${styles.lastCol}`}>
                    <div className={styles.tableCell}>Action:</div>
                    <div className={styles.tableCell}>
                        <div className={styles.dFlex5} style={{
                            display: 'flex', justifyContent: 'flex-end'
                        }}>
                            <div className={styles.dropdownSec}>
                                <button className={styles.viewAllBtn} onClick={() => {
                                    onDelete(user.userId)
                                }}>Remove Admin Access</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const RenderHeaderRow = ({ label, value, ordering, updateOrdering }) => {
    const [hovering, setHovering] = useState(false);

    return (
        <div className={`${styles.tableHeader} ${value ? styles.hoverableHeader : ""}`}
             onMouseOver={() => setHovering(true)} onMouseOut={() => setHovering(false)}
             onClick={() => {
                 if(value) updateOrdering(value);
             }}
             style={{backgroundColor: ordering.orderBy === value ? '#fff' : 'transparent'}}>
            {label}
            <span className="sortDownArrow">
          {(value && ordering.orderBy === value && !hovering)
              ? (ordering.orderType == 'ASC'
                  ? <img src={"../images/arrow-up.png"} alt="Down Arrow"/>
                  : <img src={"../images/arrow-down.png"} alt="Down Arrow"/>)
              : null}

                {(value && ordering.orderBy === value && hovering)
                    ? (ordering.orderType == 'ASC'
                        ? <img src={"../images/arrow-down.png"} alt="Down Arrow" style={{ opacity: 0.3 }} />
                        : <img src={"../images/arrow-up.png"} alt="Down Arrow" style={{ opacity: 0.3 }} />)
                    : null}

                {(value && hovering && ordering.orderBy !== value) &&
                    <img src={"../images/arrow-up.png"} alt="Down Arrow"
                         style={{ opacity: 0.3 }} />}

            </span>
        </div>
    )
}



const Admins = () => {
    const [subAdmins, setSubAdmins] = useState(null);
    const [loadingData, setLoadingData] = useState(false);
    const [searchInputValue, setSearchInputValue] = useState("");
    const [pagination, setPagination] = useState({page:1, size: 10})
    const [ordering, setOrdering] = useState({orderBy: 'firstName', orderType: 'ASC'})
    const [pageCountOptions, setPageCountOptions] = useState([pagination.size, 20, 50])
    const [lastPage, setLastPage] = useState(1);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState('');
    const [ showAdminCreationModal, setShowAdminCreationModal ] = useState(false);

    const getSubAdminData = async () => {
        setLoadingData(true);
        try {
            const params = {
                ...pagination, ...ordering,
                keywords: searchInputValue,
            };

            const response = await fetchGetRequest(endpoints.subadmin.getAll, {
                params,
            });
            if (response.data.administrators.length > 0) {
                setSubAdmins(() => {
                    return response.data.administrators;
                });
                setLastPage(response.data.pagination.lastPage)
            } else {
                setSubAdmins([]);
                setLastPage(1);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoadingData(false);
        }
    };

    useEffect(() => {
        getSubAdminData();
    }, [searchInputValue, pagination, ordering]);

    const deleteSubadmin = async (id) => {
        try {
            const response = await fetchDeleteRequest(endpoints.subadmin.delete(id));
            toastMessage(constants.TOAST_TYPE.SUCCESS, response?.data?.message);
            getSubAdminData();
        } catch (err) {
            console.log(err);
            toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
        }
    };

    const handlePageClick = (event) => {
        setPagination(x => ({...x, page: event.selected+1}))
    }

    const updateOrdering = (orderBy) => {
        setOrdering(x => {
            if(x.orderBy === orderBy) {
                const orderType = x.orderType === 'ASC' ? 'DESC' : 'ASC';
                return {...x, orderType}
            }
            return {orderBy, orderType: 'ASC'}
        })
    }

    return (
        <div id="wrapper" className="">
            <DeleteConfirmationModal open={!!showDeleteConfirmation}
                 onClose={() => setShowDeleteConfirmation('')}
                 title="Remove Subadmin?"
                 body="Are you sure you want to remove the subadmin?"
                 onConfirm={() => {
                     deleteSubadmin(showDeleteConfirmation); setShowDeleteConfirmation('');
                 }}
            />
            <CreateSubAdmin onClose={() => {
                    setSearchInputValue("");
                    if(searchInputValue === "") getSubAdminData();
                    setShowAdminCreationModal(false)
                }}
                open={showAdminCreationModal} />

            <Sidebar />
            <Header />
            <section id="content-wrapper">
                <div className="row">
                    <div className="col-lg-12">
                        <h2 className={styles.contentTitle}>Admins</h2>
                        <div className={styles.volunteerPageTitle}>
                            <div className={styles.titleSearchPrt} style={{
                                display: 'flex', justifyContent: 'flex-end', alignItems: 'center'
                            }}>
                                <input
                                    type="search"
                                    placeholder="Search"
                                    value={searchInputValue}
                                    onChange={(e) => {
                                        setSearchInputValue(e.target.value);
                                    }}
                                />
                            </div>
                        </div>

                        {(!loadingData && subAdmins?.length > 0) &&
                            <div className={styles.volunteerDataTable}>
                                <div className={styles.tableBody}>
                                    <div className={styles.theader}>
                                        <RenderHeaderRow label="Admin Name" value="firstName"
                                             ordering={ordering} updateOrdering={updateOrdering}
                                        />
                                        <RenderHeaderRow label="Admin Email" value="emailAddress"
                                             ordering={ordering} updateOrdering={updateOrdering}
                                        />
                                        <div
                                            className={`${styles.tableHeader} ${styles.lastCol}`}
                                            style={{
                                                display: 'flex', justifyContent: 'flex-end'
                                            }}
                                        >
                                            <button className={styles.viewAllBtn} style={{
                                                borderRadius: '100px', padding: '0',
                                                width: '35px', height: '35px', fontSize: '18px',
                                                lineHeight: 1, textAlign: 'center'
                                            }} onClick={() => setShowAdminCreationModal(true)}>
                                                +
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {subAdmins?.map((user) => {
                                    return (
                                        <AdminRow user={user}
                                             onDelete={setShowDeleteConfirmation}
                                             onToggleAccordion={() => {
                                                 setSubAdmins((prev) => {
                                                     return prev?.map((item) => {
                                                         if (item?.userId === user?.userId) {
                                                             return {
                                                                 ...item,
                                                                 isAccordianOpen:
                                                                     !item?.isAccordianOpen,
                                                             };
                                                         }
                                                         return item;
                                                     });
                                                 });
                                             }}
                                        />
                                    );
                                })}
                            </div>}

                        {(loadingData || subAdmins === null) &&
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Spinner animation="border" />
                            </div>}

                        {(!loadingData && subAdmins?.length === 0) &&
                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                <div>No Records Found!</div><br />
                                <button className={styles.viewAllBtn}
                                    onClick={() => setShowAdminCreationModal(true)}>Create Subadmin</button>
                            </div>}

                        {subAdmins?.length > 0 &&
                            <div style={{
                                display: 'flex', justifyContent: 'flex-end', marginTop: '50px'
                            }}>
                                <ReactPaginate
                                    className="Pagination"
                                    breakLabel="..."
                                    nextLabel="Next"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={5}
                                    pageCount={lastPage}
                                    previousLabel="Previous"
                                    renderOnZeroPageCount={null}
                                />
                                <div style={{
                                    display: 'flex', alignItems: 'center', marginLeft: '15px'
                                }}>
                                    <select value={pagination.size}
                                            style={{ paddingTop: '10px', paddingBottom: '10px', borderRadius: '6px' }}
                                            className={styles.selectPrt}
                                            onChange={e =>
                                                setPagination(x => ({page:1, size: e.target.value}))}>
                                        {pageCountOptions.map((x, i) =>
                                            <option key={i} value={x}>{x}</option>)}
                                    </select>
                                </div>
                            </div>}
                    </div>
                </div>
            </section>
        </div>
    );
};


export default Admins;
