
import React from "react";
import 'font-awesome/css/font-awesome.min.css';
import Sidebar from "../../app/components/sidebar";
import Header from "../../app/components/header";
import styles from "./editforms.module.scss";




const Editforms = () => { 
  
  // const tableWrapper = document.querySelector(".table-wrapper");
  // const switchInputs = document.querySelectorAll(".switch-wrapper input");
  // const prices = tableWrapper.querySelectorAll(".price");
  // const toggleClass = "hide";
  
  // const $button  = document.querySelector('#sidebar-toggle');
	// const $wrapper = document.querySelector('#wrapper');
	
	// $button.addEventListener('click', (e) => {
	//   e.preventDefault();
	//   $wrapper.classList.toggle('toggled');
	// });
  

  return (    

  <div id="wrapper" className="">    
    <Sidebar/>
    <Header />
    <section id="content-wrapper">
            <div className="row">
              <div className="col-lg-12">                
                  <h2 className={styles.contentTitle}>Edit Forms</h2>                

                <form>
                  <div className={`${styles.packForm} ${styles.mt30}`}>
                      <div className={`${styles.leftCol} ${styles.wFull}`}>
                          <input className={styles.formControl} placeholder="Volunteer Create Account" type="text"/>
                          <span className={styles.btext}>Last Updated on 7/5/2023</span>
                      </div>                      
                  </div>

                  <div className={`${styles.packForm} ${styles.mt30}`}>
                      <div className={`${styles.leftCol} ${styles.wFull}`}>
                          <input className={styles.formControl} placeholder="Educator Create Account" type="text"/>
                          <span className={styles.btext}>Last Updated on 7/5/2023</span>
                      </div>                      
                  </div>

                  <div className={`${styles.packForm} ${styles.mt30}`}>
                      <div className={`${styles.leftCol} ${styles.wFull}`}>
                          <input className={styles.formControl} placeholder="Submit a Request" type="text"/>
                          <span className={styles.btext}>Last Updated on 7/5/2023</span>
                      </div>                      
                  </div>

                  <div className={`${styles.packForm} ${styles.mt30}`}>
                      <div className={`${styles.leftCol} ${styles.wFull}`}>
                          <input className={styles.formControl} placeholder="Donations" type="text"/>
                          <span className={styles.btext}>Last Updated on 7/5/2023</span>
                      </div>                      
                  </div>

                </form>
               
                
              </div>
            </div>
        </section>
  </div>
);}
export default Editforms;



