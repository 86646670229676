
export default function(value) {
    let plusPrefix = false;
    if(value.startsWith("+")) {
        plusPrefix = true;
        value = value.slice(1);
    }
    const nums = [ '0', '1', '2', '3', '4', '5', '6', '7', '8', '9' ]
    value = value.split("").filter(x => nums.includes(x)).join("")
    return `${plusPrefix ? "+" : ""}${value}`;
}
