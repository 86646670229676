import React, {useEffect, useRef, useState} from "react";
import "font-awesome/css/font-awesome.min.css";
import Sidebar from "../../app/components/sidebar";
import Header from "../../app/components/header";
import styles from "./volunteers.module.scss";
import { fetchDeleteRequest, fetchGetRequest } from "../../utils/network";
import endpoints from "../../utils/endpoints";
import toastMessage from "../../utils/toastMessage";
import constants from "../../utils/constants";
import ReactPaginate from "react-paginate";
import DeleteConfirmationModal from "../../components/common/DeleteConfirmationModal";
import {useNavigate} from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import {fetchPackets} from "../packets";
import {getStatusLabel} from "../packets/view";
import FilterByModal from "./FilterByModal";
import {CSVLink} from "react-csv";

const PacketsList = ({volunteerId, open}) => {
  const [packets, setPackets] = useState(null)
  const [categorizedPackets, setCategorizedPackets] = useState({});
  const [pagination, setPagination] = useState({page:1,size:10});
  const [keywords, setKeywords] = useState("");
  const [ordering, setOrdering] = useState({orderBy: 'requestNumber', orderType: 'ASC'})
  const [lastPage, setLastPage] = useState(1);

  const navigate = useNavigate();
  const fetchAllPackets = async () => {
    const extraParams = { volunteerId }
    const data = await Promise.all([
      fetchPackets("Pending", keywords, extraParams),
      fetchPackets("Active", keywords, extraParams),
      fetchPackets("Completed", keywords, extraParams)
    ]);
    setCategorizedPackets({
      pending: data[0].data,
      active: data[1].data,
      closed: data[2].data
    })
  }

  useEffect(() => {
    if(open && !packets) { fetchAllPackets(); }
  }, [volunteerId, open])

  return (
      <div className={styles.tblAccordionBody}>
        {categorizedPackets.pending?.length > 0 &&
            <div className="">
              <h3>Pending Packets</h3>
              <ul>
                {categorizedPackets.pending.map(packet =>
                    <li key={packet.packetId} className={styles.hoverableHeader}
                        onClick={() => {
                          navigate(`/packets/details/${packet.packetId}`);
                        }}>
                      <div className={`${styles.packForm}`}>
                        <div className={styles.leftCol}>
                          <div className={styles.formControl}>{packet.requestNumber}</div>
                          <span>{getStatusLabel(packet.packetStatus).toUpperCase()}</span>
                        </div>
                      </div>
                    </li>)}
              </ul>
            </div>}
        {categorizedPackets.active?.length > 0 &&
            <div style={{marginTop: '25px'}}>
              <h3>Active Packets</h3>
              <ul>
                {categorizedPackets.active.map(packet =>
                    <li key={packet.packetId} className={styles.hoverableHeader}
                      onClick={() => {
                        navigate(`/packets/details/${packet.packetId}`);
                      }}>
                      <div className={`${styles.packForm}`}>
                        <div className={styles.leftCol}>
                          <div className={styles.formControl}>{packet.requestNumber}</div>
                          <span>{getStatusLabel(packet.packetStatus).toUpperCase()}</span>
                        </div>
                      </div>
                    </li>)}
              </ul>
            </div>}
        {categorizedPackets.closed?.length > 0 &&
            <div style={{marginTop: '25px'}}>
              <h3>Completed Packets</h3>
              <ul>
                {categorizedPackets.closed.map(packet =>
                    <li key={packet.packetId} className={styles.hoverableHeader}
                        onClick={() => {
                          navigate(`/packets/details/${packet.packetId}`);
                        }}>
                      <div className={`${styles.packForm}`}>
                        <div className={styles.leftCol}>
                          <div className={styles.formControl}>{packet.requestNumber}</div>
                          <span>{getStatusLabel(packet.packetStatus).toUpperCase()}</span>
                        </div>
                      </div>
                    </li>)}
              </ul>
            </div>}

        {Object.keys(categorizedPackets).length === 0 ?
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Spinner animation="border" />
            </div>
        : (!Object.values(categorizedPackets).some(x => x.length > 0) &&
                <div style={{
                  width: '100%', display: 'flex', justifyContent: 'center',
                  color: 'grey', marginBottom: '80px'
                }}>No Records Found</div>)
        }
      </div>
  )
}

const VolunteerRow = ({user, onDelete, onToggleAccordion}) => {
  const [showActions, setShowActions] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowActions(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
      <div ref={ref} className={styles.tableBody} key={user?.userId}>
        <div className={styles.tableRow}>
          <div className={styles.tableSmall}>
            <div className={styles.tableCell}>Volunteer Name:</div>
            <div className={styles.tableCell}>{user?.fullName}</div>
          </div>
          <div className={styles.tableSmall}>
            <div className={styles.tableCell}>Partner Name:</div>
            <div className={styles.tableCell}>{!!user.volunteer?.partner
                ? `${user.volunteer?.partner.title}`
                : `-`
            }</div>
          </div>
          <div className={styles.tableSmall}>
            <div className={styles.tableCell}>School District:</div>
            <div className={styles.tableCell}>{user.volunteer?.schoolDistricts?.map(x => x.districtName)?.join(", ") || "-"}</div>
          </div>
          <div className={styles.tableSmall}>
            <div className={styles.tableCell}>Total Packets:</div>
            <div className={styles.tableCell}>{user.volunteer?.totalPackets || "0"}</div>
          </div>
          <div className={styles.tableSmall}>
            <div className={styles.tableCell}>Active Packets:</div>
            <div className={styles.tableCell}>{user.volunteer?.activePackets || "0"}</div>
          </div>
          <div className={styles.tableSmall}>
            <div className={styles.tableCell}>Status:</div>
            <div className={styles.tableCell}>{user.userSettings.isAvailable ? "Available" : "Unavailable"}</div>
          </div>
          <div className={`${styles.tableSmall} ${styles.lastCol}`}>
            <div className={styles.tableCell}>Action:</div>
            <div className={styles.tableCell}>
              <div className={styles.dFlex5}>
                <div className={styles.dropdownSec} onClick={() => setShowActions(x=> !x)}>
                  <img
                      className={styles.mrGap}
                      src={"../images/doted.png"}
                      alt="dot"
                  />
                  {showActions &&
                    <ul>
                      <li onClick={() => onDelete(user?.userId)}>
                        <i className="fa fa-trash-o"></i> Remove User
                      </li>
                    </ul>}
                </div>
                <div className={styles.mtminus2}>
                    <span onClick={onToggleAccordion}>
                      <img alt="arrow" className={styles.hoverableHeader}
                           src={user?.isAccordianOpen
                              ? "../images/arrowup.png"
                              : "../images/arrow.png"} />
                    </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {user?.isAccordianOpen &&
          <PacketsList open={user.isAccordianOpen} volunteerId={user.volunteer.volunteerId} />}
      </div>
  )
}


const RenderHeaderRow = ({ label, value, ordering, updateOrdering }) => {
  const [hovering, setHovering] = useState(false);

  return (
      <div className={`${styles.tableHeader} ${value ? styles.hoverableHeader : ""}`}
           onMouseOver={() => setHovering(true)} onMouseOut={() => setHovering(false)}
           onClick={() => {
             if(value) updateOrdering(value);
           }}
           style={{backgroundColor: ordering.orderBy === value ? '#fff' : 'transparent'}}>
        {label}
        <span className="sortDownArrow">
          {(value && ordering.orderBy === value && !hovering)
              ? (ordering.orderType == 'ASC'
                  ? <img src={"../images/arrow-up.png"} alt="Down Arrow"/>
                  : <img src={"../images/arrow-down.png"} alt="Down Arrow"/>)
              : null}

          {(value && ordering.orderBy === value && hovering)
              ? (ordering.orderType == 'ASC'
                  ? <img src={"../images/arrow-down.png"} alt="Down Arrow" style={{ opacity: 0.3 }} />
                  : <img src={"../images/arrow-up.png"} alt="Down Arrow" style={{ opacity: 0.3 }} />)
              : null}

          {(value && hovering && ordering.orderBy !== value) &&
              <img src={"../images/arrow-up.png"} alt="Down Arrow"
                   style={{ opacity: 0.3 }} />}

            </span>
      </div>
  )
}


const Volunteers = () => {
  const [volunteerData, setVolunteerData] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [pagination, setPagination] = useState({page:1, size: 10})
  const [ordering, setOrdering] = useState({orderBy: 'firstName', orderType: 'ASC'})
  const [pageCountOptions, setPageCountOptions] = useState([pagination.size, 20, 50])
  const [lastPage, setLastPage] = useState(1);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState('');

  const [exportedData, setExportedData] = useState("")
  const [exportFileName, setExportFileName] = useState("");
  const csvLinkRef = React.useRef(null);
  const [exporting, setExporting] = useState(false);

  const [filters, setFilters] = useState({});
  const [filterByActive, setFilterByActive] = useState(false);

  const getVolunteerData = async () => {
    setLoadingData(true);
    try {
      const params = {
        ...pagination, ...ordering, ...filters,
        keywords: searchInputValue,
      };
      const response = await fetchGetRequest(endpoints.volunteer.getAll, {
        params,
      });
      if (response.data.users.length > 0) {
        setVolunteerData(() => {
          return response.data.users.map((user) => {
            return { ...user, isAccordianOpen: false };
          });
        });
        setLastPage(response.data.pagination.lastPage);
      } else {
        setVolunteerData([]);
        setLastPage(1);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    getVolunteerData();
  }, [searchInputValue, pagination, ordering, filters]);

  const deleteVolunteer = async (id) => {
    try {
      const response = await fetchDeleteRequest(endpoints.volunteer.delete(id));
      toastMessage(constants.TOAST_TYPE.SUCCESS, response?.data?.message);
      getVolunteerData();
    } catch (err) {
      console.log(err);
      toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
    }
  };

  const handlePageClick = (event) => {
    setPagination(x => ({...x, page: event.selected+1}))
  }

  const updateOrdering = (orderBy) => {
    setOrdering(x => {
      if(x.orderBy === orderBy) {
        const orderType = x.orderType === 'ASC' ? 'DESC' : 'ASC';
        return {...x, orderType}
      }
      return {orderBy, orderType: 'ASC'}
    })
  }

  const doWait = (time) => {
    return new Promise((resolve, reject) => {
      setTimeout(resolve, time);
    })
  }

  const onExport = async () => {
    setExporting(true);
    setExportFileName(`data-volunteers.csv`);
    try {
      const response = await fetchGetRequest(endpoints.volunteer.export())
      if(response.status === 200) {
        setExportedData(response.data);
        await doWait(1000);
        csvLinkRef?.current?.link?.click();
      }
    } catch(err) {
      console.error(err);
    } finally {
      setExporting(false);
    }
  }

  return (
    <div id="wrapper" className="">
      <DeleteConfirmationModal open={!!showDeleteConfirmation}
           onClose={() => setShowDeleteConfirmation('')}
           title="Remove Volunteer?"
           body="Are you sure you want to remove the volunteer?"
           onConfirm={() => {
             deleteVolunteer(showDeleteConfirmation); setShowDeleteConfirmation('');
           }}
      />
      <Sidebar />
      <Header />
      <section id="content-wrapper">

        <FilterByModal open={filterByActive} activeFilters={filters}
           setActiveFilters={setFilters} onClose={() => setFilterByActive(false)} />

        <div className="row">
          <CSVLink data={exportedData} filename={exportFileName} ref={csvLinkRef}></CSVLink>
          <div className="col-lg-12">
            <h2 className={styles.contentTitle}>Volunteers</h2>
            <div className={styles.volunteerPageTitle}>
              <div className={styles.titleSearchPrt} style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className={`${styles.filterIcon} ${styles.hoverable}`}
                       style={{ padding: '10px', borderRadius: '100px', width: '45px', height: '45px',
                         display: 'flex', justifyContent: 'center' }}
                       onClick={() => setFilterByActive(true)}>
                    <a data-toggle="modal" data-target="#basicModal2" href="#">
                      {Object.keys(filters).length === 0
                          ? <img src= {"../images/filter.svg"} alt="filter"/>
                          : <img src= {"../images/edit-filter.svg"} alt="filter"/>}
                    </a>
                  </div>
                  {exporting &&
                      <Spinner style={{marginLeft: '5px', marginRight: '10px', padding: '10px'}} animation="border" />
                  }
                  {!exporting &&
                      <div style={{ marginLeft: '5px', marginRight: '10px', borderRadius: '100px', padding: '10px' }}
                            className={styles.hoverable} onClick={onExport}>
                        <img src= {"../images/download-btn.svg"} alt="export csv"/>
                      </div>}
                  <input type="search" placeholder="Search" value={searchInputValue}
                    onChange={(e) => {
                      if(!searchInputValue && e.target.value) {
                        setPagination(x => ({
                          ...x, page: 1
                        }))
                      }
                      setSearchInputValue(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>

            {(!loadingData && volunteerData?.length > 0) &&
              <div className={styles.volunteerDataTable}>
                <div className={styles.tableBody}>
                  <div className={styles.theader}>
                    <RenderHeaderRow label="Volunteer Name" value="firstName"
                         ordering={ordering} updateOrdering={updateOrdering}
                    />
                    <RenderHeaderRow label="Partner Name" value=""
                         ordering={ordering} updateOrdering={updateOrdering}
                    />
                    <RenderHeaderRow label="School Districts" value=""
                         ordering={ordering} updateOrdering={updateOrdering}
                    />
                    <RenderHeaderRow label="Total Packets" value=""
                         ordering={ordering} updateOrdering={updateOrdering}
                    />
                    <RenderHeaderRow label="Active Packets" value=""
                         ordering={ordering} updateOrdering={updateOrdering}
                    />
                    <RenderHeaderRow label="Status" value=""
                         ordering={ordering} updateOrdering={updateOrdering}
                    />
                    <div
                      className={`${styles.tableHeader} ${styles.lastCol}`}
                    >Action</div>
                  </div>
                </div>
                {volunteerData?.map((user) => {
                  return (
                    <VolunteerRow user={user} onDelete={setShowDeleteConfirmation}
                          onToggleAccordion={() => {
                            setVolunteerData((prev) => {
                              return prev?.map((item) => {
                                if (item?.userId === user?.userId) {
                                  return {
                                    ...item,
                                    isAccordianOpen:
                                        !item?.isAccordianOpen,
                                  };
                                }
                                return item;
                              });
                            });
                          }}
                      />
                  );
                })}
              </div>}

            {(loadingData || volunteerData === null) &&
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Spinner animation="border" />
                </div>}

            {(!loadingData && volunteerData?.length === 0) &&
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  No Records Found!
                </div>}

            {volunteerData?.length > 0 &&
              <div style={{
                display: 'flex', justifyContent: 'flex-end', marginTop: '50px', marginRight: '0'
              }}>
                <ReactPaginate
                    className="Pagination"
                    breakLabel="..."
                    nextLabel="Next"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={lastPage}
                    previousLabel="Previous"
                    renderOnZeroPageCount={null}
                />
                <div style={{
                  display: 'flex', alignItems: 'center', marginLeft: '15px'
                }}>
                  <select value={pagination.size}
                          style={{ paddingTop: '10px', paddingBottom: '10px', borderRadius: '6px' }}
                          className={styles.selectPrt}
                          onChange={e =>
                              setPagination(x => ({page:1, size: e.target.value}))}>
                    {pageCountOptions.map((x, i) =>
                        <option key={i} value={x}>{x}</option>)}
                  </select>
                </div>
              </div>}
          </div>
        </div>
      </section>
    </div>
  );
};
export default Volunteers;
