import React, {useRef, useState} from "react";
import "font-awesome/css/font-awesome.min.css";
import Sidebar from "../../../app/components/sidebar";
import Header from "../../../app/components/header";
import styles from "./addSchoolDistrict.module.scss";
import { Formik } from "formik";
import * as yup from "yup";
import { fetchPostRequest } from "../../../utils/network";
import endpoints from "../../../utils/endpoints";
import toastMessage from "../../../utils/toastMessage";
import constants from "../../../utils/constants";
import { useNavigate } from "react-router-dom";
import InputErrorMessage from "../../../app/components/inputErrorMessage";
import phoneNumberValidator from "../../../utils/phoneNumberValidator";
import Spinner from "react-bootstrap/Spinner";

const AddSchoolDistrict = () => {
  const navigate = useNavigate();
  const formikRef = useRef();
  const [addingItem, setAddingItem] = useState(false);

  const addSchoolDistrict = async (values) => {
    setAddingItem(true);
    try {
      const payload = {
        districtName: values.districtName,
        address: values.address,
        city: values.city,
        zipCode: values.zipCode,
        phoneNumber: values.phoneNumber,
      };
      const response = await fetchPostRequest(
        endpoints.schoolDistrictManagement.add,
        payload
      );
      toastMessage(constants.TOAST_TYPE.SUCCESS, response?.data?.message);
      navigate("/school-district-management");
    } catch (err) {
      console.log(err);
      if (err?.data?.errors?.length > 0) {
        return err?.data?.errors?.forEach((error) => {
          formikRef?.current?.setFieldError(
            error?.fieldName,
            error?.fieldError?.[0]
          );
        });
      }
      toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
    } finally {
      setAddingItem(false);
    }
  };

  return (
    <div id="wrapper" className="">
      <Sidebar />
      <Header />
      <section id="content-wrapper">
        <div className="row">
          <div className="col-lg-12">
            <h2 className={styles.contentTitle}>Add School District</h2>
            <Formik
              initialValues={{
                districtName: "",
                address: "",
                city: "",
                zipCode: "",
                phoneNumber: "",
              }}
              validationSchema={yup.object().shape({
                districtName: yup
                  .string()
                  .required("District name is required")
                  .trim(),
                address: yup.string().required("Address is required").trim(),
                city: yup.string().required("City is required").trim(),
                zipCode: yup.string().required("Zip code is required").trim(),
                phoneNumber: yup
                  .string()
                  .required("Phone number is required")
                  .trim(),
              })}
              innerRef={formikRef}
              onSubmit={(values) => {
                addSchoolDistrict(values);
              }}
            >
              {({ values, setFieldValue, handleBlur, handleChange, handleSubmit }) => {
                return (
                  <form className={styles.mt50} onSubmit={handleSubmit}>
                    <div className={`${styles.dFlex} ${styles.viewForm}`}>
                      <div className={`${styles.leftCol} ${styles.fullWidth}`}>
                        <label>District Name</label>
                        <input
                          className={styles.formControl}
                          placeholder="District Name"
                          type="text"
                          name="districtName"
                          value={values.districtName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <InputErrorMessage name="districtName" />
                      </div>
                    </div>
                    <div className={`${styles.dFlex} ${styles.viewForm}`}>
                      <div className={`${styles.leftCol} ${styles.fullWidth}`}>
                        <label>Address</label>
                        <input
                          className={styles.formControl}
                          placeholder="Address"
                          type="text" pattern="[-a-zA-Z0-9\\/,:; ]+"
                          name="address" title="Address"
                          value={values.address}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <InputErrorMessage name="address" />
                      </div>
                    </div>
                    <div className={`${styles.dFlex} ${styles.viewForm}`}>
                      <div className={`${styles.leftCol} ${styles.fullWidth}`}>
                        <label>City</label>
                        <input
                          className={styles.formControl}
                          placeholder="City"
                          type="text" pattern="[a-zA-Z ]+"
                          name="city" title="City"
                          value={values.city}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <InputErrorMessage name="city" />
                      </div>
                    </div>
                    <div className={`${styles.dFlex} ${styles.viewForm}`}>
                      <div className={`${styles.leftCol} ${styles.fullWidth}`}>
                        <label>Zip Code</label>
                        <input
                          className={styles.formControl}
                          placeholder="Zip Code"
                          type="number" min={10000} max={99999}
                          name="zipCode" title="Zip Code"
                          value={values.zipCode}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <InputErrorMessage name="zipCode" />
                      </div>
                    </div>
                    <div className={`${styles.dFlex} ${styles.viewForm}`}>
                      <div className={`${styles.leftCol} ${styles.fullWidth}`}>
                        <label>Phone Number</label>
                        <input
                          className={styles.formControl}
                          placeholder="Phone Number"
                          type="tel" pattern="\+?[\d]{10}"
                          title="Phone Number must be 10 digits long"
                          name="phoneNumber"
                          value={values.phoneNumber}
                          onChange={e => {
                            setFieldValue("phoneNumber", phoneNumberValidator(e.target.value));
                          }}
                          onBlur={handleBlur}
                        />
                        <InputErrorMessage name="phoneNumber" />
                      </div>
                    </div>

                    <div className={`${styles.dFlex} ${styles.viewForm}`}>
                      <button className={styles.subBtn2} type="submit"
                          disabled={addingItem}
                          style={{
                            display: 'flex', alignItems: 'space-between'
                          }}>
                        {addingItem &&
                            <Spinner animation="border" size="sm" style={{ marginRight: '10px' }} />}
                        Add
                      </button>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </section>
    </div>
  );
};
export default AddSchoolDistrict;
