import styles from "./DeleteConfirmationModal.module.scss";
import closeIcon_img from "../../../assets/images/close-icon.png";
import {useEffect} from "react";


function DeleteConfirmationModal({ open, onClose, onConfirm, title, body }) {
    useEffect(() => {
        if(open) {
            document.getElementsByTagName('body')?.[0].classList.add(styles['modal-open']);
        } else {
            document.getElementsByTagName('body')?.[0].classList.remove(styles['modal-open']);
        }
    }, [open]);

    return (
        <div className={`${styles.modal} ${styles.fade} ${open ? styles.show : ''}`}
             tabIndex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
            <div className={styles['modal-dialog']}>
                <div className={styles['modal-content']}>
                    <div className={styles['modal-header']}>
                        <h4 className={styles['modal-title']}>{title}</h4>
                        <button type="button" onClick={onClose} className={styles['close']} data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true"><img src={closeIcon_img} alt="close-icon" /></span>
                        </button>
                    </div>
                    <div className={styles['modal-body']}>
                        {body}
                    </div>
                    <div className={styles['modal-footer']}>
                        <button type="button" className={styles.cancelBtn} onClick={onClose}>Cancel</button>
                        <button type="button" onClick={onConfirm}
                                className={`${styles['confirmBtn']}`}>Confirm</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeleteConfirmationModal;
