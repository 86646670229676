
import React from "react";
import 'font-awesome/css/font-awesome.min.css';
import Sidebar from "../../app/components/sidebar";
import Header from "../../app/components/header";
import styles from "./donors.module.scss";



const Donors = () => { 
  
  // const tableWrapper = document.querySelector(".table-wrapper");
  // const switchInputs = document.querySelectorAll(".switch-wrapper input");
  // const prices = tableWrapper.querySelectorAll(".price");
  // const toggleClass = "hide";
  
  // const $button  = document.querySelector('#sidebar-toggle');
	// const $wrapper = document.querySelector('#wrapper');
	
	// $button.addEventListener('click', (e) => {
	//   e.preventDefault();
	//   $wrapper.classList.toggle('toggled');
	// });
  

  return (    

  <div id="wrapper" className="">    
    <Sidebar/>
    <Header />
    <section id="content-wrapper">
            <div className="row">
              <div className="col-lg-12">
                <h2 className={styles.contentTitle}>Donors</h2>
                <div className={styles.volunteerPageTitle}>                  
                  <div className={styles.titleSearchPrt}>
                    <input type="search" name="" placeholder="Search"/>
                  </div>
                </div>

                <div className={`${styles.volunteerDataTable} ${styles.lastclofull}`}>
                  <div className={styles.tableBody}>
                    <div className={styles.theader}>
                      <div className={styles.tableHeader}>Donor Name <a className="sortDownArrow" href="#"><img src= {"../images/arrow-down.png"} alt="Down Arrow"/></a></div>
                      <div className={styles.tableHeader}>Total Donations <a className="sortDownArrow" href="#"><img src= {"../images/arrow-down.png"} alt="Down Arrow"/></a></div>
                      <div className={styles.tableHeader}>Latest Donation Amount <a className="sortDownArrow" href="#"><img src= {"../images/arrow-down.png"} alt="Down Arrow"/></a></div>
                      <div className={styles.tableHeader}>Latest Donation Date <a className="sortDownArrow" href="#"><img src= {"../images/arrow-down.png"} alt="Down Arrow"/></a></div>
                    </div>
                  </div>

                  <div className={styles.tableBody}>                    
                      <div className={styles.tableRow}>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Donor Name:</div>
                        <div className={styles.tableCell}>John Christensen</div>
                      </div>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Total Donations:</div>
                        <div className={styles.tableCell}>$1,000</div>
                      </div>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Latest Donation Amount:</div>
                        <div className={styles.tableCell}>$500</div>
                      </div>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Latest Donation Date:</div>
                        <div className={styles.tableCell}>06/21/2023</div>
                      </div>                      
                    </div>
                  </div>

                  <div className={styles.tableBody}>                    
                      <div className={styles.tableRow}>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Donor Name:</div>
                        <div className={styles.tableCell}>John Christensen</div>
                      </div>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Total Donations:</div>
                        <div className={styles.tableCell}>$1,000</div>
                      </div>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Latest Donation Amount:</div>
                        <div className={styles.tableCell}>$500</div>
                      </div>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Latest Donation Date:</div>
                        <div className={styles.tableCell}>06/21/2023</div>
                      </div>                      
                    </div>
                  </div>

                  <div className={styles.tableBody}>                    
                      <div className={styles.tableRow}>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Donor Name:</div>
                        <div className={styles.tableCell}>John Christensen</div>
                      </div>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Total Donations:</div>
                        <div className={styles.tableCell}>$1,000</div>
                      </div>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Latest Donation Amount:</div>
                        <div className={styles.tableCell}>$500</div>
                      </div>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Latest Donation Date:</div>
                        <div className={styles.tableCell}>06/21/2023</div>
                      </div>                      
                    </div>
                  </div>

                  <div className={styles.tableBody}>                    
                      <div className={styles.tableRow}>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Donor Name:</div>
                        <div className={styles.tableCell}>John Christensen</div>
                      </div>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Total Donations:</div>
                        <div className={styles.tableCell}>$1,000</div>
                      </div>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Latest Donation Amount:</div>
                        <div className={styles.tableCell}>$500</div>
                      </div>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Latest Donation Date:</div>
                        <div className={styles.tableCell}>06/21/2023</div>
                      </div>                      
                    </div>
                  </div>

                  <div className={styles.tableBody}>                    
                      <div className={styles.tableRow}>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Donor Name:</div>
                        <div className={styles.tableCell}>John Christensen</div>
                      </div>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Total Donations:</div>
                        <div className={styles.tableCell}>$1,000</div>
                      </div>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Latest Donation Amount:</div>
                        <div className={styles.tableCell}>$500</div>
                      </div>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Latest Donation Date:</div>
                        <div className={styles.tableCell}>06/21/2023</div>
                      </div>                      
                    </div>
                  </div>

                  <div className={styles.tableBody}>                    
                      <div className={styles.tableRow}>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Donor Name:</div>
                        <div className={styles.tableCell}>John Christensen</div>
                      </div>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Total Donations:</div>
                        <div className={styles.tableCell}>$1,000</div>
                      </div>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Latest Donation Amount:</div>
                        <div className={styles.tableCell}>$500</div>
                      </div>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Latest Donation Date:</div>
                        <div className={styles.tableCell}>06/21/2023</div>
                      </div>                      
                    </div>
                  </div>

                  <div className={styles.tableBody}>                    
                      <div className={styles.tableRow}>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Donor Name:</div>
                        <div className={styles.tableCell}>John Christensen</div>
                      </div>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Total Donations:</div>
                        <div className={styles.tableCell}>$1,000</div>
                      </div>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Latest Donation Amount:</div>
                        <div className={styles.tableCell}>$500</div>
                      </div>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Latest Donation Date:</div>
                        <div className={styles.tableCell}>06/21/2023</div>
                      </div>                      
                    </div>
                  </div>

                  <div className={styles.tableBody}>                    
                      <div className={styles.tableRow}>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Donor Name:</div>
                        <div className={styles.tableCell}>John Christensen</div>
                      </div>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Total Donations:</div>
                        <div className={styles.tableCell}>$1,000</div>
                      </div>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Latest Donation Amount:</div>
                        <div className={styles.tableCell}>$500</div>
                      </div>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Latest Donation Date:</div>
                        <div className={styles.tableCell}>06/21/2023</div>
                      </div>                      
                    </div>
                  </div>

                  <div className={styles.tableBody}>                    
                      <div className={styles.tableRow}>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Donor Name:</div>
                        <div className={styles.tableCell}>John Christensen</div>
                      </div>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Total Donations:</div>
                        <div className={styles.tableCell}>$1,000</div>
                      </div>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Latest Donation Amount:</div>
                        <div className={styles.tableCell}>$500</div>
                      </div>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Latest Donation Date:</div>
                        <div className={styles.tableCell}>06/21/2023</div>
                      </div>                      
                    </div>
                  </div>

                  <div className={styles.tableBody}>                    
                      <div className={styles.tableRow}>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Donor Name:</div>
                        <div className={styles.tableCell}>John Christensen</div>
                      </div>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Total Donations:</div>
                        <div className={styles.tableCell}>$1,000</div>
                      </div>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Latest Donation Amount:</div>
                        <div className={styles.tableCell}>$500</div>
                      </div>
                      <div className={styles.tableSmall}>
                        <div className={styles.tableCell}>Latest Donation Date:</div>
                        <div className={styles.tableCell}>06/21/2023</div>
                      </div>                      
                    </div>
                  </div>
                                    
                  
                </div>
                
              </div>
            </div>
        </section>
  </div>
);}
export default Donors;



