
import React from "react";
import 'font-awesome/css/font-awesome.min.css';
import Sidebar from "../../app/components/sidebar";
import Header from "../../app/components/header";
import styles from "./editformsview.module.scss";




const Editformsview = () => { 
  
  // const tableWrapper = document.querySelector(".table-wrapper");
  // const switchInputs = document.querySelectorAll(".switch-wrapper input");
  // const prices = tableWrapper.querySelectorAll(".price");
  // const toggleClass = "hide";
  
  // const $button  = document.querySelector('#sidebar-toggle');
	// const $wrapper = document.querySelector('#wrapper');
	
	// $button.addEventListener('click', (e) => {
	//   e.preventDefault();
	//   $wrapper.classList.toggle('toggled');
	// });
  

  return (    

  <div id="wrapper" className="">    
    <Sidebar/>
    <Header />
    <section id="content-wrapper">
            <div className="row">
              <div className="col-lg-12">                
                  <h2 className={styles.contentTitle}>View Forms</h2>                

                <form className={styles.mt50}>
                      <div className={`${styles.dFlex} ${styles.viewForm}`}>
                        <div className={styles.leftCol}>
                          <label>What is Lorem Ipsum?</label>
                          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
                          <input className={styles.formControl} placeholder="Volunteer Create Account" type="text"/>
                        </div>
                        <div className={styles.leftCol}>
                          <label>What is Lorem Ipsum?</label>
                          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
                          <input className={styles.formControl} placeholder="Volunteer Create Account" type="text"/>
                        </div>
                      </div>

                      <div className={`${styles.dFlex} ${styles.viewForm}`}>
                        <div className={styles.leftCol}>
                          <label>What is Lorem Ipsum?</label>
                          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
                          <select className={styles.formControl}>
                            <option>Select One</option>
                          </select>
                        </div>
                        <div className={styles.leftCol}>
                          <label>What is Lorem Ipsum?</label>
                          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
                          <select className={styles.formControl}>
                            <option>Select One</option>
                          </select>
                        </div>
                      </div>

                      <div className={`${styles.dFlex} ${styles.viewForm}`}>
                        <div className={styles.leftCol}>
                          <label>What is Lorem Ipsum?</label>
                          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
                          <input className={styles.formControl} placeholder="Volunteer Create Account" type="text"/>
                        </div>
                        <div className={styles.leftCol}>
                          <label>What is Lorem Ipsum?</label>
                          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
                          <input className={styles.formControl} placeholder="Volunteer Create Account" type="text"/>
                        </div>
                      </div>

                      <div className={`${styles.dFlex} ${styles.viewForm}`}>
                        <div className={styles.leftCol}>
                          <label>What is Lorem Ipsum?</label>
                          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
                          <textarea>
                            
                          </textarea>
                        </div>
                        <div className={styles.leftCol}>
                          <label>What is Lorem Ipsum?</label>
                          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
                          <input className={styles.formControl} placeholder="Volunteer Create Account" type="text"/>
                        </div>
                      </div>

                      <div className={`${styles.dFlex} ${styles.viewForm}`}>
                        <input className={styles.subBtn2} value="Submit" type="button"/>
                      </div>

                </form>
               
                
              </div>
            </div>
        </section>
  </div>
);}
export default Editformsview;



