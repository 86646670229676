import Sidebar from "../../app/components/sidebar";
import Header from "../../app/components/header";
import styles from "./form-placeholder-management.module.scss";
import React, {useState,useEffect} from "react";
import {Link} from "react-router-dom";


const all_form_types = [
    // "Educator-Registration-Form",
    // "Volunteer-Registration-Form",
    // "Educator-Create-Form",
    // "Volunteer-Create-Form",
    // "Educator-Edit-Profile-Form",
    // "Volunteer-Edit-Profile-Form",
    "Packet-Request-Form",
]

function FormPlaceholderManagement() {


    return (
        <div id="wrapper" className="">
            <Sidebar/>
            <Header />
            <section id="content-wrapper">
                <div className="row">
                    <div className="col-lg-12">
                        <h2 className={styles.contentTitle}>Edit Forms</h2>

                        <form>
                            {all_form_types.map(form_type =>
                                <Link to={`/form-placeholder-management/edit/${form_type}`}
                                      style={{textDecoration: 'none'}}>
                                    <div className={`${styles.packForm} ${styles.mt30} ${styles.link}`}>
                                        <div className={`${styles.leftCol} ${styles.wFull}`}>
                                            <input className={styles.formControl} type="text"
                                                   placeholder={form_type.replaceAll("-", " ")} />
                                            {/*<span className={styles.btext}>Last Updated on 7/5/2023</span>*/}
                                        </div>
                                    </div>
                                </Link>)}

                        </form>


                    </div>
                </div>
            </section>
        </div>
    )
}


export default FormPlaceholderManagement;
