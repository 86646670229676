import React, {useEffect, useState} from "react";
import 'font-awesome/css/font-awesome.min.css';
import Sidebar from "../../app/components/sidebar";
import Header from "../../app/components/header";
import styles from "./dashboard.module.scss";
import "./dashboard.css";
import Spinner from "react-bootstrap/Spinner";
import {fetchGetRequest} from "../../utils/network";
import endpoints from "../../utils/endpoints";
import ProgressBar from 'react-bootstrap/ProgressBar';
import FilterByModal from "./FilterByModal";
import {CSVLink} from "react-csv";

function range(a, b) {
    let o = [];
    for(let i=a; i<=b ; i++) {
        o.push(i);
    }
    return o;
}

const reportFor_options = {
    "Monthly": [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ],
    "Yearly": range(2000, (new Date()).getFullYear())
}

const Dashboard = () => {
    const [reportType, setReportType] = useState("Monthly");
    const [reportFor, setReportFor] = useState(reportFor_options["Monthly"][(new Date()).getMonth()]);
    const [stats, setStats] = useState(null);
    const [filters, setFilters] = useState({});
    const [filterByActive, setFilterByActive] = useState(false);

    const [exportedData, setExportedData] = useState("")
    const [exportFileName, setExportFileName] = useState("");
    const [exporting, setExporting] = useState(false);
    const csvLinkRef = React.useRef(null);
  
  // const tableWrapper = document.querySelector(".table-wrapper");
  // const switchInputs = document.querySelectorAll(".switch-wrapper input");
  // const prices = tableWrapper.querySelectorAll(".price");
  // const toggleClass = "hide";
  
  // const $button  = document.querySelector('#sidebar-toggle');
	// const $wrapper = document.querySelector('#wrapper');
	
	// $button.addEventListener('click', (e) => {
	//   e.preventDefault();
	//   $wrapper.classList.toggle('toggled');
	// });

    const canReportForSlideLeft = () => {
        const options = reportFor_options[reportType];
        const index = options.indexOf(reportFor);
        return index > 0;
    }

    const canReportForSlideRight = () => {
        const options = reportFor_options[reportType];
        const index = options.indexOf(reportFor);
        return index < (options.length - 1)
    }

    const reportForSlideLeft = () => {
        const options = reportFor_options[reportType];
        const index = options.indexOf(reportFor);
        if(index > 0)
            setReportFor(options[index - 1]);
    }

    const reportForSlideRight = () => {
        const options = reportFor_options[reportType];
        const index = options.indexOf(reportFor);
        if(index < (options.length - 1))
            setReportFor(options[index + 1]);
    }

    const doWait = (time) => {
        return new Promise((resolve, reject) => {
            setTimeout(resolve, time);
        })
    }

    const onExport = async () => {
        setExporting(true);
        setExportFileName(`reports-data-${reportFor}.csv`);
        const params = {reportType, reportFor, ...filters};
        if(reportType === "Monthly") {
            params.reportFor = reportFor_options[reportType].indexOf(reportFor) + 1;
        }
        try {
            const response = await fetchGetRequest(
                endpoints.stats.getExportedData, {params}
            )
            if(response.status === 200) {
                setExportedData(response.data);
                await doWait(1000);
                csvLinkRef?.current?.link?.click();
            }
        } catch(err) {
            console.error(err);
        } finally {
            setExporting(false);
        }
    }

    useEffect(() => {
        setStats(null);
        const params = {reportType, reportFor, ...filters};
        if(reportType === "Monthly") {
            params.reportFor = reportFor_options[reportType].indexOf(reportFor) + 1;
        }
        fetchGetRequest(
            endpoints.stats.getReports,
            {params}
        ).then(resp => {
            if(resp.status < 400) {
                setStats(resp.data);
            }
        }).catch(err => {
            console.log(err);
        })
    }, [reportType, reportFor, filters]);

    const progressPercent = stats ?
        stats.averageDeliveryTimeByVolunteer * 100 / 20
        : 0;

  return (    

      <div id="wrapper" className="">
        <Sidebar/>
        <Header />
        <section id="content-wrapper">

            <FilterByModal open={filterByActive} activeFilters={filters}
               setActiveFilters={setFilters} onClose={() => setFilterByActive(false)} />

            <div className="row">
                <CSVLink data={exportedData} filename={exportFileName} ref={csvLinkRef}></CSVLink>
              <div className="col-lg-12">
                <h2 className={styles.contentTitle}>Dashboard</h2>
                <div className="mt30">
                  <div className={styles.dFlex6}>
                    <div className={styles.switchWrapper}>
                          <input id="monthly" type="radio" name="switch" checked={reportType === "Monthly"}/>
                          <input id="yearly" type="radio" name="switch" checked={reportType === "Yearly"} />
                          <label for="monthly" onClick={() => {
                              setReportType("Monthly")
                              setReportFor(reportFor_options["Monthly"][(new Date()).getMonth()])
                          }}>Monthly</label>
                          <label for="yearly" onClick={() => {
                              setReportType("Yearly")
                              setReportFor(2023)
                          }}>Yearly</label>
                          <span className={`${styles.highlighter} ${
                              reportType === "Yearly" ? styles.highlighter1 : ''
                          }`}></span>
                      </div>
                      <div className={`${styles.filterIcon} ${styles.hoverable}`}
                           style={{ padding: '10px', borderRadius: '100px', width: '45px', height: '45px',
                               display: 'flex', justifyContent: 'center' }}
                           onClick={() => setFilterByActive(true)}>
                          <a data-toggle="modal" data-target="#basicModal2" href="#">
                              {Object.keys(filters).length === 0
                                  ? <img src= {"../images/filter.svg"} alt="filter"/>
                                  : <img src= {"../images/edit-filter.svg"} alt="filter"/>}
                          </a>
                      </div>
                      {exporting &&
                          <Spinner style={{marginLeft: '20px', padding: '10px'}} animation="border" />
                      }
                      {!exporting && <div style={{ marginLeft: '20px', borderRadius: '100px', padding: '10px' }} className={styles.hoverable}
                        onClick={onExport}>
                          <img src= {"../images/download-btn.svg"} alt="export csv"/>
                      </div>}
                  </div>

                  <div className={styles.tableWrapper}>
                        <div className={styles.info}>
                            <div className={`${styles.price} ${styles.monthly}`}>
                                <div id="slider">
                                    {canReportForSlideLeft() &&
                                        <a href="#" onClick={reportForSlideLeft} className="control_prev"><img src= {"../images/arrow-sl.png"} alt="arrow"/></a>}
                                    {canReportForSlideRight() &&
                                        <a href="#" onClick={reportForSlideRight} className="control_next"><img src= {"../images/arrow-sr.png"} alt="arrow"/></a>}
                                    <ul>
                                      <li>{reportFor}</li>
                                    </ul>
                                  </div>

                                {stats &&
                                    <div className={styles.dFlex7}>
                                    <div className={styles.cardSec}>
                                        <p>Number of Requests</p>
                                        <h2>{stats.numberOfRequests}</h2>
                                    </div>
                                    <div className={styles.cardSec}>
                                        <p>Total Money Spent</p>
                                        <h2>{stats.totalMoneySpend}</h2>
                                    </div>
                                    <div className={styles.cardSec}>
                                        <p>Number of Packets Completed</p>
                                        <h2>{stats.numberOfPacketCompleted}</h2>
                                    </div>
                                    <div className={styles.cardSec}>
                                        <p>Number of Educator Profiles Created</p>
                                        <h2>{stats.numberOfEducatorCreated}</h2>
                                    </div>
                                    <div className={styles.cardSec}>
                                        <p>Number of Volunteer Profiles Created</p>
                                        <h2>{stats.numberOfVolunteerCreated}</h2>
                                    </div>
                                    <div className={styles.cardSec}>
                                        <p>Number of Volunteer Who Shopped</p>
                                        <h2>{stats.numberOfVolunteerWhoShopped}</h2>
                                    </div>
                                    <div style={{
                                        display: 'flex', justifyContent: 'center', width: '100%'
                                    }}>
                                        <div className={`${styles.cardSec} ${styles.wrange}`}>
                                            <p>Average Timeline for Receiving the Request and Completing Them By Volunteer</p>
                                            <div className={styles.slidecontainer}>
                                                <ProgressBar now={progressPercent} variant={progressPercent > 100 && "danger"}
                                                   label={progressPercent > 100 ? `${stats?.averageDeliveryTimeByVolunteer} days`: ''} />
                                                <div className={styles.dFlex}>
                                                    <small>0 day</small>
                                                    <small>10 day</small>
                                                    <small>20 day</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                  </div>}

                                {stats === null &&
                                    <div style={{
                                        display: 'flex', justifyContent: 'center'
                                    }}>
                                        <Spinner animation="border" />
                                    </div>}

                            </div>

                            </div>
                    </div>

                </div>

              </div>
            </div>
        </section>
      </div>
    );
}
export default Dashboard;



