import Sidebar from "../../../app/components/sidebar";
import Header from "../../../app/components/header";
import styles from "./viewPartnerVolunteers.module.scss";
import Spinner from "react-bootstrap/Spinner";
import React, {useEffect, useRef, useState} from "react";
import {fetchGetRequest} from "../../../utils/network";
import endpoints from "../../../utils/endpoints";
import {useNavigate, useParams} from "react-router-dom";
import toastMessage from "../../../utils/toastMessage";
import constants from "../../../utils/constants";


const VolunteerRow = ({user, onToggleAccordion}) => {
    const [showActions, setShowActions] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setShowActions(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    return (
        <div ref={ref} className={styles.tableBody} key={user?.userId}>
            <div className={styles.tableRow}>
                <div className={styles.tableSmall} style={{ width: '80%' }}>
                    <div className={styles.tableCell}>Volunteer Name:</div>
                    <div className={styles.tableCell}>{user?.fullName}</div>
                </div>
                <div className={styles.tableSmall}>
                    <div className={styles.tableCell}>Status:</div>
                    <div className={styles.tableCell}>{user.userSettings?.isAvailable ? "Available" : "Unavailable"}</div>
                </div>
            </div>
        </div>
    )
}

const RenderHeaderRow = ({ label, value, ordering, updateOrdering, style }) => {
    const [hovering, setHovering] = useState(false);

    return (
        <div className={`${styles.tableHeader} ${value ? styles.hoverableHeader : ""}`}
             onMouseOver={() => setHovering(true)} onMouseOut={() => setHovering(false)}
             onClick={() => {
                 if(value) updateOrdering(value);
             }}
             style={{backgroundColor: ordering.orderBy === value ? '#fff' : 'transparent', ...style}}>
            {label}
            <span className="sortDownArrow">
              {(value && ordering.orderBy === value && !hovering)
                  ? (ordering.orderType == 'ASC'
                      ? <img src={"../../images/arrow-up.png"} alt="Down Arrow"/>
                      : <img src={"../../images/arrow-down.png"} alt="Down Arrow"/>)
                  : null}

                {(value && ordering.orderBy === value && hovering)
                    ? (ordering.orderType == 'ASC'
                        ? <img src={"../../images/arrow-down.png"} alt="Down Arrow" style={{ opacity: 0.3 }} />
                        : <img src={"../../images/arrow-up.png"} alt="Down Arrow" style={{ opacity: 0.3 }} />)
                    : null}

                {(value && hovering && ordering.orderBy !== value) &&
                    <img src={"../../images/arrow-up.png"} alt="Down Arrow"
                         style={{ opacity: 0.3 }} />}

            </span>
        </div>
    )
}


const ViewPartnerVolunteers = () => {
    const [partnerName, setPartnerName] = useState('');
    const [volunteerData, setVolunteerData] = useState(null);
    const [loadingData, setLoadingData] = useState(false);
    const [ordering, setOrdering] = useState({orderBy: 'requestNumber', orderType: 'ASC'})
    const navigate = useNavigate();
    const { id } = useParams();

    const getPartnerData = async () => {
        setLoadingData(true);
        try {
            const response = await fetchGetRequest(
                endpoints.partnerManagement.getSingle(id)
            );
            if(Array.isArray(response.data.partner.volunteers)) {
                setPartnerName(response.data.partner.title);
                setVolunteerData(response.data.partner.volunteers);
            }
        } catch (err) {
            console.log(err);
            toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
        } finally {
            setLoadingData(false);
        }
    };

    const updateOrdering = (orderBy) => {
        setOrdering(x => {
            if(x.orderBy === orderBy) {
                const orderType = x.orderType === 'ASC' ? 'DESC' : 'ASC';
                return {...x, orderType}
            }
            return {orderBy, orderType: 'ASC'}
        })
    }

    useEffect(() => {
        if(id) getPartnerData();
    }, [id]);


    return (
        <div id="wrapper" className="">
            <Sidebar />
            <Header />
            <section id="content-wrapper">
                <div className="row">
                    <div className="col-lg-12">
                        <h5 style={{marginBottom: '25px', fontWeight: 'bold'}}><span style={{fontWeight: 'normal'}}>Partner Name:</span> {partnerName}</h5>
                        <h6 style={{marginBottom: 0}}>Volunteers: </h6>
                        <br />

                        {(!loadingData && volunteerData?.length > 0) &&
                            <div className={styles.volunteerDataTable}>
                                <div className={styles.tableBody}>
                                    <div className={styles.theader}>
                                        <RenderHeaderRow label="Volunteer Name" value=""
                                             ordering={ordering} updateOrdering={updateOrdering}
                                             style={{ width: '80%' }}
                                        />
                                        <RenderHeaderRow label="Status" value=""
                                             ordering={ordering} updateOrdering={updateOrdering}
                                        />
                                    </div>
                                </div>
                                {volunteerData?.map((user) => {
                                    return (
                                        <VolunteerRow user={user.user}
                                              onToggleAccordion={() => {
                                                  setVolunteerData((prev) => {
                                                      return prev?.map((item) => {
                                                          if (item?.userId === user?.userId) {
                                                              return {
                                                                  ...item,
                                                                  isAccordianOpen:
                                                                      !item?.isAccordianOpen,
                                                              };
                                                          }
                                                          return item;
                                                      });
                                                  });
                                              }}
                                        />
                                    );
                                })}
                            </div>}

                        {(loadingData || volunteerData === null) &&
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Spinner animation="border" />
                            </div>}

                        {(!loadingData && volunteerData?.length === 0) &&
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                No Records Found!
                            </div>}

                    </div>
                </div>
            </section>
        </div>
    )
}


export default ViewPartnerVolunteers;
