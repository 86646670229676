import React, {useEffect, useRef, useState} from "react";
import {fetchGetRequest, fetchPatchRequest} from "../../../utils/network";
import endpoints from "../../../utils/endpoints";
import {useNavigate, useParams} from "react-router-dom";
import Sidebar from "../../../app/components/sidebar";
import Header from "../../../app/components/header";
import AssignVolunteer from "../assignVolunteer";
import AssignPartner from "../assignPartner";
import DeleteConfirmationModal from "../../../components/common/DeleteConfirmationModal";
import styles from "../view/viewpackets.module.scss";
import Spinner from "react-bootstrap/Spinner";
import {getDateStringUSFormat, getStatusLabel} from "../view";
import toastMessage from "../../../utils/toastMessage";
import constants from "../../../utils/constants";
import Form from 'react-bootstrap/Form';
import * as yup from "yup";
import {Formik, useFormikContext} from "formik";
import InputErrorMessage from "../../../app/components/inputErrorMessage";
import FormikErrorFocus from "formik-error-focus";

const EditableTextField = ({ value, errors, style, ...rest }) => {
    const styles = errors ? {borderColor: 'red !important'} : {}
    return (
        <input type="text" value={value || ''} {...rest} style={{ ...style, ...styles }} />
    )
}

const EditableTextArea = ({ value, ...rest }) => {
    return (
        <textarea value={value || ''} {...rest}></textarea>
    )
}

const EditableSingleSelect = ({ options, getOptionLabel, getOptionValue, value, setValue, ...rest }) => {
    return (
        <select value={getOptionValue(value) || ''} onChange={e => {
            const newValue = e.target.value;
            const option = options.find(x => getOptionValue(x) === newValue);
            if(option) setValue(option);
        }} {...rest}>
            {options.map(option =>
                <option key={getOptionValue(option)} value={getOptionValue(option)}>{getOptionLabel(option)}</option>)}
        </select>
    )
}

const ScrollToFocus = () => {
    const formik = useFormikContext();
    return (
        <FormikErrorFocus
            offset={0} focusDelay={0}
            align={"top"} ease={"out-bounce"}
            duration={600}
        />
    )
}

const EditableMulitSelect = ({ id, name, options, getOptionLabel, getOptionValue, value, setValue, disabled }) => {
    return (
        <div id={id} name={name} style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))' }}>
            {options.map(option =>
                <div style={{ display: 'flex', alignItems: 'center', minWidth: '120px', gap: '8px'}}
                     key={getOptionValue(option)}>
                    <Form.Check type="checkbox" disabled={disabled || false}
                       checked={value.some(x => getOptionValue(x) === getOptionValue(option))}
                       onChange={e => {
                           const optionValue = getOptionValue(option)
                           if(e.target.checked) {
                                setValue(x => {
                                    x = x || [];
                                    if(!x.some(y => getOptionValue(y) === optionValue)) {
                                        return [...x, option];
                                    }
                                    return x;
                                });
                           } else {
                               setValue(x => !x ? [] : x.filter(y => getOptionValue(y) !== optionValue))
                           }
                       }}
                    />
                    <div>{getOptionLabel(option)}</div>
                </div>)}
        </div>
    )
}


const EditPacket = () => {
    const [packetDetails, setPacketDetails] = useState(null);
    const [schoolData, setSchoolData] = useState(null);
    const [itemTypeData, setItemTypeData] = useState(null);
    const [saving, setSaving] = useState(false);
    const {packetId} = useParams();

    const navigate = useNavigate();

    const formikRef = useRef();

    const fetchPacketDetails = async() => {
        let response;
        response = await fetchGetRequest(endpoints.packetManagement.getParticularPacket(packetId))
        if(response.status < 400 && response.data.packet) {
            const dt = response.data.packet
            setPacketDetails({
                requestNumber: dt.requestNumber,
                packetStatus: dt.packetStatus,
                locationHours: dt.locationHours,
                giftCardNumber: dt.giftCardNumber,
                trackingNumber: dt.trackingNumber,
                packetDueDate: dt.additionalDates?.packetDueDate,
                volunteerDeliveredToOfficeDate: dt.additionalDates?.volunteerDeliveredToOfficeDate,
                school: {
                    schoolId: dt.school?.schoolId,
                    schoolName: dt.school?.schoolName
                },
                requestedItems: dt.requestedItems,
                studentAge: dt.studentAge,
                studentGender: dt.studentGender,
                shirtSize: dt.shirtSize,
                pantSize: dt.pantSize,
                shoeSize: dt.shoeSize,
                remarks: dt.remarks,
                studentType: dt.studentType,
                specialClothingRequirements: dt.specialClothingRequirements,
                educator: dt.educator,
                additionalItems: dt.additionalItems,
                studentPreferences: dt.studentPreferences
            })
            fetchSchoolData(dt.educator.schoolDistrict.schoolDistrictId);
        }
    }

    const fetchRequiredItemData = async () => {
        try {
            const params = {page: 1, size: 999};
            const response = await fetchGetRequest(
                endpoints.itemTypeManagement.getAll,
                { params }
            );
            if(Array.isArray(response?.data?.itemTypes)) {
                setItemTypeData(response?.data?.itemTypes);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const fetchSchoolData = async (schoolDistrictId) => {
        try {
            const params = {page: 1, size: 999, schoolDistrictId};
            const response = await fetchGetRequest(
                endpoints.schoolManagement.getAll,
                { params }
            );
            if(response?.data?.schools) {
                setSchoolData(response?.data?.schools);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchRequiredItemData();
        fetchPacketDetails();
    }, []);

    const savePacketData = async (packetDetails) => {
        setSaving(true);
        const body = {};
        if(packetDetails.school.schoolId) {
            body.schoolId = packetDetails.school.schoolId;
        }
        body.trackingNumber = packetDetails.trackingNumber;
        body.locationHours = packetDetails.locationHours;
        body.studentAge = packetDetails.studentAge;
        body.studentGender = packetDetails.studentGender;
        body.specialClothingRequirements = packetDetails.specialClothingRequirements;
        body.giftCardNumber = packetDetails.giftCardNumber || null;
        body.itemTypeIds = packetDetails.requestedItems.map(x => x.itemTypeId);
        body.shirtSize = packetDetails.shirtSize;
        body.pantSize = packetDetails.pantSize;
        body.shoeSize = packetDetails.shoeSize;
        body.studentType = packetDetails.studentType;
        body.additionalItems = packetDetails.additionalItems || null;
        body.studentPreferences = packetDetails.studentPreferences;
        body.remarks = packetDetails.remarks || null;
        try {
            const resp = await fetchPatchRequest(
                endpoints.packetManagement.updatePacket(packetId), body
            );
            if(resp.status === 200) {
                toastMessage(constants.TOAST_TYPE.SUCCESS, resp?.data?.message);
                navigate(`/packets/details/${packetId}`);
            }
        } catch (err) {
            console.error(err);
            toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
        } finally {
            setSaving(false);
        }
    }

    return (
        <div id="wrapper" className="">
            <Sidebar/>
            <Header/>

            <section id="content-wrapper">

            {packetDetails &&
                <Formik
                    initialValues={packetDetails}
                    validationSchema={yup.object().shape({
                        giftCardNumber: yup.string().trim().nullable(),
                        trackingNumber: yup.string().required("Please enter the tracking number").trim(),
                        studentAge: yup.number().required('Student Age is required'),
                        studentGender: yup.string().required('Student Gender is required'),
                        specialClothingRequirements: yup.string().nullable(),
                        shirtSize: yup.string().required('Shirt Size is required'),
                        pantSize: yup.string().required('Pant Size is required'),
                        shoeSize: yup.string().required('Shoe Size is required'),
                        requestedItems: yup.array(yup.object().shape({
                            itemTypeId: yup.string().required(''),
                        })).min(1, "Requested Items should have atleast 1 item selected"),
                        school: yup.object().shape({
                            schoolId: yup.string().required(''),
                            schoolName: yup.string().required('')
                        }),
                        studentType: yup.string().required(''),
                        additionalItems: yup.string().nullable(),
                        studentPreferences: yup.string().nullable(),
                        remarks: yup.string().nullable()
                    })}
                    innerRef={formikRef}
                    onSubmit={(values) => {
                        console.log(values);
                        savePacketData(values);
                    }}
                >
                    {({ values, errors, setFieldValue, handleBlur, handleChange, handleSubmit }) =>
                    <form className="row" onSubmit={handleSubmit}>
                        <ScrollToFocus />
                        {(packetDetails && itemTypeData && schoolData) &&
                            <div className="col-lg-12">
                                <div className={styles.dFlex}>
                                    <h2 className={styles.contentTitle}>Edit Packet - {packetDetails.requestNumber}</h2>

                                    <div>
                                        <button type="submit" className={`${styles.sendBtn} ${styles.avBtn}`} disabled={saving}
                                            style={{display: 'flex', gap: '10px', justifyContent: 'space-between', alignItems: 'center'}}>
                                            {saving &&
                                                <Spinner />}
                                            Save
                                        </button>
                                    </div>
                                </div>

                                <div className={styles.eduInfo}>
                                    <h3 className={styles.subTitle}>Gift Card Information</h3>
                                    <div className={styles.dFlex}>
                                        <div style={{flex: 1, display: 'flex'}}>
                                            <div style={{ width: '250px' }}>Gift Card Number: &nbsp;</div>
                                            <EditableTextField value={values.giftCardNumber} name="giftCardNumber"
                                               onChange={handleChange} onBlur={handleBlur} errors={errors.giftCardNumber}
                                               maxLength={15} placeholder="Wal: 0000-0000"
                                            />
                                        </div>
                                        <InputErrorMessage name="giftCardNumber" style={{marginLeft: '250px', marginTop: '-5px', marginBottom: '10px'}} />
                                    </div>
                                </div>

                                <div className={styles.eduInfo}>
                                    <h3 className={styles.subTitle}>Request Information</h3>
                                    <div className={styles.dFlex} style={{flexDirection: 'column', alignItems: 'flex-start'}}>
                                        <div style={{flex: 1, display: 'flex', marginBottom: '5px'}}>
                                            <div style={{width: '250px'}}>Tracking Number: </div>
                                            <EditableTextField value={values.trackingNumber} required name="trackingNumber"
                                               onChange={handleChange} onBlur={handleBlur} errors={errors.trackingNumber}
                                            />
                                        </div>
                                        <InputErrorMessage name="trackingNumber" style={{marginLeft: '250px', marginTop: '-5px', marginBottom: '10px'}} />

                                        <div style={{flex: 1, display: 'flex'}}>
                                            <div style={{width: '250px'}}>Location Hours: </div>
                                            <EditableTextField value={values.locationHours} required name="locationHours"
                                               onChange={handleChange} onBlur={handleBlur} errors={errors.locationHours}
                                            />
                                        </div>
                                        <InputErrorMessage name="locationHours" style={{marginLeft: '250px', marginTop: '-5px', marginBottom: '10px'}} />
                                    </div>
                                </div>

                                {packetDetails.educator &&
                                    <div className={styles.eduInfo}>
                                        <h3 className={styles.subTitle}>Educator Information</h3>
                                        <div className={styles.dFlex}>
                                            <p>
                                                <span>Full Name:</span> {packetDetails.educator.user.firstName} {packetDetails.educator.user.lastName}
                                            </p>
                                            <p><span>Position:</span> {packetDetails.educator.educatorPosition}</p>
                                            <p><span>Email:</span> {packetDetails.educator.user.emailAddress}</p>
                                            <p>
                                                <span>Phone Number:</span> {packetDetails.educator.user.phoneCountryCode} {packetDetails.educator.user.phoneNumber}
                                            </p>
                                        </div>
                                    </div>}

                                <div className={styles.eduInfo}>
                                    <h3 className={styles.subTitle}>School Information</h3>
                                    <div className={styles.dFlex}>
                                        <div style={{display: 'flex'}}>
                                            <div style={{ width: '250px' }}>School: </div>
                                            <EditableSingleSelect required value={values.school || ''} options={schoolData}
                                                  setValue={newVal => setFieldValue('school', newVal)}
                                                  getOptionLabel={x => x.schoolName} getOptionValue={x => x.schoolId}
                                                  name="School" style={{width: '190px'}}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.eduInfo}>
                                    <h3 className={styles.subTitle}>Student Information</h3>
                                    <div className={styles.dFlex} style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                                        <div style={{ display: 'flex', marginBottom: '5px'}}>
                                            <div style={{width: '250px'}}>Age: </div>
                                            <EditableTextField required disabled={saving}  value={values.studentAge} type="number"
                                               name="studentAge" onChange={handleChange} onBlur={handleBlur} errors={errors.studentAge}
                                            />
                                        </div>
                                        <InputErrorMessage name="studentAge" style={{marginLeft: '250px', marginTop: '-5px', marginBottom: '10px'}} />
                                        <div style={{display: 'flex'}}>
                                            <div style={{width: '250px'}}>Gender: </div>
                                            <EditableSingleSelect required disabled={saving}  value={values.studentGender}
                                               options={['Male', 'Female', 'Unspecified']} style={{width: '190px'}}
                                               setValue={newVal => setFieldValue('studentGender', newVal)}
                                               getOptionLabel={x => x} getOptionValue={x => x} name="studentGender"
                                            />
                                        </div>
                                        <InputErrorMessage name="studentGender" style={{marginLeft: '250px', marginTop: '-5px', marginBottom: '10px'}} />
                                    </div>
                                </div>

                                <div className={styles.itemsSec}>
                                    <h3>Requested Items:</h3>
                                    <InputErrorMessage name="requestedItems" />
                                    <EditableMulitSelect disabled={saving} value={values.requestedItems || []}
                                        setValue={newValue => {
                                            if(typeof newValue === 'function') {
                                                newValue = newValue(values.requestedItems || []);
                                            }
                                            return setFieldValue('requestedItems', newValue);
                                        }}
                                        options={itemTypeData}
                                        getOptionLabel={(x) => x.title}
                                        getOptionValue={(x) => x.itemTypeId}
                                        onBlur={handleBlur} id="requestedItems" name="requestedItems"
                                    />
                                </div>


                                <div className={styles.itemsSec}>
                                    <h3>Special Clothing Requirements: </h3>
                                    <div style={{marginTop: '5px'}}>
                                        <EditableTextArea disabled={saving}  value={values.specialClothingRequirements} style={{ width: '440px' }}
                                            name="specialClothingRequirements" onChange={handleChange} onBlur={handleBlur}
                                        />
                                    </div>
                                    <InputErrorMessage name="specialClothingRequirements" />
                                </div>

                                <div className={styles.eduInfo}>
                                    <div className={styles.dFlex} style={{gap: '5px'}}>
                                        <div style={{width: '100%', display: 'flex', gap: '25px'}}>
                                            <div style={{ width: '225px' }}>Student Type: </div>
                                            <div style={{display: 'flex'}}>
                                                <Form.Check type="radio" disabled={saving} checked={values.studentType === 'Youth'}
                                                   onChange={e => {
                                                       if(e.target.checked) setFieldValue('studentType', 'Youth');
                                                   }}
                                                />
                                                <span>&nbsp; Youth</span>
                                            </div>
                                            <div style={{display: 'flex'}}>
                                                <Form.Check type="radio" disabled={saving} checked={values.studentType === 'Adult'}
                                                   onChange={e => {
                                                       if(e.target.checked) setFieldValue('studentType', 'Adult');
                                                   }}
                                                />
                                                <span>&nbsp; Adult</span>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', width: '100%'}}>
                                            <span style={{ width: '250px' }}>Shirt Size: </span>
                                            <EditableTextField disabled={saving}  value={values.shirtSize} name="shirtSize"
                                               onChange={handleChange} onBlur={handleBlur} errors={errors.shirtSize} />
                                        </div>
                                        <InputErrorMessage name="shirtSize" style={{marginLeft: '250px', marginTop: '-5px', marginBottom: '10px'}} />
                                        <div style={{ display: 'flex', width: '100%'}}>
                                            <span style={{ width: '250px' }}>Student’s Pant Size: </span>
                                            <EditableTextField disabled={saving}  value={values.pantSize} name="pantSize"
                                               onChange={handleChange} onBlur={handleBlur} errors={errors.pantSize} />
                                            <br />
                                        </div>
                                        <InputErrorMessage name="pantSize" style={{marginLeft: '250px', marginTop: '-5px', marginBottom: '10px'}} />
                                        <div style={{ display: 'flex', width: '100%'}}>
                                            <span style={{ width: '250px' }}>Student’s Shoe Size: </span>
                                            <EditableTextField disabled={saving}  value={values.shoeSize} name="shoeSize"
                                               onChange={handleChange} onBlur={handleBlur} errors={errors.shoeSize} />
                                            <br />
                                        </div>
                                        <InputErrorMessage name="shoeSize" style={{marginLeft: '250px', marginTop: '-5px', marginBottom: '10px'}} />

                                        <div style={{width: '100%'}}>
                                            <br />
                                            <h3 style={{marginBottom: '5px', paddingBottom: 0}}>Additional Needs/Priority Items: </h3>
                                            <EditableTextArea disabled={saving}  value={values.additionalItems} style={{width: '440px'}}
                                               name="additionalItems" onChange={handleChange} onBlur={handleBlur}
                                            />
                                            <br />
                                        </div>

                                        <div style={{width: '100%'}}>
                                            <br />
                                            <h3 style={{marginBottom: '5px', paddingBottom: 0}}>Student's Preferences: </h3>
                                            <EditableTextArea disabled={saving}  value={values.studentPreferences} style={{width: '440px'}}
                                               name="studentPreferences" onChange={handleChange} onBlur={handleBlur}
                                            />
                                            <br />
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.itemsSec}>
                                    <h3>Other notes:</h3>
                                    <div style={{marginTop: '5px'}}>
                                        <EditableTextArea disabled={saving}  value={values.remarks} style={{width: '440px'}}
                                          name="remarks" onChange={handleChange} onBlur={handleBlur}
                                        />
                                    </div>
                                </div>

                            </div>}

                        {(!packetDetails || !itemTypeData || !schoolData) &&
                            <div style={{display: 'flex', justifyContent: 'center', paddingTop: '50px'}}>
                                <Spinner/>
                            </div>}
                    </form>}
                </Formik>}
            </section>
        </div>
    )
}


export default EditPacket;
