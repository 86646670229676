import styles from "./SendNotificationModal.module.scss";
import closeIcon_img from "../../../assets/images/close-icon.png";
import {useEffect, useState} from "react";
import Spinner from "react-bootstrap/Spinner";
import {fetchPostRequest} from "../../../utils/network";
import endpoints from "../../../utils/endpoints";
import toastMessage from "../../../utils/toastMessage";
import constants from "../../../utils/constants";


function SendNotificationModal({ open, onClose }) {
    const [notificationSubject, setNotificationSubject] = useState("");
    const [notificationBody, setNotificationBody] = useState("");
    const [recipientType, setRecipientType] = useState([]);
    const [sendingNotification, setSendingNotification] = useState(false);

    const sendNotification = async () => {
        setSendingNotification(true);
        try {
            const response = await fetchPostRequest(
                endpoints.notifications.sendNotification, {
                    notificationSubject, notificationBody, recipientType
                }
            )
            if(response.status < 400) {
                onClose();
                toastMessage(constants.TOAST_TYPE.SUCCESS, response.data.message);
            }
        } catch (err) {
            console.error(err);
        } finally {
            setSendingNotification(false);
        }
    }


    useEffect(() => {
        if(open) {
            document.getElementsByTagName('body')?.[0].classList.add(styles['modal-open']);
            setNotificationBody("");
            setNotificationSubject("");
            setRecipientType([]);
        } else {
            document.getElementsByTagName('body')?.[0].classList.remove(styles['modal-open']);
        }
    }, [open]);


    return (
        <div className={`${styles.modal} ${styles.fade} ${open ? styles.show : ''}`}
             tabIndex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
            <div className={styles['modal-dialog']}>
                <div className={styles['modal-content']} style={{padding: '20px'}}>
                    <div className={styles['modal-header']}>
                        <h4 className={styles['modal-title']}>Create Notification</h4>
                        <button type="button" onClick={onClose} className={styles['close']} data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true"><img src={closeIcon_img} alt="close-icon" /></span>
                        </button>
                    </div>
                    <div className={styles['modal-body']}>
                        <form id="send-notification-form" onSubmit={e => {
                                e.preventDefault();
                                sendNotification()
                            }}>
                            <h5>
                                Send Notification to:
                                <input type="text" style={{
                                        width: '1px', height: '1px', opacity: 0
                                    }} title="Select Atleast one" onChange={() => undefined}
                                    required value={recipientType.join(",")}
                                />
                            </h5><br />
                            <div style={{
                                display: 'flex', justifyContent: 'flex-start'
                            }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <input type="checkbox" style={{ width: '20px', height: '20px', accentColor: '#6F9CC9' }}
                                       checked={recipientType.includes("Volunteer")}
                                       onChange={e => {
                                           if(e.target.checked) {
                                               setRecipientType(z => [...z, "Volunteer"])
                                           } else {
                                               setRecipientType(z => z.filter(zz => zz !== "Volunteer"))
                                           }
                                       }}
                                    />
                                    <span style={{ padding: '8px' }}>Volunteers</span>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '30px' }}>
                                    <input type="checkbox" style={{ width: '20px', height: '20px', accentColor: '#6F9CC9' }}
                                        checked={recipientType.includes("Educator")}
                                       onChange={e => {
                                           if(e.target.checked) {
                                               setRecipientType(z => [...z, "Educator"])
                                           } else {
                                               setRecipientType(z => z.filter(zz => zz !== "Educator"))
                                           }
                                       }}
                                    />
                                    <span style={{ padding: '8px' }}>Educators</span>
                                </div>
                            </div>
                            <br />
                            <h5 style={{ marginBottom: '10px' }}>Notification Subject:</h5>
                            <input type="text" style={{
                                    width: '100%', backgroundColor: '#F5F5F5', border: '2px solid #C6C6C6',
                                    padding: '5px 12px'
                                }} value={notificationSubject} required
                               onChange={e => setNotificationSubject(e.target.value)}
                               placeholder="What is your notification about?"
                            />
                            <br /><br />
                            <h5 style={{ marginBottom: '10px' }}>Notification Body Text:</h5>
                            <textarea style={{
                                    width: '100%', backgroundColor: '#F5F5F5', border: '2px solid #C6C6C6',
                                    padding: '5px 12px'
                                }} value={notificationBody} required
                                onChange={e => setNotificationBody(e.target.value)}
                                placeholder="Write something...">
                            </textarea>
                        </form>
                    </div>
                    <div className={styles['modal-footer']}>
                        <button type="button" type="submit" form="send-notification-form"
                            className={`${styles['confirmBtn']}`}
                            style={{
                                display: 'flex', alignItems: 'center'
                            }} disabled={sendingNotification}>
                            {sendingNotification &&
                                <Spinner animation="border" />}
                            <span style={{paddingLeft: '30px', paddingRight: '30px'}}>
                                {sendingNotification
                                    ? "Sending Notification..."
                                    : "Send Notification"}
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SendNotificationModal;
