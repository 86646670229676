import React, {useEffect, useState} from "react";
import {fetchGetRequest, fetchPatchRequest} from "../../../utils/network";
import endpoints from "../../../utils/endpoints";
import styles from "./assignVolunteer.module.scss";
import closeIcon_img from "../../../assets/images/close-icon.png";
import {useNavigate} from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import toastMessage from "../../../utils/toastMessage";
import constants from "../../../utils/constants";
import ReactPaginate from "react-paginate";


const AssignVolunteerWithAnother = ({ volunteer, packetDetails, open, onClose }) => {
    const [packets, setPackets] = useState(null);
    const [loadingData, setLoadingData] = useState(false);
    const [searchInputValue, setSearchInputValue] = useState("");
    const [pagination, setPagination] = useState({page:1, size: 10})
    const [ordering, setOrdering] = useState({orderBy: 'requestNumber', orderType: 'ASC'})
    const [pageCountOptions, setPageCountOptions] = useState([pagination.size, 20, 50])
    const [lastPage, setLastPage] = useState(1);
    const [expandedPacketNumber, setExpandedPacketNumber] = useState("");

    const navigate = useNavigate();

    const getPacketsData = async () => {
        setLoadingData(true);
        try {
            const params = {
                ...pagination, ...ordering,
                keywords: searchInputValue,
                status: "Pending",
                schoolId: packetDetails.school.schoolId
            };

            const response = await fetchGetRequest(endpoints.packetManagement.getAll, {
                params,
            });
            if (response.status < 400 && Array.isArray(response.data.packets)) {
                setPackets([...response.data.packets]);
                setLastPage(response.data?.pagination?.lastPage || 1)
            } else {
                toastMessage(constants.TOAST_TYPE.ERROR, response?.data?.message)
                setPackets([]);
                setLastPage(1);
            }
        } catch (err) {
            toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message)
            console.log(err);
        } finally {
            setLoadingData(false);
        }
    };

    useEffect(() => {
        console.log(":::: FETCH")
        if(!volunteer) return;
        getPacketsData()
    }, [packetDetails, open, volunteer, pagination, ordering])

    const updateOrdering = (orderBy) => {
        setOrdering(x => {
            if(x.orderBy === orderBy) {
                const orderType = x.orderType === 'ASC' ? 'DESC' : 'ASC';
                return {...x, orderType}
            }
            return {orderBy, orderType: 'ASC'}
        })
    }

    const assignPacketToVolunteer = async (packetId, volunteerId) => {
        try {
            const response = await fetchPatchRequest(
                endpoints.volunteer.assignPacket(packetId),
                {volunteerId}
            );
            if(response.status < 400) {
                onClose?.();
                toastMessage(constants.TOAST_TYPE.SUCCESS, "Packet Assigned Successfully!");
            } else {
                onClose?.();
                toastMessage(constants.TOAST_TYPE.ERROR, response.data.message);
            }
        } catch(err) {
            console.error(err);
            onClose?.();
            toastMessage(constants.TOAST_TYPE.ERROR, err.data.message);
        }
    }

    const handlePageClick = (event) => {
        setPagination(x => ({...x, page: event.selected+1}))
    }

    return (
        <div className={`${styles.modal} ${styles.fade} ${open ? styles.show : ''}`}
             tabIndex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
            <div className={styles['modal-dialog']} style={{ maxWidth: '65%' }}>
                <div className={styles['modal-content']} style={{padding: '15px'}}>
                    <div className={styles['modal-header']}>
                        <div>
                            <h5 style={{ marginBottom: '8px', fontSize: '18px', fontWeight: 'normal' }}>Choose one or more packet to assign to</h5>
                            <h4 style={{ marginBottom: 0, fontSize: '28px', fontWeight: 'normal' }}><strong>{volunteer.user.firstName} {volunteer.user.lastName}</strong></h4>
                        </div>
                        <button type="button" onClick={onClose} className={styles['close']} data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true"><img src={closeIcon_img} alt="close-icon" /></span>
                        </button>
                    </div>
                    <div className={styles['modal-body']}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{marginTop: '10px', marginBottom: '15px'}}>Unassigned Requests:</div>
                        </div>

                        {(packets?.length > 0 && !loadingData) &&
                            <div style={{
                                display: 'flex', justifyContent: 'center',
                                flexWrap: 'wrap', maxHeight: '70vh',
                                overflowY: 'scroll'
                            }}>
                                {packets.map(packet =>
                                    <div style={{
                                        minWidth: '500px', width: '70%', maxWidth: '100%',
                                        marginTop: '2px', marginBottom: '2px'
                                    }}>
                                        <div key={packet.packetId} className={styles.hoverableHeader}
                                             style={{
                                                 backgroundColor: '#e8e8e8', display: 'flex',
                                                 justifyContent: 'space-between',
                                                 padding: '15px 20px',
                                                 borderRadius: '5px', alignItems: 'center'
                                             }}>
                                                <div className={styles.formControl}>{packet.requestNumber}</div>
                                                <div style={{display: 'flex', alignItems: 'center'}}>
                                                    <div style={{
                                                        color: '#61A8C8'
                                                    }}>{packet.packetStatus.toUpperCase()}</div>
                                                    <button style={{
                                                        padding: '15px 30px', marginLeft: '20px'
                                                    }} className={styles.viewAllBtn}
                                                    onClick={() =>
                                                        assignPacketToVolunteer(packet.packetId, volunteer.volunteerId)}
                                                    >Assign Packet</button>
                                                    <button className={styles.hoverableHeader}
                                                        style={{
                                                            padding: '20px 30px',
                                                            backgroundColor: 'transparent',
                                                        }}
                                                        onClick={() => {
                                                            if(expandedPacketNumber === packet.packetId)
                                                                setExpandedPacketNumber('');
                                                            else
                                                                setExpandedPacketNumber(packet.packetId);
                                                        }}
                                                    >
                                                        {expandedPacketNumber === packet.packetId
                                                        ? <img src={"/images/arrow-up.png"} alt="Up Arrow"/>
                                                        : <img src={"/images/arrow-down.png"} alt="Down Arrow"/>}
                                                    </button>
                                                </div>
                                        </div>
                                        {expandedPacketNumber === packet.packetId &&
                                            <div style={{
                                                backgroundColor: '#d8d8d8', padding: '12px 20px'
                                            }}>
                                                <div style={{
                                                    display: 'flex', justifyContent: 'space-between',
                                                }}>
                                                    <h4 style={{fontWeight: 'normal'}}>Student Information</h4>
                                                    <h5 style={{fontWeight: 'normal', fontSize: '18px'}}><strong>Age: </strong>{packet.studentAge}</h5>
                                                    <h5 style={{fontWeight: 'normal', fontSize: '18px'}}><strong>Gender: </strong>{packet.studentGender}</h5>
                                                    <div>{" "}</div>
                                                </div>
                                                {packet.requestedItems &&
                                                    <div className={styles.itemsSec}>
                                                        <h5 style={{marginTop: '10px', marginBottom: '8px', fontWeight: 600, fontSize: '18px'}}>Requested Items:</h5>
                                                        <ul>
                                                            {packet.requestedItems.map(reqItem =>
                                                                <li key={reqItem.itemTypeId}>{reqItem.title}</li>)}
                                                        </ul>
                                                    </div>}
                                            </div>}
                                    </div>)}
                            </div>}
                        {(packets === null || loadingData) &&
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Spinner animation="border" />
                            </div>}

                        {packets?.length === 0 &&
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                No Records Found!
                            </div>}


                        {packets?.length > 0 &&
                            <div style={{
                                display: 'flex', justifyContent: 'flex-end', marginTop: '50px', marginRight: '0'
                            }}>
                                <ReactPaginate
                                    className="Pagination"
                                    breakLabel="..."
                                    nextLabel="Next"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={5}
                                    pageCount={lastPage}
                                    previousLabel="Previous"
                                    renderOnZeroPageCount={null}
                                />
                                <div style={{
                                    display: 'flex', alignItems: 'center', marginLeft: '15px'
                                }}>
                                    <select value={pagination.size}
                                            style={{ paddingTop: '10px', paddingBottom: '10px', borderRadius: '6px' }}
                                            className={styles.selectPrt}
                                            onChange={e =>
                                                setPagination(x => ({page:1, size: e.target.value}))}>
                                        {pageCountOptions.map((x, i) =>
                                            <option key={i} value={x}>{x}</option>)}
                                    </select>
                                </div>
                            </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AssignVolunteerWithAnother;
